<template>
    <div class="field multiselect-wrapper">
        <multiselect
            v-model="involved"
            :options="options"
            :multiple="multiple"
            :close-on-select="true"
            :clear-on-select="multiple"
            :preserve-search="false"
            placeholder="ACME Company Co."
            label="name"
            track-by="name"
            :preselect-first="false"
            open-direction="bottom"
            @select="onSelect"
            @search-change="onSearchChange"
            @tag="onCreateCompany"
            @open="onOpen"
            @close="multiselectOpen=false"
            >
            
            <li slot="afterList" v-if="!isLoading && lastSearch.length >= 3 && options.length != 0">
                <span class="multiselect__option afterList">
                    <a href="#" @click.prevent="createCompany = true"><span>Looking for a different <em>{{lastSearch}}</em>?</span> <strong>Tell us about them ›</strong></a>
                </span>
            </li>

            <span slot="noResult" v-if="isLoading || options.length != 0">Searching ...</span>

            <span slot="noResult" class="noResult" v-else>
                <a
                    href="#"
                    @click.prevent="createCompany = true"
                    ><span>No companies found that match <em>{{lastSearch}}</em>.</span> <strong>Tell us about them ›</strong>
                </a>
            </span>

            <span slot="noOptions" class="noOptions">
                Start typing to search for companies.
            </span>
        </multiselect>

        <div v-if="multiselectOpen" style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0,0,0,0.5); z-index: 100;"></div>

        <modal-create-company
            v-if="createCompany"
            :name="lastSearch"
            :hasDetails="hasDetails"
            :hasSectors="hasSectors"
            :hasType="hasType"
            :hasDiversity="hasDiversity"
            :hasTags="hasTags"
            :hasMemberFlag="hasMemberFlag"            
            :create-type-options="createTypeOptions"
            @created="onCompanyCreated"
            @closed="createCompany=false"
        ></modal-create-company>

        <input type="text" tabindex="-1" :required="required" :name="formField" :value="validateInvolved" v-if="formField != '' && required" class="hidden">
        <input type="hidden" :name="`${formField}_json`" :value="validateInvolved" v-if="formField != ''">
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import { debounce } from "lodash"

    const ModalCreateCompany = () => import(
        /* webpackChunkName: "modal-create-company" */ './ModalCreateCompany.vue'
    );

    export default {
        components: {
            Multiselect,
            ModalCreateCompany
        },

        data() {
            return {
                isLoading: false,
                editing: false,
                multiselectOpen: false,
                createCompany: false,
                lastSearch: '',
                involved: [],
                options: [],
            }
        },

        props: {
            multiple: {
                type: Boolean,
                default: false
            },

            required: {
                type: Boolean,
                default: false
            },

            hasDetails: {
                type: Boolean,
                default: true
            },

            hasSectors: {
                type: Boolean,
                default: true
            },

            hasType: {
                type: Boolean,
                default: false
            },

            hasDiversity: {
                type: Boolean,
                default: false
            },

            hasTags: {
                type: Boolean,
                default: false
            },

            createTypeOptions: {
                type: String,
                default: 'ALL'
            },

            typeFilter: {
                type: String,
                default: ''
            },

            typeFilterNot: {
                type: String,
                default: ''
            },

            formField: {
                type: String,
                default: ''
            },

            hasMemberFlag: {
                type: Boolean,
                default: false
            },

            needCompany: {
                type: Boolean,
                default: false
            },

            edit: {
                type: Object,
                default: function() {
                    return { id: 0 }
                }
            },            
        },

        mounted () {
            if( this.edit.id > 0 ) {
                this.editing = true

                if( this.formField == 'acquirer_id' || this.formField == 'deal_acquirer' ) {
                    this.involved = this.edit.acquirer ?? []
                }

                if( this.formField == 'coinvestor_id' || this.formField == 'coinvestors' ) {
                    this.involved = this.edit.participants ?? []
                }

                if( this.formField == 'debtProvider_id' ) {
                    this.involved = this.edit.debtProviders ?? []
                }
                
                if( this.formField == 'financialAdvisor_id' || this.formField == 'financialAdvisors' ) {
                    this.involved = this.edit.financialAdvisors ?? []
                }
                
                if( this.formField == 'lawFirm_id' || this.formField == 'lawFirms' ) {
                    this.involved = this.edit.lawFirms ?? []
                }

                if( this.formField == 'underwriter_id' ) {
                    this.involved = this.edit.underwriters ?? []
                }
            }
        },

        computed: {
            validateInvolved() {
                if( this.involved && "id" in this.involved ) {
                    return JSON.stringify(this.involved)
                }
                return  ( !this.involved || !this.involved.length ) ? '' : JSON.stringify(this.involved)
            }
        },

        watch: {
            involved(newValue, oldValue) {
                this.$emit('set-company', newValue)
                if( newValue == null ) {
                    this.options = []
                } else {
                    this.options = ( newValue && newValue.length >= 1 ) ? newValue : [newValue]
                }
            }
        },

        methods: {
            onCreateCompany(companyName) {
                if( this.isLoading ) { return }

                this.options = []
                this.lastSearch = companyName
                this.createCompany = true
            },

            onCompanyCreated: function(company) {
                if( company.id && company.id >= 1 ) {
                    this.options = []
                    if( this.multiple ) {
                        this.involved.push(company)
                    } else {
                        this.involved = company
                    }
                }
            },

            onSelect(options,id) {
                this.options=[]
            },

            onSearchChange(query) {
                if( query.length >= 3 ) {
                    this.isLoading  = true
                    this.lastSearch = query
                    this.loadOptions();
                }
            },

            loadOptions: debounce(function() {
                let query = this.lastSearch
                fetch(
                    window.IntelligenceURL + `/json/companies?q=${encodeURI(query)}&typeMatch=${this.typeFilter}&typeNotMatch=${this.typeFilterNot}&inclWebsiteWithName=true`, {
                        method: 'GET',
                        credentials: 'same-origin',
                        redirect: 'follow',
                        agent: null,
                    }
                ).then( res => { return res.json() }
                ).then( companies => { return companies.items }
                ).then( results => {
                    this.options = results
                    this.isLoading = false
                })
            }, 300 ),

            onOpen() {
                this.multiselectOpen=true;
                if( !this.involved.length ) {
                    this.options = []
                }
            },

            // containsKey(obj, key ) {
            //     return Object.keys(obj).includes(key);
            // },
            // clearAll() {
            //     this.involved = []
            // },
        },
    }
</script>

<style lang="scss">

</style>
