var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.modalOpen
    ? _c(
        "div",
        {
          staticClass: "modal",
          class: _vm.modalOpen ? "is-active" : "",
          attrs: { tabindex: "0" }
        },
        [
          _c("div", { staticClass: "modal-background" }),
          _vm._v(" "),
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-title title is-4" }, [
              _vm._v(
                "Is this the " + _vm._s(_vm.type) + " you were looking for?"
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "box" }, [
              _c("div", { staticClass: "content" }, [
                _c("p", [
                  _vm._v(
                    "\n                    We found a similar " +
                      _vm._s(_vm.type) +
                      " in our records from "
                  ),
                  _c("em", {
                    domProps: { textContent: _vm._s(_vm.monthYear) }
                  }),
                  _vm._v(" that also involves "),
                  _c("strong", {
                    domProps: { textContent: _vm._s(_vm.company.name) }
                  }),
                  _vm._v(".\n                ")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "matches" },
                  _vm._l(_vm.matches, function(match) {
                    return _c(
                      "div",
                      { key: match.id, staticClass: "level mb-1" },
                      [
                        _c("div", { staticClass: "level-left" }, [
                          _c("div", { staticClass: "level-item" }, [
                            _c("strong", [
                              match.type
                                ? _c("span", {
                                    domProps: {
                                      textContent: _vm._s(match.type)
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              match.round
                                ? _c("span", {
                                    domProps: {
                                      textContent: _vm._s(match.round)
                                    }
                                  })
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              match.date
                                ? _c("em", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.formatDate(match.date)
                                      )
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              match.pressRelease
                                ? _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: match.pressRelease,
                                        target: "_blank"
                                      }
                                    },
                                    [_vm._v("Read Press Release")]
                                  )
                                : _vm._e()
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "level-right" }, [
                          _c("div", { staticClass: "level-item" }, [
                            _c(
                              "a",
                              {
                                staticClass: "button is-success",
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.selectMatch(match)
                                  }
                                }
                              },
                              [_vm._v("Yes")]
                            )
                          ])
                        ])
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c("div", { staticStyle: { "text-align": "center" } }, [
                  _c(
                    "a",
                    {
                      staticClass: "button is-danger is-light",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.closeModal()
                        }
                      }
                    },
                    [_vm._v("No, this is a different " + _vm._s(_vm.type))]
                  )
                ])
              ])
            ])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }