var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.modalOpen
    ? _c(
        "div",
        {
          staticClass: "modal",
          class: _vm.modalOpen ? "is-active" : "",
          attrs: { tabindex: "0" },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.closeModal()
            }
          }
        },
        [
          _c("div", {
            staticClass: "modal-background",
            on: {
              click: function($event) {
                return _vm.closeModal()
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "modal-content create-company" }, [
            _c("div", { staticClass: "modal-title title is-4" }, [
              _vm._v("Adding Company")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "box" }, [
              _c(
                "div",
                { class: { saving: _vm.saving } },
                [
                  _c("div", { staticClass: "field is-horizontal mb-4" }, [
                    _vm._m(0),
                    _vm._v(" "),
                    _c("div", { staticClass: "field-body" }, [
                      _c("div", { staticClass: "field" }, [
                        _c("div", { staticClass: "control" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.company.new_name,
                                expression: "company.new_name"
                              }
                            ],
                            ref: "input",
                            staticClass: "input",
                            attrs: { type: "text", required: "" },
                            domProps: { value: _vm.company.new_name },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.company,
                                  "new_name",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _vm.errors.name
                          ? _c("p", { staticClass: "help is-danger" }, [
                              _vm._v("Please enter a company name")
                            ])
                          : _vm._e()
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.hasDetails || _vm.hasMemberFlag
                    ? _c("div", [
                        _c("div", { staticClass: "field is-horizontal mb-4" }, [
                          _vm._m(1),
                          _vm._v(" "),
                          _c("div", { staticClass: "field-body" }, [
                            _c("div", { staticClass: "field" }, [
                              _c("div", { staticClass: "control select" }, [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.company.new_country,
                                        expression: "company.new_country"
                                      }
                                    ],
                                    staticClass: "input",
                                    attrs: { name: "country", required: "" },
                                    on: {
                                      change: function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.$set(
                                          _vm.company,
                                          "new_country",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("option", { attrs: { value: "" } }, [
                                      _vm._v("Choose Country")
                                    ]),
                                    _vm._v(" "),
                                    _vm._m(2),
                                    _vm._v(" "),
                                    _vm._m(3)
                                  ]
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "field" }, [
                              _vm.company.new_country != "US" &&
                              _vm.company.new_country != "Canada"
                                ? _c("div", { staticClass: "control" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.company.new_region,
                                          expression: "company.new_region"
                                        }
                                      ],
                                      staticClass: "input",
                                      attrs: {
                                        type: "text",
                                        placeholder: "Province/State",
                                        required: "",
                                        disabled: _vm.company.new_country == ""
                                      },
                                      domProps: {
                                        value: _vm.company.new_region
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.company,
                                            "new_region",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.company.new_country == "US"
                                ? _c("div", { staticClass: "control select" }, [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.company.new_region,
                                            expression: "company.new_region"
                                          }
                                        ],
                                        staticClass: "input",
                                        on: {
                                          change: function($event) {
                                            var $$selectedVal = Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function(o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function(o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                            _vm.$set(
                                              _vm.company,
                                              "new_region",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("option", { attrs: { value: "" } }, [
                                          _vm._v(" - State - ")
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "AK" } },
                                          [_vm._v("Alaska")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "AL" } },
                                          [_vm._v("Alabama")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "AR" } },
                                          [_vm._v("Arkansas")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "AZ" } },
                                          [_vm._v("Arizona")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "CA" } },
                                          [_vm._v("California")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "CO" } },
                                          [_vm._v("Colorado")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "CT" } },
                                          [_vm._v("Connecticut")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "DC" } },
                                          [_vm._v("District of Columbia")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "DE" } },
                                          [_vm._v("Delaware")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "FL" } },
                                          [_vm._v("Florida")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "GA" } },
                                          [_vm._v("Georgia")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "HI" } },
                                          [_vm._v("Hawaii")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "IA" } },
                                          [_vm._v("Iowa")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "ID" } },
                                          [_vm._v("Idaho")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "IL" } },
                                          [_vm._v("Illinois")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "IN" } },
                                          [_vm._v("Indiana")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "KS" } },
                                          [_vm._v("Kansas")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "KY" } },
                                          [_vm._v("Kentucky")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "LA" } },
                                          [_vm._v("Louisiana")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "MA" } },
                                          [_vm._v("Massachusetts")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "MD" } },
                                          [_vm._v("Maryland")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "ME" } },
                                          [_vm._v("Maine")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "MI" } },
                                          [_vm._v("Michigan")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "MN" } },
                                          [_vm._v("Minnesota")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "MO" } },
                                          [_vm._v("Missouri")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "MS" } },
                                          [_vm._v("Mississippi")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "MT" } },
                                          [_vm._v("Montana")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "NC" } },
                                          [_vm._v("North Carolina")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "ND" } },
                                          [_vm._v("North Dakota")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "NE" } },
                                          [_vm._v("Nebraska")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "NH" } },
                                          [_vm._v("New Hampshire")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "NJ" } },
                                          [_vm._v("New Jersey")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "NM" } },
                                          [_vm._v("New Mexico")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "NV" } },
                                          [_vm._v("Nevada")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "NY" } },
                                          [_vm._v("New York")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "OH" } },
                                          [_vm._v("Ohio")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "OK" } },
                                          [_vm._v("Oklahoma")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "OR" } },
                                          [_vm._v("Oregon")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "PA" } },
                                          [_vm._v("Pennsylvania")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "PR" } },
                                          [_vm._v("Puerto Rico")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "RI" } },
                                          [_vm._v("Rhode Island")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "SC" } },
                                          [_vm._v("South Carolina")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "SD" } },
                                          [_vm._v("South Dakota")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "TN" } },
                                          [_vm._v("Tennessee")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "TX" } },
                                          [_vm._v("Texas")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "UT" } },
                                          [_vm._v("Utah")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "VA" } },
                                          [_vm._v("Virginia")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "VT" } },
                                          [_vm._v("Vermont")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "WA" } },
                                          [_vm._v("Washington")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "WI" } },
                                          [_vm._v("Wisconsin")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "WV" } },
                                          [_vm._v("West Virginia")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "WY" } },
                                          [_vm._v("Wyoming")]
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.company.new_country == "Canada"
                                ? _c("div", { staticClass: "control select" }, [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.company.new_region,
                                            expression: "company.new_region"
                                          }
                                        ],
                                        staticClass: "input",
                                        on: {
                                          change: function($event) {
                                            var $$selectedVal = Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function(o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function(o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                            _vm.$set(
                                              _vm.company,
                                              "new_region",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("option", { attrs: { value: "" } }, [
                                          _vm._v(" - Province - ")
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "AB" } },
                                          [_vm._v("Alberta")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "BC" } },
                                          [_vm._v("British Columbia")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "MB" } },
                                          [_vm._v("Manitoba")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "NB" } },
                                          [_vm._v("New Brunswick")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "NF" } },
                                          [_vm._v("Newfoundland")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "NT" } },
                                          [_vm._v("Northwest Territories")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "NS" } },
                                          [_vm._v("Nova Scotia")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "NU" } },
                                          [_vm._v("Nunavut")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "ON" } },
                                          [_vm._v("Ontario")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "PE" } },
                                          [_vm._v("Prince Edward Island")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "QC" } },
                                          [_vm._v("Quebec")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "SK" } },
                                          [_vm._v("Saskatchewan")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "YT" } },
                                          [_vm._v("Yukon Territory")]
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.errors.region
                                ? _c(
                                    "p",
                                    {
                                      staticClass: "help is-danger",
                                      staticStyle: { "white-space": "nowrap" }
                                    },
                                    [_vm._v("Please enter a region OR country")]
                                  )
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "field" }, [
                              _c("div", { staticClass: "control" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.company.new_city,
                                      expression: "company.new_city"
                                    }
                                  ],
                                  ref: "companyCity",
                                  staticClass: "input",
                                  attrs: {
                                    placeholder: "City",
                                    type: "text",
                                    required: ""
                                  },
                                  domProps: { value: _vm.company.new_city },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.company,
                                        "new_city",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _vm.errors.city
                                ? _c("p", { staticClass: "help is-danger" }, [
                                    _vm._v("Please enter the city")
                                  ])
                                : _vm._e()
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "field is-horizontal mb-4" }, [
                          _vm._m(4),
                          _vm._v(" "),
                          _c("div", { staticClass: "field-body" }, [
                            _c("div", { staticClass: "field" }, [
                              _c("div", { staticClass: "control" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.company.new_description,
                                      expression: "company.new_description"
                                    }
                                  ],
                                  staticClass: "input",
                                  attrs: { type: "text", required: "" },
                                  domProps: {
                                    value: _vm.company.new_description
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.company,
                                        "new_description",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "field is-horizontal mb-4" }, [
                          _vm._m(5),
                          _vm._v(" "),
                          _c("div", { staticClass: "field-body" }, [
                            _c("div", { staticClass: "field" }, [
                              _c("div", { staticClass: "control" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.company.new_website,
                                      expression: "company.new_website"
                                    }
                                  ],
                                  staticClass: "input",
                                  attrs: {
                                    type: "text",
                                    required: "",
                                    placeholder: "https://www.example.com"
                                  },
                                  domProps: { value: _vm.company.new_website },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.company,
                                        "new_website",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            ])
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasSectors
                    ? _c("field-sectors", {
                        attrs: {
                          fieldPrefix: "new",
                          required: true,
                          needSector: this.errors.sector,
                          needSubSector: this.errors.subSector
                        },
                        on: {
                          "set-sector": _vm.setSector,
                          "set-subsector": _vm.setSubSector
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasType
                    ? _c("div", { staticClass: "field is-horizontal mb-4" }, [
                        _vm._m(6),
                        _vm._v(" "),
                        _c("div", { staticClass: "field-body select" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.company.new_type,
                                  expression: "company.new_type"
                                }
                              ],
                              attrs: { required: "" },
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.company,
                                    "new_type",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v(" - Please Select - ")
                              ]),
                              _vm._v(" "),
                              _vm.createTypeOptions == "ALL"
                                ? _c(
                                    "optgroup",
                                    { attrs: { label: "Investor / GP" } },
                                    [
                                      _c(
                                        "option",
                                        { attrs: { value: "2857" } },
                                        [_vm._v("Accelerator/Incubator")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "2858" } },
                                        [_vm._v("Angel Group")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "2859" } },
                                        [_vm._v("Angel/Private Investor")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "2860" } },
                                        [_vm._v("Asset Management/Mutual Fund")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "2861" } },
                                        [_vm._v("Corporate Investor")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "2862" } },
                                        [_vm._v("Corporate Venture Capital")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "2863" } },
                                        [_vm._v("Corporate Private Equity")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "2864" } },
                                        [_vm._v("Debt Provider")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "2865" } },
                                        [_vm._v("Family Offices")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "2867" } },
                                        [_vm._v("Merchant Bank")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "2868" } },
                                        [_vm._v("Private Equity")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "2869" } },
                                        [_vm._v("Real Estate Investor")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "2870" } },
                                        [_vm._v("REIT")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "2871" } },
                                        [_vm._v("Venture Capital")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "2866" } },
                                        [_vm._v("(Other / Unknown)")]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.createTypeOptions == "ALL"
                                ? _c(
                                    "optgroup",
                                    {
                                      attrs: { label: "Limited Partner / LP" }
                                    },
                                    [
                                      _c(
                                        "option",
                                        { attrs: { value: "2872" } },
                                        [_vm._v("Endowment/Foundation/Trust")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "2873" } },
                                        [_vm._v("Fund of Funds")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "2874" } },
                                        [_vm._v("Insurance Company")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "2876" } },
                                        [_vm._v("Public Pension")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "2877" } },
                                        [_vm._v("Private Pension")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "2878" } },
                                        [_vm._v("Sovereign Wealth Fund")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "2875" } },
                                        [_vm._v("(Other / Unknown)")]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.createTypeOptions == "SP"
                                ? _c(
                                    "optgroup",
                                    { attrs: { label: "Service Provider" } },
                                    [
                                      _c(
                                        "option",
                                        { attrs: { value: "2879" } },
                                        [_vm._v("Accounting")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "2880" } },
                                        [_vm._v("Brokerage/Investment Bank")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "2864" } },
                                        [_vm._v("Debt Provider")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "2881" } },
                                        [_vm._v("Financial Advisor")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "2882" } },
                                        [_vm._v("Law Firm/Legal Advisor")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "2883" } },
                                        [_vm._v("PR/IR/Executive Search")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "2884" } },
                                        [_vm._v("Proxy Solicitor/Info Agent")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "2885" } },
                                        [_vm._v("(Other / Unknown)")]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.createTypeOptions == "ALL"
                                ? _c(
                                    "optgroup",
                                    { attrs: { label: "Corporation" } },
                                    [
                                      _c(
                                        "option",
                                        { attrs: { value: "1869" } },
                                        [_vm._v("Assets, Divisions")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "1870" } },
                                        [_vm._v("Bank/Financial Institution")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "1871" } },
                                        [
                                          _vm._v(
                                            "Building, Properties, Real Assets"
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "2855" } },
                                        [_vm._v("Corporation/Partnership")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "2856" } },
                                        [_vm._v("Infrastructure Project")]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._m(7)
                            ]
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasMemberFlag
                    ? _c("div", { staticClass: "field is-horizontal mb-4" }, [
                        _vm._m(8),
                        _vm._v(" "),
                        _c("div", { staticClass: "field-body" }, [
                          _c("div", { staticClass: "field" }, [
                            _c("div", { staticClass: "control radios" }, [
                              _c("label", { staticClass: "radio" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.company.new_cvcaMember,
                                      expression: "company.new_cvcaMember"
                                    }
                                  ],
                                  attrs: { type: "radio", value: "1" },
                                  domProps: {
                                    checked: _vm._q(
                                      _vm.company.new_cvcaMember,
                                      "1"
                                    )
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.$set(
                                        _vm.company,
                                        "new_cvcaMember",
                                        "1"
                                      )
                                    }
                                  }
                                }),
                                _vm._v("Yes")
                              ]),
                              _vm._v(" "),
                              _c("label", { staticClass: "radio" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.company.new_cvcaMember,
                                      expression: "company.new_cvcaMember"
                                    }
                                  ],
                                  attrs: { type: "radio", value: "0" },
                                  domProps: {
                                    checked: _vm._q(
                                      _vm.company.new_cvcaMember,
                                      "0"
                                    )
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.$set(
                                        _vm.company,
                                        "new_cvcaMember",
                                        "0"
                                      )
                                    }
                                  }
                                }),
                                _vm._v("No")
                              ])
                            ])
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _vm.hasDiversity
                    ? _c("div", { staticClass: "field is-horizontal mb-4" }, [
                        _vm._m(9),
                        _vm._v(" "),
                        _c("div", { staticClass: "field-body" }, [
                          _c("div", { staticClass: "field" }, [
                            _c("label", { staticClass: "checkbox" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.company.new_founderWoman,
                                    expression: "company.new_founderWoman"
                                  }
                                ],
                                attrs: { type: "checkbox", value: "1" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.company.new_founderWoman
                                  )
                                    ? _vm._i(
                                        _vm.company.new_founderWoman,
                                        "1"
                                      ) > -1
                                    : _vm.company.new_founderWoman
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.company.new_founderWoman,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = "1",
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.company,
                                            "new_founderWoman",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.company,
                                            "new_founderWoman",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.company,
                                        "new_founderWoman",
                                        $$c
                                      )
                                    }
                                  }
                                }
                              }),
                              _vm._v(
                                " Does one or more member of the founding team identify as a woman?\n                            "
                              )
                            ]),
                            _vm._v(" "),
                            _c("label", { staticClass: "checkbox" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.company.new_founderBipoc,
                                    expression: "company.new_founderBipoc"
                                  }
                                ],
                                attrs: { type: "checkbox", value: "1" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.company.new_founderBipoc
                                  )
                                    ? _vm._i(
                                        _vm.company.new_founderBipoc,
                                        "1"
                                      ) > -1
                                    : _vm.company.new_founderBipoc
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.company.new_founderBipoc,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = "1",
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.company,
                                            "new_founderBipoc",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.company,
                                            "new_founderBipoc",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.company,
                                        "new_founderBipoc",
                                        $$c
                                      )
                                    }
                                  }
                                }
                              }),
                              _vm._v(
                                " Does one or more of the founding team identify as "
                              ),
                              _vm._m(10),
                              _vm._v("?\n                            ")
                            ]),
                            _vm._v(" "),
                            _c("label", { staticClass: "checkbox" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.company.new_mgmtWoman,
                                    expression: "company.new_mgmtWoman"
                                  }
                                ],
                                attrs: { type: "checkbox", value: "1" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.company.new_mgmtWoman
                                  )
                                    ? _vm._i(_vm.company.new_mgmtWoman, "1") >
                                      -1
                                    : _vm.company.new_mgmtWoman
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.company.new_mgmtWoman,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = "1",
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.company,
                                            "new_mgmtWoman",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.company,
                                            "new_mgmtWoman",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.company,
                                        "new_mgmtWoman",
                                        $$c
                                      )
                                    }
                                  }
                                }
                              }),
                              _vm._v(" Does one or more member of the "),
                              _vm._m(11),
                              _vm._v(
                                " identify as a woman?\n                            "
                              )
                            ]),
                            _vm._v(" "),
                            _c("label", { staticClass: "checkbox" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.company.new_mgmtBipoc,
                                    expression: "company.new_mgmtBipoc"
                                  }
                                ],
                                attrs: { type: "checkbox", value: "1" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.company.new_mgmtBipoc
                                  )
                                    ? _vm._i(_vm.company.new_mgmtBipoc, "1") >
                                      -1
                                    : _vm.company.new_mgmtBipoc
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.company.new_mgmtBipoc,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = "1",
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.company,
                                            "new_mgmtBipoc",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.company,
                                            "new_mgmtBipoc",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.company,
                                        "new_mgmtBipoc",
                                        $$c
                                      )
                                    }
                                  }
                                }
                              }),
                              _vm._v(" Does one or more of the "),
                              _vm._m(12),
                              _vm._v(" identify as "),
                              _vm._m(13),
                              _vm._v("?\n                            ")
                            ])
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasTags
                    ? _c("div", { staticClass: "field is-horizontal mb-4" }, [
                        _vm._m(14),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "field-body select" },
                          [
                            _c("field-tags", {
                              attrs: { formField: "new_tags" },
                              on: { "set-tags": _vm.setTags }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("hr")
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field is-grouped is-justify-content-center" },
                [
                  _c("div", { staticClass: "control" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button is-success",
                        class: { "is-loading": _vm.saving },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.createCompany()
                          }
                        }
                      },
                      [_vm._v("Save")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "control" }, [
                    _c(
                      "a",
                      {
                        staticClass: "button is-link is-light",
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.closeModal()
                          }
                        }
                      },
                      [_vm._v("Cancel")]
                    )
                  ])
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c("button", {
            staticClass: "modal-close is-large",
            attrs: { "aria-label": "close" },
            on: {
              click: function($event) {
                return _vm.closeModal()
              }
            }
          })
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Name")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Head Office")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("optgroup", { attrs: { label: "Popular" } }, [
      _c("option", { attrs: { value: "Canada" } }, [_vm._v("Canada")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "England" } }, [_vm._v("England")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "US" } }, [_vm._v("United States")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("optgroup", { attrs: { label: "All Countries" } }, [
      _c("option", { attrs: { value: "Afghanistan" } }, [
        _vm._v("Afghanistan")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Åland Islands" } }, [
        _vm._v("Åland Islands")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Albania" } }, [_vm._v("Albania")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Algeria" } }, [_vm._v("Algeria")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "American Samoa" } }, [
        _vm._v("American Samoa")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Andorra" } }, [_vm._v("Andorra")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Angola" } }, [_vm._v("Angola")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Anguilla" } }, [_vm._v("Anguilla")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Antarctica" } }, [_vm._v("Antarctica")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Antigua and Barbuda" } }, [
        _vm._v("Antigua and Barbuda")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Argentina" } }, [_vm._v("Argentina")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Armenia" } }, [_vm._v("Armenia")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Aruba" } }, [_vm._v("Aruba")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Australia" } }, [_vm._v("Australia")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Austria" } }, [_vm._v("Austria")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Azerbaijan" } }, [_vm._v("Azerbaijan")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Bahamas" } }, [_vm._v("Bahamas")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Bahrain" } }, [_vm._v("Bahrain")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Bangladesh" } }, [_vm._v("Bangladesh")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Barbados" } }, [_vm._v("Barbados")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Belarus" } }, [_vm._v("Belarus")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Belgium" } }, [_vm._v("Belgium")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Belize" } }, [_vm._v("Belize")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Benin" } }, [_vm._v("Benin")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Bermuda" } }, [_vm._v("Bermuda")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Bhutan" } }, [_vm._v("Bhutan")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Bolivia" } }, [_vm._v("Bolivia")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Bosnia and Herzegovina" } }, [
        _vm._v("Bosnia and Herzegovina")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Botswana" } }, [_vm._v("Botswana")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Bouvet Island" } }, [
        _vm._v("Bouvet Island")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Brazil" } }, [_vm._v("Brazil")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "British Indian Ocean Territory" } }, [
        _vm._v("British Indian Ocean Territory")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Brunei Darussalam" } }, [
        _vm._v("Brunei Darussalam")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Bulgaria" } }, [_vm._v("Bulgaria")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Burkina Faso" } }, [
        _vm._v("Burkina Faso")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Burundi" } }, [_vm._v("Burundi")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Cambodia" } }, [_vm._v("Cambodia")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Cameroon" } }, [_vm._v("Cameroon")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Canada" } }, [_vm._v("Canada")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Cape Verde" } }, [_vm._v("Cape Verde")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Cayman Islands" } }, [
        _vm._v("Cayman Islands")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Central African Republic" } }, [
        _vm._v("Central African Republic")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Chad" } }, [_vm._v("Chad")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Chile" } }, [_vm._v("Chile")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "China" } }, [_vm._v("China")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Christmas Island" } }, [
        _vm._v("Christmas Island")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Cocos (Keeling) Islands" } }, [
        _vm._v("Cocos (Keeling) Islands")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Colombia" } }, [_vm._v("Colombia")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Comoros" } }, [_vm._v("Comoros")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Congo" } }, [_vm._v("Congo")]),
      _vm._v(" "),
      _c(
        "option",
        { attrs: { value: "Congo, The Democratic Republic of The" } },
        [_vm._v("Congo, The Democratic Republic of The")]
      ),
      _vm._v(" "),
      _c("option", { attrs: { value: "Cook Islands" } }, [
        _vm._v("Cook Islands")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Costa Rica" } }, [_vm._v("Costa Rica")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Cote D'ivoire" } }, [
        _vm._v("Cote D'ivoire")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Croatia" } }, [_vm._v("Croatia")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Cuba" } }, [_vm._v("Cuba")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Cyprus" } }, [_vm._v("Cyprus")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Czech Republic" } }, [
        _vm._v("Czech Republic")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Denmark" } }, [_vm._v("Denmark")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Djibouti" } }, [_vm._v("Djibouti")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Dominica" } }, [_vm._v("Dominica")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Dominican Republic" } }, [
        _vm._v("Dominican Republic")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Ecuador" } }, [_vm._v("Ecuador")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Egypt" } }, [_vm._v("Egypt")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "El Salvador" } }, [
        _vm._v("El Salvador")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "England" } }, [_vm._v("England")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Equatorial Guinea" } }, [
        _vm._v("Equatorial Guinea")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Eritrea" } }, [_vm._v("Eritrea")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Estonia" } }, [_vm._v("Estonia")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Ethiopia" } }, [_vm._v("Ethiopia")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Falkland Islands (Malvinas)" } }, [
        _vm._v("Falkland Islands (Malvinas)")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Faroe Islands" } }, [
        _vm._v("Faroe Islands")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Fiji" } }, [_vm._v("Fiji")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Finland" } }, [_vm._v("Finland")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "France" } }, [_vm._v("France")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "French Guiana" } }, [
        _vm._v("French Guiana")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "French Polynesia" } }, [
        _vm._v("French Polynesia")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "French Southern Territories" } }, [
        _vm._v("French Southern Territories")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Gabon" } }, [_vm._v("Gabon")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Gambia" } }, [_vm._v("Gambia")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Georgia" } }, [_vm._v("Georgia")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Germany" } }, [_vm._v("Germany")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Ghana" } }, [_vm._v("Ghana")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Gibraltar" } }, [_vm._v("Gibraltar")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Greece" } }, [_vm._v("Greece")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Greenland" } }, [_vm._v("Greenland")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Grenada" } }, [_vm._v("Grenada")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Guadeloupe" } }, [_vm._v("Guadeloupe")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Guam" } }, [_vm._v("Guam")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Guatemala" } }, [_vm._v("Guatemala")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Guernsey" } }, [_vm._v("Guernsey")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Guinea" } }, [_vm._v("Guinea")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Guinea-bissau" } }, [
        _vm._v("Guinea-bissau")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Guyana" } }, [_vm._v("Guyana")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Haiti" } }, [_vm._v("Haiti")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Heard Island and Mcdonald Islands" } }, [
        _vm._v("Heard Island and Mcdonald Islands")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Holy See (Vatican City State)" } }, [
        _vm._v("Holy See (Vatican City State)")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Honduras" } }, [_vm._v("Honduras")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Hong Kong" } }, [_vm._v("Hong Kong")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Hungary" } }, [_vm._v("Hungary")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Iceland" } }, [_vm._v("Iceland")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "India" } }, [_vm._v("India")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Indonesia" } }, [_vm._v("Indonesia")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Iran, Islamic Republic of" } }, [
        _vm._v("Iran, Islamic Republic of")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Iraq" } }, [_vm._v("Iraq")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Ireland" } }, [_vm._v("Ireland")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Isle of Man" } }, [
        _vm._v("Isle of Man")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Israel" } }, [_vm._v("Israel")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Italy" } }, [_vm._v("Italy")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Jamaica" } }, [_vm._v("Jamaica")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Japan" } }, [_vm._v("Japan")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Jersey" } }, [_vm._v("Jersey")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Jordan" } }, [_vm._v("Jordan")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Kazakhstan" } }, [_vm._v("Kazakhstan")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Kenya" } }, [_vm._v("Kenya")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Kiribati" } }, [_vm._v("Kiribati")]),
      _vm._v(" "),
      _c(
        "option",
        { attrs: { value: "Korea, Democratic People's Republic of" } },
        [_vm._v("Korea, Democratic People's Republic of")]
      ),
      _vm._v(" "),
      _c("option", { attrs: { value: "Korea, Republic of" } }, [
        _vm._v("Korea, Republic of")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Kuwait" } }, [_vm._v("Kuwait")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Kyrgyzstan" } }, [_vm._v("Kyrgyzstan")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Lao People's Democratic Republic" } }, [
        _vm._v("Lao People's Democratic Republic")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Latvia" } }, [_vm._v("Latvia")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Lebanon" } }, [_vm._v("Lebanon")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Lesotho" } }, [_vm._v("Lesotho")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Liberia" } }, [_vm._v("Liberia")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Libyan Arab Jamahiriya" } }, [
        _vm._v("Libyan Arab Jamahiriya")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Liechtenstein" } }, [
        _vm._v("Liechtenstein")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Lithuania" } }, [_vm._v("Lithuania")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Luxembourg" } }, [_vm._v("Luxembourg")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Macao" } }, [_vm._v("Macao")]),
      _vm._v(" "),
      _c(
        "option",
        { attrs: { value: "Macedonia, The Former Yugoslav Republic of" } },
        [_vm._v("Macedonia, The Former Yugoslav Republic of")]
      ),
      _vm._v(" "),
      _c("option", { attrs: { value: "Madagascar" } }, [_vm._v("Madagascar")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Malawi" } }, [_vm._v("Malawi")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Malaysia" } }, [_vm._v("Malaysia")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Maldives" } }, [_vm._v("Maldives")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Mali" } }, [_vm._v("Mali")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Malta" } }, [_vm._v("Malta")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Marshall Islands" } }, [
        _vm._v("Marshall Islands")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Martinique" } }, [_vm._v("Martinique")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Mauritania" } }, [_vm._v("Mauritania")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Mauritius" } }, [_vm._v("Mauritius")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Mayotte" } }, [_vm._v("Mayotte")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Mexico" } }, [_vm._v("Mexico")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Micronesia, Federated States of" } }, [
        _vm._v("Micronesia, Federated States of")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Moldova, Republic of" } }, [
        _vm._v("Moldova, Republic of")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Monaco" } }, [_vm._v("Monaco")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Mongolia" } }, [_vm._v("Mongolia")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Montenegro" } }, [_vm._v("Montenegro")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Montserrat" } }, [_vm._v("Montserrat")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Morocco" } }, [_vm._v("Morocco")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Mozambique" } }, [_vm._v("Mozambique")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Myanmar" } }, [_vm._v("Myanmar")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Namibia" } }, [_vm._v("Namibia")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Nauru" } }, [_vm._v("Nauru")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Nepal" } }, [_vm._v("Nepal")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Netherlands" } }, [
        _vm._v("Netherlands")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Netherlands Antilles" } }, [
        _vm._v("Netherlands Antilles")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "New Caledonia" } }, [
        _vm._v("New Caledonia")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "New Zealand" } }, [
        _vm._v("New Zealand")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Nicaragua" } }, [_vm._v("Nicaragua")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Niger" } }, [_vm._v("Niger")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Nigeria" } }, [_vm._v("Nigeria")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Niue" } }, [_vm._v("Niue")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Norfolk Island" } }, [
        _vm._v("Norfolk Island")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Northern Mariana Islands" } }, [
        _vm._v("Northern Mariana Islands")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Norway" } }, [_vm._v("Norway")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Oman" } }, [_vm._v("Oman")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Pakistan" } }, [_vm._v("Pakistan")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Palau" } }, [_vm._v("Palau")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Palestinian Territory, Occupied" } }, [
        _vm._v("Palestinian Territory, Occupied")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Panama" } }, [_vm._v("Panama")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Papua New Guinea" } }, [
        _vm._v("Papua New Guinea")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Paraguay" } }, [_vm._v("Paraguay")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Peru" } }, [_vm._v("Peru")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Philippines" } }, [
        _vm._v("Philippines")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Pitcairn" } }, [_vm._v("Pitcairn")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Poland" } }, [_vm._v("Poland")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Portugal" } }, [_vm._v("Portugal")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Puerto Rico" } }, [
        _vm._v("Puerto Rico")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Qatar" } }, [_vm._v("Qatar")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Reunion" } }, [_vm._v("Reunion")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Romania" } }, [_vm._v("Romania")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Russian Federation" } }, [
        _vm._v("Russian Federation")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Rwanda" } }, [_vm._v("Rwanda")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Saint Helena" } }, [
        _vm._v("Saint Helena")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Saint Kitts and Nevis" } }, [
        _vm._v("Saint Kitts and Nevis")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Saint Lucia" } }, [
        _vm._v("Saint Lucia")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Saint Pierre and Miquelon" } }, [
        _vm._v("Saint Pierre and Miquelon")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Saint Vincent and The Grenadines" } }, [
        _vm._v("Saint Vincent and The Grenadines")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Samoa" } }, [_vm._v("Samoa")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "San Marino" } }, [_vm._v("San Marino")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Sao Tome and Principe" } }, [
        _vm._v("Sao Tome and Principe")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Saudi Arabia" } }, [
        _vm._v("Saudi Arabia")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Scotland" } }, [_vm._v("Scotland")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Senegal" } }, [_vm._v("Senegal")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Serbia" } }, [_vm._v("Serbia")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Seychelles" } }, [_vm._v("Seychelles")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Sierra Leone" } }, [
        _vm._v("Sierra Leone")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Singapore" } }, [_vm._v("Singapore")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Slovakia" } }, [_vm._v("Slovakia")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Slovenia" } }, [_vm._v("Slovenia")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Solomon Islands" } }, [
        _vm._v("Solomon Islands")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Somalia" } }, [_vm._v("Somalia")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "South Africa" } }, [
        _vm._v("South Africa")
      ]),
      _vm._v(" "),
      _c(
        "option",
        { attrs: { value: "South Georgia and The South Sandwich Islands" } },
        [_vm._v("South Georgia and The South Sandwich Islands")]
      ),
      _vm._v(" "),
      _c("option", { attrs: { value: "Spain" } }, [_vm._v("Spain")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Sri Lanka" } }, [_vm._v("Sri Lanka")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Sudan" } }, [_vm._v("Sudan")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Suriname" } }, [_vm._v("Suriname")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Svalbard and Jan Mayen" } }, [
        _vm._v("Svalbard and Jan Mayen")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Swaziland" } }, [_vm._v("Swaziland")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Sweden" } }, [_vm._v("Sweden")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Switzerland" } }, [
        _vm._v("Switzerland")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Syrian Arab Republic" } }, [
        _vm._v("Syrian Arab Republic")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Taiwan" } }, [_vm._v("Taiwan")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Tajikistan" } }, [_vm._v("Tajikistan")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Tanzania, United Republic of" } }, [
        _vm._v("Tanzania, United Republic of")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Thailand" } }, [_vm._v("Thailand")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Timor-leste" } }, [
        _vm._v("Timor-leste")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Togo" } }, [_vm._v("Togo")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Tokelau" } }, [_vm._v("Tokelau")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Tonga" } }, [_vm._v("Tonga")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Trinidad and Tobago" } }, [
        _vm._v("Trinidad and Tobago")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Tunisia" } }, [_vm._v("Tunisia")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Turkey" } }, [_vm._v("Turkey")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Turkmenistan" } }, [
        _vm._v("Turkmenistan")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Turks and Caicos Islands" } }, [
        _vm._v("Turks and Caicos Islands")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Tuvalu" } }, [_vm._v("Tuvalu")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Uganda" } }, [_vm._v("Uganda")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Ukraine" } }, [_vm._v("Ukraine")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "United Arab Emirates" } }, [
        _vm._v("United Arab Emirates")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "UK" } }, [_vm._v("United Kingdom")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "US" } }, [_vm._v("United States")]),
      _vm._v(" "),
      _c(
        "option",
        { attrs: { value: "United States Minor Outlying Islands" } },
        [_vm._v("United States Minor Outlying Islands")]
      ),
      _vm._v(" "),
      _c("option", { attrs: { value: "Uruguay" } }, [_vm._v("Uruguay")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Uzbekistan" } }, [_vm._v("Uzbekistan")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Vanuatu" } }, [_vm._v("Vanuatu")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Venezuela" } }, [_vm._v("Venezuela")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Viet Nam" } }, [_vm._v("Viet Nam")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Virgin Islands, British" } }, [
        _vm._v("Virgin Islands, British")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Virgin Islands, U.S." } }, [
        _vm._v("Virgin Islands, U.S.")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Wales" } }, [_vm._v("Wales")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Wallis and Futuna" } }, [
        _vm._v("Wallis and Futuna")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Western Sahara" } }, [
        _vm._v("Western Sahara")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Yemen" } }, [_vm._v("Yemen")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Zambia" } }, [_vm._v("Zambia")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Zimbabwe" } }, [_vm._v("Zimbabwe")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Description")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Website")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Company Type")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("optgroup", { attrs: { label: "Other" } }, [
      _c("option", { attrs: { value: "2886" } }, [
        _vm._v("Academic/Research Institution")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "2887" } }, [_vm._v("Government")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "2888" } }, [
        _vm._v("Industry Association/NGO")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "2889" } }, [_vm._v("Military")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "2890" } }, [_vm._v("(Other / Unknown)")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("CVCA Member?")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Diversity")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "tooltip", attrs: { href: "#" } }, [
      _vm._v("BIPOC "),
      _c("span", { staticClass: "classic" }, [
        _vm._v("BIPOC refers to black, indigenous, and other people of color")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "tooltip", attrs: { href: "#" } }, [
      _vm._v("management team "),
      _c("span", { staticClass: "classic" }, [
        _vm._v("Management team is limited to individuals in the C-Suite")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "tooltip", attrs: { href: "#" } }, [
      _vm._v("management team "),
      _c("span", { staticClass: "classic" }, [
        _vm._v("Management team is limited to individuals in the C-Suite")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "tooltip", attrs: { href: "#" } }, [
      _vm._v("BIPOC "),
      _c("span", { staticClass: "classic" }, [
        _vm._v("BIPOC refers to black, indigenous, and other people of color")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Tags")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }