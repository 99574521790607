var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "field multiselect-wrapper" },
    [
      _c(
        "multiselect",
        {
          attrs: {
            options: _vm.options,
            multiple: true,
            "close-on-select": true,
            "clear-on-select": true,
            "preserve-search": true,
            placeholder: "Tag name",
            label: "name",
            "track-by": "name",
            "preselect-first": false,
            "open-direction": "bottom"
          },
          on: {
            select: function($event) {
              _vm.options = []
            },
            "search-change": _vm.asyncFind
          },
          model: {
            value: _vm.tags,
            callback: function($$v) {
              _vm.tags = $$v
            },
            expression: "tags"
          }
        },
        [
          _vm.isLoading
            ? _c("span", { attrs: { slot: "noResult" }, slot: "noResult" }, [
                _vm._v("Searching ...")
              ])
            : _c("span", { attrs: { slot: "noResult" }, slot: "noResult" }, [
                _vm._v("\n            No tags found that match "),
                _c("em", [_vm._v(_vm._s(_vm.lastSearch))]),
                _vm._v(".\n        ")
              ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "noOptions",
              attrs: { slot: "noOptions" },
              slot: "noOptions"
            },
            [_vm._v("\n            Start typing to search for tags.\n        ")]
          )
        ]
      ),
      _vm._v(" "),
      _vm.formField != ""
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.tagIDs,
                expression: "tagIDs"
              }
            ],
            staticClass: "hidden",
            attrs: { type: "text", tabindex: "-1", name: _vm.formField },
            domProps: { value: _vm.tagIDs },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.tagIDs = $event.target.value
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }