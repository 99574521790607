var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "field multiselect-wrapper" },
    [
      _c(
        "multiselect",
        {
          attrs: {
            options: _vm.fundList,
            multiple: _vm.multiple,
            "close-on-select": true,
            "clear-on-select": _vm.multiple,
            "preserve-search": false,
            disabled: _vm.isDisabled,
            placeholder: _vm.placeholder,
            label: "name",
            "track-by": "name",
            "preselect-first": false,
            "open-direction": "bottom"
          },
          on: {
            "search-change": _vm.onSearchChange,
            tag: _vm.onCreateFund,
            open: function($event) {
              _vm.multiselectOpen = true
            },
            close: function($event) {
              _vm.multiselectOpen = false
            }
          },
          model: {
            value: _vm.selected,
            callback: function($$v) {
              _vm.selected = $$v
            },
            expression: "selected"
          }
        },
        [
          _vm.fundList.length || _vm.lastSearch.length
            ? _c("li", { attrs: { slot: "afterList" }, slot: "afterList" }, [
                _c("span", { staticClass: "multiselect__option afterList" }, [
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.createFund = true
                        }
                      }
                    },
                    [
                      _c("span", [_vm._v("Looking for a different fund?")]),
                      _vm._v(" "),
                      _c("strong", [_vm._v("Tell us about it ›")])
                    ]
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("span", { attrs: { slot: "noResult" }, slot: "noResult" }, [
            _vm._v('\n            No funds found that match "'),
            _c("em", [_vm._v(_vm._s(_vm.lastSearch))]),
            _vm._v('"\n        ')
          ]),
          _vm._v(" "),
          _c("span", { attrs: { slot: "noOptions" }, slot: "noOptions" }, [
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.createFund = true
                  }
                }
              },
              [
                _c("span", [_vm._v("No existing funds found.")]),
                _vm._v(" "),
                _c("strong", [_vm._v("Create the first fund ›")])
              ]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _vm.multiselectOpen
        ? _c("div", {
            staticStyle: {
              position: "fixed",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              background: "rgba(0,0,0,0.5)",
              "z-index": "100"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.createFund
        ? _c("modal-create-fund", {
            attrs: { name: _vm.lastSearch, owner: _vm.member.id },
            on: {
              created: _vm.onFundCreated,
              closed: function($event) {
                _vm.createFund = false
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.formField != ""
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.fundIDs,
                expression: "fundIDs"
              }
            ],
            staticClass: "hidden",
            attrs: {
              type: "text",
              tabindex: "-1",
              required: _vm.required,
              name: _vm.formField
            },
            domProps: { value: _vm.fundIDs },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.fundIDs = $event.target.value
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.formField != ""
        ? _c("input", {
            attrs: { type: "hidden", name: _vm.formField + "_json" },
            domProps: { value: JSON.stringify(_vm.selected) }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }