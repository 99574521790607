var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      attrs: {
        method: "post",
        autocomplete: "off",
        action: "/collect/investment"
      },
      on: {
        submit: function($event) {
          _vm.isSaving = true
        }
      }
    },
    [
      _c("input", {
        attrs: { type: "hidden", name: _vm.csrfName },
        domProps: { value: _vm.csrfToken }
      }),
      _vm._v(" "),
      _vm.isAdmin
        ? _c("input", {
            attrs: { type: "hidden", name: "isAdmin", value: "true" }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("find-or-create-deal", {
        attrs: {
          "show-lead-investor": !_vm.isAdmin,
          "show-disclosure": !_vm.isAdmin,
          "show-tags": _vm.isAdmin,
          edit: _vm.edit
        },
        on: {
          "set-deal": _vm.onSetDeal,
          "set-disclosure": _vm.onSetDisclosure,
          "need-details": _vm.onNeedDetails
        }
      }),
      _vm._v(" "),
      !_vm.isAdmin
        ? _c("section", { staticClass: "box" }, [
            _c("div", { staticClass: "section-title title is-4" }, [
              _vm._v("Your Participation")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "section-content" }, [
              _c("div", { staticClass: "field is-horizontal mb-4" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "field-body" }, [
                  _c(
                    "div",
                    { staticClass: "field is-expanded" },
                    [
                      _c("find-or-create-fund", {
                        attrs: {
                          multiple: true,
                          member: _vm.member,
                          "form-field": "participant_fund",
                          funds: Array.isArray(_vm.member.fundList)
                            ? {}
                            : _vm.member.fundList,
                          edit: _vm.edit
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "field" }, [
                        _vm.disclosure == "Partial"
                          ? _c("label", { staticClass: "checkbox" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.confFundName,
                                    expression: "confFundName"
                                  }
                                ],
                                attrs: {
                                  type: "checkbox",
                                  value: "1",
                                  name: "participant_confFundName"
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.confFundName)
                                    ? _vm._i(_vm.confFundName, "1") > -1
                                    : _vm.confFundName
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.confFundName,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = "1",
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.confFundName = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.confFundName = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.confFundName = $$c
                                    }
                                  }
                                }
                              }),
                              _vm._v(
                                " Keep fund name confidential\n                            "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.disclosure == "Confidential"
                          ? _c(
                              "label",
                              { staticClass: "checkbox confidential" },
                              [
                                _c("input", {
                                  attrs: {
                                    type: "checkbox",
                                    value: "1",
                                    disabled: ""
                                  },
                                  domProps: { checked: true }
                                }),
                                _vm._v(
                                  " Keep fund name confidential\n                                "
                                ),
                                _c("input", {
                                  attrs: {
                                    type: "hidden",
                                    value: "1",
                                    name: "participant_confFundName"
                                  }
                                })
                              ]
                            )
                          : _vm._e()
                      ])
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("div", { staticClass: "field is-horizontal" }, [
                _vm._m(1),
                _vm._v(" "),
                _c("div", { staticClass: "field-body" }, [
                  _c("div", { staticClass: "control" }, [
                    _c("div", { staticClass: "field select" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.currency,
                              expression: "currency"
                            }
                          ],
                          attrs: { name: "deal_currency", required: "" },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.currency = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        [
                          _c("option", { attrs: { value: "CAD" } }, [
                            _vm._v("CAD $")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "USD" } }, [
                            _vm._v("USD $")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "GBP" } }, [
                            _vm._v("GBP £")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "Euro" } }, [
                            _vm._v("Euro €")
                          ])
                        ]
                      )
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "field is-horizontal mb-4" }, [
                _vm._m(2),
                _vm._v(" "),
                _c("div", { staticClass: "field-body" }, [
                  _c("div", { staticClass: "field has-addons" }, [
                    _c("div", { staticClass: "control  has-icons-left" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.investedEquity,
                            expression: "investedEquity"
                          }
                        ],
                        staticClass: "input",
                        attrs: {
                          type: "number",
                          step: ".001",
                          pattern: "^\\d*(\\.\\d{0,3})?$",
                          name: "participant_investedEquity",
                          value: "0",
                          min: "0",
                          max: "99999",
                          required: ""
                        },
                        domProps: { value: _vm.investedEquity },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.investedEquity = $event.target.value
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm._m(3)
                    ]),
                    _vm._v(" "),
                    _vm._m(4)
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "field is-horizontal mb-4" }, [
                _vm._m(5),
                _vm._v(" "),
                _c("div", { staticClass: "field-body" }, [
                  _c("div", { staticClass: "field is-expanded" }, [
                    _c("div", { staticClass: "field has-addons" }, [
                      _c("div", { staticClass: "control has-icons-left" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.investedDebt,
                              expression: "investedDebt"
                            }
                          ],
                          staticClass: "input",
                          attrs: {
                            type: "number",
                            step: ".001",
                            pattern: "^\\d*(\\.\\d{0,3})?$",
                            name: "participant_investedDebt",
                            value: "0",
                            min: "0",
                            max: "99999",
                            required: ""
                          },
                          domProps: { value: _vm.investedDebt },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.investedDebt = $event.target.value
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm._m(6)
                      ]),
                      _vm._v(" "),
                      _vm._m(7)
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "field" }, [
                      _vm.disclosure == "Partial"
                        ? _c("label", { staticClass: "checkbox" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.confTransaction,
                                  expression: "confTransaction"
                                }
                              ],
                              attrs: {
                                type: "checkbox",
                                value: "1",
                                name: "participant_confTransaction"
                              },
                              domProps: {
                                checked: Array.isArray(_vm.confTransaction)
                                  ? _vm._i(_vm.confTransaction, "1") > -1
                                  : _vm.confTransaction
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.confTransaction,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "1",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.confTransaction = $$a.concat([
                                          $$v
                                        ]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.confTransaction = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.confTransaction = $$c
                                  }
                                }
                              }
                            }),
                            _vm._v(
                              " Keep transaction amount confidential\n                            "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.disclosure == "Confidential"
                        ? _c(
                            "label",
                            { staticClass: "checkbox confidential" },
                            [
                              _c("input", {
                                attrs: {
                                  type: "checkbox",
                                  value: "1",
                                  disabled: ""
                                },
                                domProps: { checked: true }
                              }),
                              _vm._v(
                                " Keep transaction amount confidential                                           \n                                "
                              ),
                              _c("input", {
                                attrs: {
                                  type: "hidden",
                                  value: "1",
                                  name: "participant_confTransaction"
                                }
                              })
                            ]
                          )
                        : _vm._e()
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "field is-horizontal" }, [
                _vm._m(8),
                _vm._v(" "),
                _c("div", { staticClass: "field-body" }, [
                  _c("div", { staticClass: "control" }, [
                    _c("div", { staticClass: "field select" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.instruments,
                              expression: "instruments"
                            }
                          ],
                          attrs: {
                            name: "participant_instruments",
                            required: ""
                          },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.instruments = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v(" - Please Select - ")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "Common Shares" } }, [
                            _vm._v("Common Shares")
                          ]),
                          _vm._v(" "),
                          _c(
                            "option",
                            { attrs: { value: "Convertible Debt" } },
                            [
                              _vm._v(
                                "Convertible Debt (Including Convertible Notes)"
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "option",
                            { attrs: { value: "Preferred Shares" } },
                            [_vm._v("Preferred Shares")]
                          ),
                          _vm._v(" "),
                          _c(
                            "option",
                            { attrs: { value: "Restricted Stock" } },
                            [_vm._v("Restricted Stock")]
                          ),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "SAFE Notes" } }, [
                            _vm._v("SAFE Notes")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "Venture Debt" } }, [
                            _vm._v("Venture Debt")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "Warrants" } }, [
                            _vm._v("Warrants")
                          ])
                        ]
                      )
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "field is-horizontal mb-4" }, [
                _vm._m(9),
                _vm._v(" "),
                _c("div", { staticClass: "field-body" }, [
                  _c("div", { staticClass: "field" }, [
                    _c("div", { staticClass: "control radios" }, [
                      _c("label", { staticClass: "radio" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.followOn,
                              expression: "followOn"
                            }
                          ],
                          attrs: {
                            type: "radio",
                            name: "participant_followOn",
                            value: "1"
                          },
                          domProps: { checked: _vm._q(_vm.followOn, "1") },
                          on: {
                            change: function($event) {
                              _vm.followOn = "1"
                            }
                          }
                        }),
                        _vm._v("Yes")
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "radio" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.followOn,
                              expression: "followOn"
                            }
                          ],
                          attrs: {
                            type: "radio",
                            name: "participant_followOn",
                            value: "0"
                          },
                          domProps: { checked: _vm._q(_vm.followOn, "0") },
                          on: {
                            change: function($event) {
                              _vm.followOn = "0"
                            }
                          }
                        }),
                        _vm._v("No")
                      ])
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "field is-horizontal mb-4" }, [
                _vm._m(10),
                _vm._v(" "),
                _c("div", { staticClass: "field-body" }, [
                  _c("div", { staticClass: "field" }, [
                    _c("div", { staticClass: "control radios" }, [
                      _c("label", { staticClass: "radio" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.tranched,
                              expression: "tranched"
                            }
                          ],
                          attrs: {
                            type: "radio",
                            name: "participant_tranched",
                            value: "1"
                          },
                          domProps: { checked: _vm._q(_vm.tranched, "1") },
                          on: {
                            change: function($event) {
                              _vm.tranched = "1"
                            }
                          }
                        }),
                        _vm._v("Yes")
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "radio" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.tranched,
                              expression: "tranched"
                            }
                          ],
                          attrs: {
                            type: "radio",
                            name: "participant_tranched",
                            value: "0"
                          },
                          domProps: { checked: _vm._q(_vm.tranched, "0") },
                          on: {
                            change: function($event) {
                              _vm.tranched = "0"
                            }
                          }
                        }),
                        _vm._v("No")
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isAdmin || _vm.showDealDetails
        ? _c("section", { staticClass: "box" }, [
            _c("div", { staticClass: "section-title title is-4" }, [
              _vm._v("Deal Information")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "section-content" }, [
              _vm.isAdmin
                ? _c("input", {
                    attrs: {
                      type: "hidden",
                      name: "deal_status",
                      value: "reported"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isAdmin && !_vm.isLeadInvestor
                ? _c("input", {
                    attrs: {
                      type: "hidden",
                      name: "deal_status",
                      value: "confirmed"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isLeadInvestor
                ? _c("input", {
                    attrs: {
                      type: "hidden",
                      name: "deal_status",
                      value: "lead-confirmed"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "field is-horizontal mb-4" }, [
                _vm._m(11),
                _vm._v(" "),
                _c("div", { staticClass: "field-body select" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.dealType,
                          expression: "dealType"
                        }
                      ],
                      attrs: { name: "deal_type", required: "" },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.dealType = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(" - Please Select - ")
                      ]),
                      _vm._v(" "),
                      _vm._m(12),
                      _vm._v(" "),
                      _vm._m(13)
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "field is-horizontal mb-4" }, [
                _vm._m(14),
                _vm._v(" "),
                _c("div", { staticClass: "field-body select" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.round,
                          expression: "round"
                        }
                      ],
                      attrs: { name: "deal_round" },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.round = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(" - Please Select - ")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "1693" } }, [
                        _vm._v("Pre-Seed")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "1695" } }, [
                        _vm._v("Seed")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "1696" } }, [
                        _vm._v("Series A")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "1697" } }, [
                        _vm._v("Series B")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "1698" } }, [
                        _vm._v("Series C")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "1699" } }, [
                        _vm._v("Series D")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "1700" } }, [
                        _vm._v("Series E")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "1701" } }, [
                        _vm._v("Series F")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "1702" } }, [
                        _vm._v("Series G")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "1703" } }, [
                        _vm._v("Series H")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "1704" } }, [
                        _vm._v("Series I")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "1705" } }, [
                        _vm._v("Series J")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "1706" } }, [
                        _vm._v("Series K")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "1707" } }, [
                        _vm._v("Series L")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "1708" } }, [
                        _vm._v("Series M")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "1709" } }, [
                        _vm._v("Series N")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "1692" } }, [
                        _vm._v("Debt")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "1694" } }, [
                        _vm._v("Private Equity")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "1710" } }, [
                        _vm._v("Unknown")
                      ])
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _vm.showAcquiring
                ? _c("div", [
                    _c("div", { staticClass: "field is-horizontal mb-4" }, [
                      _vm._m(15),
                      _vm._v(" "),
                      _c("div", { staticClass: "field-body" }, [
                        _c(
                          "div",
                          { staticClass: "field is-expanded" },
                          [
                            _vm.editing && _vm.edit.acquirer
                              ? _c("div", { staticClass: "field-body" }, [
                                  _c("div", { staticClass: "control" }, [
                                    _c("input", {
                                      staticClass: "input",
                                      attrs: { type: "text", disabled: "" },
                                      domProps: {
                                        value: _vm.edit.acquirer.name
                                      }
                                    })
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    attrs: {
                                      type: "hidden",
                                      name: "deal_acquirer"
                                    },
                                    domProps: { value: _vm.edit.acquirer.id }
                                  }),
                                  _vm._v(" "),
                                  _c("input", {
                                    attrs: {
                                      type: "hidden",
                                      name: "deal_acquirer_json"
                                    },
                                    domProps: {
                                      value: JSON.stringify(_vm.edit.acquirer)
                                    }
                                  })
                                ])
                              : _c("find-or-create-company", {
                                  attrs: {
                                    multiple: false,
                                    required: true,
                                    hasTags: true,
                                    "form-field": "deal_acquirer",
                                    "type-filter-not": "Service Provider -",
                                    edit: _vm.edit
                                  }
                                }),
                            _vm._v(" "),
                            _c("div", { staticClass: "field" }, [
                              _vm.disclosure == "Partial"
                                ? _c("label", { staticClass: "checkbox" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.confAcquirer,
                                          expression: "confAcquirer"
                                        }
                                      ],
                                      attrs: {
                                        type: "checkbox",
                                        value: "1",
                                        name: "deal_confAcquirer"
                                      },
                                      domProps: {
                                        checked: Array.isArray(_vm.confAcquirer)
                                          ? _vm._i(_vm.confAcquirer, "1") > -1
                                          : _vm.confAcquirer
                                      },
                                      on: {
                                        change: function($event) {
                                          var $$a = _vm.confAcquirer,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = "1",
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.confAcquirer = $$a.concat([
                                                  $$v
                                                ]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.confAcquirer = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.confAcquirer = $$c
                                          }
                                        }
                                      }
                                    }),
                                    _vm._v(
                                      " Keep acquired company confidential\n                                "
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.disclosure == "Confidential"
                                ? _c(
                                    "label",
                                    { staticClass: "checkbox confidential" },
                                    [
                                      _c("input", {
                                        attrs: {
                                          type: "checkbox",
                                          value: "1",
                                          disabled: ""
                                        },
                                        domProps: { checked: true }
                                      }),
                                      _vm._v(
                                        " Keep acquired company confidential\n                                    "
                                      ),
                                      _c("input", {
                                        attrs: {
                                          type: "hidden",
                                          value: "1",
                                          name: "deal_confAcquirer"
                                        }
                                      })
                                    ]
                                  )
                                : _vm._e()
                            ])
                          ],
                          1
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.showStock
                      ? _c("div", [
                          _c("hr"),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "field is-horizontal mb-4" },
                            [
                              _vm._m(16),
                              _vm._v(" "),
                              _c("div", { staticClass: "field-body" }, [
                                _c(
                                  "div",
                                  { staticClass: "field is-expanded" },
                                  [
                                    _c("div", { staticClass: "field" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.stockExchange,
                                            expression: "stockExchange"
                                          }
                                        ],
                                        staticClass: "input",
                                        attrs: {
                                          type: "text",
                                          required: "",
                                          name: "participant_stockExchange"
                                        },
                                        domProps: { value: _vm.stockExchange },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.stockExchange =
                                              $event.target.value
                                          }
                                        }
                                      })
                                    ])
                                  ]
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "field is-horizontal mb-4" },
                            [
                              _vm._m(17),
                              _vm._v(" "),
                              _c("div", { staticClass: "field-body" }, [
                                _c(
                                  "div",
                                  { staticClass: "field is-expanded" },
                                  [
                                    _c("div", { staticClass: "field" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.stockSymbol,
                                            expression: "stockSymbol"
                                          }
                                        ],
                                        staticClass: "input",
                                        attrs: {
                                          type: "text",
                                          required: "",
                                          name: "participant_stockSymbol"
                                        },
                                        domProps: { value: _vm.stockSymbol },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.stockSymbol =
                                              $event.target.value
                                          }
                                        }
                                      })
                                    ])
                                  ]
                                )
                              ])
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("hr")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "field is-horizontal mb-4" }, [
                _vm._m(18),
                _vm._v(" "),
                _c("div", { staticClass: "field-body" }, [
                  _c("div", { staticClass: "field is-expanded" }, [
                    _c("div", { staticClass: "field has-addons" }, [
                      _vm.isAdmin
                        ? _c("p", { staticClass: "control" }, [
                            _c("span", { staticClass: "select" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.currency,
                                      expression: "currency"
                                    }
                                  ],
                                  attrs: {
                                    name: "deal_currency",
                                    required: ""
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.currency = $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    }
                                  }
                                },
                                [
                                  _c("option", { attrs: { value: "CAD" } }, [
                                    _vm._v("CAD $")
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "USD" } }, [
                                    _vm._v("USD $")
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "GBP" } }, [
                                    _vm._v("GBP £")
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "Euro" } }, [
                                    _vm._v("Euro €")
                                  ])
                                ]
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "control",
                          class: { "has-icons-left": !_vm.isAdmin }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.totalSize,
                                expression: "totalSize"
                              }
                            ],
                            staticClass: "input",
                            attrs: {
                              type: "number",
                              step: ".001",
                              pattern: "^\\d*(\\.\\d{0,3})?$",
                              name: "deal_totalSize",
                              min: "0",
                              max: "99999"
                            },
                            domProps: { value: _vm.totalSize },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.totalSize = $event.target.value
                              }
                            }
                          }),
                          _vm._v(" "),
                          !_vm.isAdmin
                            ? _c("span", { staticClass: "icon is-left" }, [
                                _c("i", { staticClass: "fas fa-dollar-sign" })
                              ])
                            : _vm._e()
                        ]
                      ),
                      _vm._v(" "),
                      _vm._m(19)
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "field" }, [
                      _vm.disclosure == "Partial"
                        ? _c("label", { staticClass: "checkbox" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.confDealSize,
                                  expression: "confDealSize"
                                }
                              ],
                              attrs: {
                                type: "checkbox",
                                value: "1",
                                name: "deal_confDealSize"
                              },
                              domProps: {
                                checked: Array.isArray(_vm.confDealSize)
                                  ? _vm._i(_vm.confDealSize, "1") > -1
                                  : _vm.confDealSize
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.confDealSize,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "1",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.confDealSize = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.confDealSize = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.confDealSize = $$c
                                  }
                                }
                              }
                            }),
                            _vm._v(
                              " Keep deal size confidential\n                            "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.disclosure == "Confidential"
                        ? _c(
                            "label",
                            { staticClass: "checkbox confidential" },
                            [
                              _c("input", {
                                attrs: {
                                  type: "checkbox",
                                  value: "1",
                                  disabled: ""
                                },
                                domProps: { checked: true }
                              }),
                              _vm._v(
                                " Keep deal size confidential\n                                "
                              ),
                              _c("input", {
                                attrs: {
                                  type: "hidden",
                                  value: "1",
                                  name: "deal_confDealSize"
                                }
                              })
                            ]
                          )
                        : _vm._e()
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "field is-horizontal mb-4" }, [
                _vm._m(20),
                _vm._v(" "),
                _c("div", { staticClass: "field-body" }, [
                  _c("div", { staticClass: "field has-addons" }, [
                    _c("div", { staticClass: "control has-icons-left" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.equity,
                            expression: "equity"
                          }
                        ],
                        staticClass: "input",
                        attrs: {
                          type: "number",
                          step: ".001",
                          pattern: "^\\d*(\\.\\d{0,3})?$",
                          name: "deal_equity",
                          min: "0",
                          max: "99999"
                        },
                        domProps: { value: _vm.equity },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.equity = $event.target.value
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm._m(21)
                    ]),
                    _vm._v(" "),
                    _vm._m(22)
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "field is-horizontal mb-4" }, [
                _vm._m(23),
                _vm._v(" "),
                _c("div", { staticClass: "field-body" }, [
                  _c("div", { staticClass: "field has-addons" }, [
                    _c("div", { staticClass: "control has-icons-left" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.debt,
                            expression: "debt"
                          }
                        ],
                        staticClass: "input",
                        attrs: {
                          type: "number",
                          step: ".001",
                          pattern: "^\\d*(\\.\\d{0,3})?$",
                          name: "deal_debt",
                          min: "0",
                          max: "99999"
                        },
                        domProps: { value: _vm.debt },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.debt = $event.target.value
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm._m(24)
                    ]),
                    _vm._v(" "),
                    _vm._m(25)
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("div", { staticClass: "field is-horizontal mb-4" }, [
                _vm._m(26),
                _vm._v(" "),
                _c("div", { staticClass: "field-body" }, [
                  _c("div", { staticClass: "field has-addons" }, [
                    _c("div", { staticClass: "control has-icons-left" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.preMoney,
                            expression: "preMoney"
                          }
                        ],
                        staticClass: "input",
                        attrs: {
                          type: "number",
                          step: ".001",
                          pattern: "^\\d*(\\.\\d{0,3})?$",
                          name: "deal_preMoneyValuation",
                          min: "0",
                          max: "99999"
                        },
                        domProps: { value: _vm.preMoney },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.preMoney = $event.target.value
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm._m(27)
                    ]),
                    _vm._v(" "),
                    _vm._m(28)
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "field is-horizontal mb-4" }, [
                _vm._m(29),
                _vm._v(" "),
                _c("div", { staticClass: "field-body" }, [
                  _c("div", { staticClass: "field has-addons" }, [
                    _c("div", { staticClass: "control has-icons-left" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.secondaryInvestment,
                            expression: "secondaryInvestment"
                          }
                        ],
                        staticClass: "input",
                        attrs: {
                          type: "number",
                          step: ".001",
                          pattern: "^\\d*(\\.\\d{0,3})?$",
                          name: "deal_secondaryInvestment",
                          value: "0",
                          min: "0",
                          max: "99999"
                        },
                        domProps: { value: _vm.secondaryInvestment },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.secondaryInvestment = $event.target.value
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm._m(30)
                    ]),
                    _vm._v(" "),
                    _vm._m(31)
                  ])
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("section", { staticClass: "box" }, [
        _c("div", { staticClass: "section-title title is-4" }, [
          _vm._v("Who Else Was Involved?")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "section-content" }, [
          _vm.isAdmin
            ? _c("div", { staticClass: "field is-horizontal mb-4" }, [
                _vm._m(32),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "field-body" },
                  [
                    _c("find-or-create-company", {
                      attrs: {
                        multiple: true,
                        "form-field": "deal_leadInvestors",
                        hasSectors: false,
                        hasDetails: false,
                        hasType: true,
                        hasMemberFlag: _vm.isAdmin
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "field is-horizontal mb-4" }, [
            _vm._m(33),
            _vm._v(" "),
            _c("div", { staticClass: "field-body" }, [
              _c(
                "div",
                { staticClass: "field is-expanded" },
                [
                  _c("find-or-create-company", {
                    attrs: {
                      multiple: true,
                      required: false,
                      hasTags: false,
                      hasDetails: false,
                      hasSectors: false,
                      hasType: true,
                      hasMemberFlag: _vm.isAdmin,
                      "form-field": "coinvestor_id",
                      "type-filter-not": "Service Provider -",
                      edit: _vm.edit
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "field" }, [
                    _vm.disclosure == "Partial"
                      ? _c("label", { staticClass: "checkbox" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.confCoInvestors,
                                expression: "confCoInvestors"
                              }
                            ],
                            attrs: {
                              type: "checkbox",
                              value: "1",
                              name: "participant_confCoInvestors"
                            },
                            domProps: {
                              checked: Array.isArray(_vm.confCoInvestors)
                                ? _vm._i(_vm.confCoInvestors, "1") > -1
                                : _vm.confCoInvestors
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.confCoInvestors,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = "1",
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.confCoInvestors = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.confCoInvestors = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.confCoInvestors = $$c
                                }
                              }
                            }
                          }),
                          _vm._v(
                            " Keep co-investors confidential\n                            "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.disclosure == "Confidential"
                      ? _c("label", { staticClass: "checkbox confidential" }, [
                          _c("input", {
                            attrs: {
                              type: "checkbox",
                              value: "1",
                              disabled: ""
                            },
                            domProps: { checked: true }
                          }),
                          _vm._v(
                            " Keep co-investors confidential\n                                "
                          ),
                          _c("input", {
                            attrs: {
                              type: "hidden",
                              value: "1",
                              name: "participant_confCoInvestors"
                            }
                          })
                        ])
                      : _vm._e()
                  ])
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("div", { staticClass: "field is-horizontal mb-4" }, [
            _vm._m(34),
            _vm._v(" "),
            _c("div", { staticClass: "field-body" }, [
              _c(
                "div",
                { staticClass: "field is-expanded" },
                [
                  _c("find-or-create-company", {
                    attrs: {
                      multiple: true,
                      required: false,
                      hasTags: false,
                      hasDetails: false,
                      hasSectors: false,
                      hasType: true,
                      hasMemberFlag: _vm.isAdmin,
                      "form-field": "debtProvider_id",
                      "type-filter": "Debt",
                      "create-type-options": "SP",
                      edit: _vm.edit
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "field is-horizontal mb-4" }, [
            _vm._m(35),
            _vm._v(" "),
            _c("div", { staticClass: "field-body" }, [
              _c("div", { staticClass: "field is-expanded" }, [
                _c(
                  "div",
                  { staticClass: "field" },
                  [
                    _c("find-or-create-company", {
                      attrs: {
                        multiple: true,
                        required: false,
                        hasTags: false,
                        hasDetails: false,
                        hasSectors: false,
                        hasType: true,
                        hasMemberFlag: _vm.isAdmin,
                        "form-field": "financialAdvisor_id",
                        "type-filter": "Service Provider -",
                        "create-type-options": "SP",
                        edit: _vm.edit
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "field is-horizontal mb-4" }, [
            _vm._m(36),
            _vm._v(" "),
            _c("div", { staticClass: "field-body" }, [
              _c("div", { staticClass: "field is-expanded" }, [
                _c(
                  "div",
                  { staticClass: "field" },
                  [
                    _c("find-or-create-company", {
                      attrs: {
                        multiple: true,
                        required: false,
                        hasTags: false,
                        hasDetails: false,
                        hasSectors: false,
                        hasType: true,
                        hasMemberFlag: _vm.isAdmin,
                        "form-field": "lawFirm_id",
                        "type-filter": "Law Firm",
                        "create-type-options": "SP",
                        edit: _vm.edit
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "field" }, [
                  _vm.disclosure == "Partial"
                    ? _c("label", { staticClass: "checkbox" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.confAdvisors,
                              expression: "confAdvisors"
                            }
                          ],
                          attrs: {
                            type: "checkbox",
                            value: "1",
                            name: "participant_confAdvisors"
                          },
                          domProps: {
                            checked: Array.isArray(_vm.confAdvisors)
                              ? _vm._i(_vm.confAdvisors, "1") > -1
                              : _vm.confAdvisors
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.confAdvisors,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = "1",
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.confAdvisors = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.confAdvisors = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.confAdvisors = $$c
                              }
                            }
                          }
                        }),
                        _vm._v(
                          " Keep ALL advisors names confidential\n                            "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.disclosure == "Confidential"
                    ? _c("label", { staticClass: "checkbox confidential" }, [
                        _c("input", {
                          attrs: { type: "checkbox", value: "1", disabled: "" },
                          domProps: { checked: true }
                        }),
                        _vm._v(
                          " Keep ALL advisors names confidential\n                                "
                        ),
                        _c("input", {
                          attrs: {
                            type: "hidden",
                            value: "1",
                            name: "participant_confAdvisors"
                          }
                        })
                      ])
                    : _vm._e()
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "field is-grouped" }, [
        _vm._m(37),
        _vm._v(" "),
        _c("div", { staticClass: "control" }, [
          _c(
            "a",
            {
              staticClass: "button is-link is-light",
              attrs: { href: _vm.isAdmin ? "/manage" : "/collect" }
            },
            [_vm._v("Cancel")]
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal", class: { "is-active": _vm.isSaving } },
        [
          _c("div", { staticClass: "modal-background" }),
          _vm._v(" "),
          _vm._m(38)
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Choose Fund(s)")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Currency")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Your Equity")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon is-left" }, [
      _c("i", { staticClass: "fas fa-dollar-sign" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "control" }, [
      _c("a", { staticClass: "button is-static" }, [_vm._v("million")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Your Debt")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon is-left" }, [
      _c("i", { staticClass: "fas fa-dollar-sign" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "control" }, [
      _c("a", { staticClass: "button is-static" }, [_vm._v("million")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Instruments")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label twoLines" }, [
        _vm._v("Is this a follow-on investment?")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Tranched deal?")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Deal Type")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("optgroup", { attrs: { label: "Venture Capital" } }, [
      _c("option", { attrs: { value: "1674" } }, [_vm._v("Seed")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "1665" } }, [_vm._v("Early Stage")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "1667" } }, [_vm._v("Later Stage")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "1666" } }, [_vm._v("Growth")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "1671" } }, [_vm._v("Pre-IPO")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "1670" } }, [_vm._v("PIPE")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "1675" } }, [_vm._v("Venture Debt")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "1664" } }, [_vm._v("Bridge")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "1672" } }, [_vm._v("Secondary")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "1663" } }, [_vm._v("M&A")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "1662" } }, [_vm._v("RTO")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "1668" } }, [_vm._v("Other")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("optgroup", { attrs: { label: "Private Equity" } }, [
      _c("option", { attrs: { value: "1682" } }, [_vm._v("Growth")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "1681" } }, [_vm._v("Follow-On")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "1679" } }, [_vm._v("Buyout")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "1688" } }, [_vm._v("Privatization")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "1689" } }, [_vm._v("Recapitalization")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "1680" } }, [_vm._v("Debt")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "1676" } }, [
        _vm._v("Add-On Acquisition")
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "1678" } }, [_vm._v("M&A")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "1677" } }, [_vm._v("RTO")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "1686" } }, [_vm._v("Platform Creation")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "1687" } }, [_vm._v("Private Placement")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "1683" } }, [_vm._v("Infrastructure")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "1684" } }, [_vm._v("Other")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Round Type")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Acquired Company")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Stock Exchange")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Stock Symbol")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Total Deal Size")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "control" }, [
      _c("a", { staticClass: "button is-static" }, [_vm._v("million")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Total Equity")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon is-left" }, [
      _c("i", { staticClass: "fas fa-dollar-sign" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "control" }, [
      _c("a", { staticClass: "button is-static" }, [_vm._v("million")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Total Debt")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon is-left" }, [
      _c("i", { staticClass: "fas fa-dollar-sign" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "control" }, [
      _c("a", { staticClass: "button is-static" }, [_vm._v("million")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label twoLines" }, [
        _vm._v("Pre-money Company Valuation")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon is-left" }, [
      _c("i", { staticClass: "fas fa-dollar-sign" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "control" }, [
      _c("a", { staticClass: "button is-static" }, [_vm._v("million")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label twoLines" }, [
        _vm._v("How much of this round is a secondary investment?")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon is-left" }, [
      _c("i", { staticClass: "fas fa-dollar-sign" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "control" }, [
      _c("a", { staticClass: "button is-static" }, [_vm._v("million")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Lead Investor(s)")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Other Co-Investors")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Debt Providers")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Financial Advisors")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Law Firms")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "control" }, [
      _c("button", { staticClass: "button is-link" }, [_vm._v("Submit")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "modal-content has-background-white p-4 pt-6 has-text-centered",
        staticStyle: { "border-radius": "2rem" }
      },
      [
        _c("div", { staticClass: "title is-2" }, [_vm._v("Saving Report")]),
        _vm._v(" "),
        _c("p", [_vm._v("Give us a second, this will just take a moment.")]),
        _vm._v(" "),
        _c("img", {
          attrs: { src: "/assets/intelligence/icons/loading.gif", width: "100" }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }