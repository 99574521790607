<template>
    <div class="field is-horizontal mb-4">
        <div class="field-label">
            <label class="label">Sector</label>
        </div>
        <div class="field-body">
            <div class="field">
                <div class="control select">
                    <select class="input" :required="required" :name="`${fieldPrefix}_sector`" v-model="sector">
                        <option value=""> - Please Select -</option>
                        <option v-for="(obj,id) in sectors" :key="id" :value="id">
                            {{ obj.name }}
                        </option>
                    </select>
                </div>
                <p class="help is-danger" v-if="needSector">Please choose a sector</p>
            </div>

            <div class="field">
                <div class="control" v-if="isOther">
                    <input class="input" type="text" :required="required" :name="`${fieldPrefix}_subSector`" v-model="subSector" />
                </div>
                
                <div class="control select" v-else>
                    <select class="input" :required="required" :name="`${fieldPrefix}_subSector`" v-model="subSector">
                        <option value=""> - Please Select -</option>
                        <option v-for="subSector in subSectors" :key="subSector" :value="subSector">
                            {{subSector}}
                        </option>
                    </select>
                </div>
                <p class="help is-danger" v-if="needSubSector">Please choose a sub-sector</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                sector: '',
                subSector: '',
                sectors: {
                    1608: {
                    	name: "Agribusiness",
                    	options: ["Advanced Agriculture", "Agribiotechnology", "Agricultural Products & Related Activities", "Animal Production", "Aquaculture", "Crop Production", "Greenhouses", "Other Agribusiness"]
                    },
                    1609: {
                    	name: "Automotive & Transportation",
                    	options: ["Air Freight & Logistics","Airlines","Airport & Services","Automobile Manufacturing","Automobile Parts","Highways & Rail tracks","Marine Ports & Services","Marine Transportation","Other Transportation Logistics","Road, Rail Transportation","Transportation Services"]
                    },
                    1610: {
                    	name: "Business Products & Services",
                    	options: ["Advertising, Marketing & PR","Building & Properties","Commercial Printing","Consulting & Outsourcing","Education & Training","Facilities Management","HR & Staffing","Information & Records Management","Legal Services","Office furniture & Equipment","Office Supplies","Other Business Products & Services","Packaging","Wholesale"]
                    },
                    1611: {
                    	name: "CleanTech",
                    	options: ["Advanced Materials","Alternative Energy Equipment","Biofuels","Energy Efficiency","Energy Storage","Fuel Cell & Other Electric Technology","Hydro ROR Power","Integrated Renewal Energy","Other CleanTech Related","Solar Power","Thermal and Geothermal","Wind Power"]
                    },
                    1612: {
                    	name: "Computer Hardware & Services",
                    	options: ["Computer Networking Equipment","Computer Product Distribution & Support Services","Computer Storage & Peripherals","Gaming Systems","Handheld Computers & Accessories","IT Services & Outsourcing","Other Computer Hardware & Services","Personal Computers & Notebooks","Servers & Mainframes","Stealth Mode Computer Hardware & Services","Supercomputers","Wearable Technologies","Workstations & Thin Clients"]
                    },
                    1613: {
                    	name: "Consumer Products & Services",
                    	options: ["Apparel & Accessories","Consumer Durables","Consumer Non Durables","Franchise and Related","Other Products & Services"]
                    },
                    1614: {
                    	name: "eCommerce",
                    	options: ["Apparel & Accessories","Auto","Computer & Software","Digital Goods","Discount, Group Buying","Electronics & Appliances","Events & Ticketing","Food & Grocery","Home Furnishings & Improvement","Jewelry","Marketplace","Multi-Products","Music, Video, Books & Entertainment","Office Products","Other Retail & Services","Pharmacies","Tools & Platforms","Toys & Games","Travel (Internet)"]
                    },
                    1615: {
                    	name: "Electronic & Semiconductor",
                    	options: ["Chips & Semiconductors","Electrical Product Distribution","Electrical Products","Electronic Components","Electronic Manufacturing Services (EMS)","Electronic Test, Measurement & Monitoring","Other Electronics","Technical & Scientific Instrumentation"]
                    },
                    1616: {
                    	name: "Energy & Power",
                    	options: ["Energy Storage","Energy Trading & Marketing","Gas/Coal Electric Generation","Oil & Gas Equipment","Oil & Gas Exploration & Production","Oil & Gas Refining & Distribution","Oil & Gas Services","Oil & Gas Storage & Transport","Other Energy Power Related"]
                    },
                    1617: {
                    	name: "Environmental",
                    	options: ["Agri BioProducts","Environmental & Energy Consulting","Hazardous Waste Services","Industrial BioProducts","Other Environmental","Recycling","Remediation & Environmental Cleanup","Renewable Ingredients","Solid Waste","Waste Water & Treatment"]
                    },
                    1618: {
                    	name: "Financial",
                    	options: ["Accounting","Asset & Financial Management","Banks","Credit & Collections","Insurance & Re-insurance","Investment Banking","Investment Firms & Funds","Lending Institutions","Other Financial","Payments (non internet/mobile)","Real Estate","Securities Brokers & Traders","Stock Exchanges"]
                    },
                    1619: {
                    	name: "Food & Beverages",
                    	options: ["Alcoholic Beverages","Bottling & Distribution","Candy & Snackfoods","Canned & Frozen Foods","Dairy Products","Food Safety & Preservation","Fresh Foods","Functional Foods","Ingredients, Flavoring & Condiments","Meat, Fish & Seafood","Natural and Organic Consumer Packaged Goods","Non Alcoholic Beverages","Other Food & Beverages","Wholesale Food Distributors"]
                    },
                    1620: {
                    	name: "Forestry",
                    	options: ["Forest Services","Lumber Manufacturing","Lumber Products","Other Forestry","Paper Products","Wood Products"]
                    },
                    2977: {
                    	name: "Healthcare/Life Sciences",
                    	options: ["Biopharma Services","Dental Products & Services","Diagnostics","Drug Acquisition & Licensing","Drug Delivery","Drug Discovery","Drug Manufacturing & Distribution","eHealth and IT","Health & Wellness","Healthcare Plans","Healthcare Services","Medical Device & Equipment","Medical Facilities & Services","Other Life Sciences Medical Related","Stealth Mode","Therapeutic Drugs & Biologics","Therapeutic Medical Devices"]
                    },
                    2978: {
                    	name: "Industrial & Manufacturing",
                    	options: ["Aerospace & Defense","Chemicals","Construction & Engineering","Construction Materials","Containers & Packaging","Machinery & Equipment","Manufacturing","Other Industrial & Materials","Textiles"]
                    },
                    2979: {
                    	name: "Internet Software & Services",
                    	options: ["Accounting & Finance","Ad Network & Exchange","Advertising, Sales & Marketing","Application, Systems & Utilities","Asset & Financial Management & Trading","BI, Analytics & Performance Management","Billing, Expense Management & Procurement","Collaboration & Project Management","Compliance","Conferencing & Communication","Construction & Design","Content Management","Content Providers, News, Discussion","Customer Relationship Management","Data & Document Management","Data Storage","Database Management","Domain & SEO Services","Education & Training","Gambling","Games and Apps","Government","Green & Environmental","HR & Workforce Management","ISP & Web Hosting","Legal","Loan & Finance","Manufacturing, Warehousing & Industrial","Monitoring & Security","Multimedia & Graphics","Music","Networking & Connectivity","Other Internet Software & Services","Payments","Photo, Video","Real Estate","Retail Software","Scientific, Engineering","Search","Social, Online Community","Sports","Stealth Mode","Supply Chain & Logistics","Travel","Web Development"]
                    },
                    2980: {
                    	name: "Leisure",
                    	options: ["Casinos & Gaming","Entertainment","Hotels & Resorts","Other Leisure","Restaurants","Sports & Recreation"]
                    },
                    2981: {
                    	name: "Media (Traditional)",
                    	options: ["Broadcasting, Radio & Television","Film & Video","Integrated Media","Music","Other Media","Publishing"]
                    },
                    2982: {
                    	name: "Metals & Mining",
                    	options: ["Aluminum","Brokers & Distribution","Coal","Copper & Other Non Ferrous Metals","Diamond & Precious Stones","Diversified Metals & Mining","Gold & Silver","Other Metals & Mining","Precious Metals (non Gold and Silver)","Steel"]
                    },
                    2983: {
                    	name: "Mobile & Telecommunications",
                    	options: ["Fiber Optics","Internet Appliances/Devices","Mobile Commerce","Mobile Devices & Services","Mobile Payment","Mobile Software & Services","Other Mobile & Telecommunications","RFID Systems","Stealth Mode Mobile & Telecommunications","Telecom Devices & Equipment","Telecom/Internet Infrastructure","Telecom/Mobile Services"]
                    },
                    2984: {
                    	name: "Real Estate",
                    	options: ["Commerical & Industrial Properties","Development/Builder","Office Properties","Other Real Estate Related","Property Management","Residential Properties","Retirement Properties"]
                    },
                    2985: {
                    	name: "Retail (Brick & Mortar)",
                    	options: ["Apparel & Accessories","Auto","Catalog, Mail Order & TV Sales","Computer & Software","Convenience Stores","Department Stores","Electronics & Appliances","Gasoline","Grocery","Home Furnishings & Improvement","Jewelry","Music, Video, Books & Entertainment","Office Products","Other Retail","Pharmacies","Services","Sporting Goods","Toys & Games"]
                    },
                    2986: {
                    	name: "Security",
                    	options: ["Business and Consumer Security Products & Services","Other Security Related"]
                    },
                    2987: {
                    	name: "Software (Non Internet/Mobile)",
                    	options: ["Accounting & Finance","Advertising, Sales & Marketing","Application & Data Integration","Asset Management & Trading","BI, Analytics & Performance","Billing, Expense, Procurement","CleanTech, Environmental","Collaboration & Project Management","Compliance","Conferencing & Communication","Construction & Design","Content Management","Customer Relationship Management","Data & Document Management","Database Management","Education & Training","Email, Multimedia & Graphics","Financial Services","Gaming","Government","HR & Workforce Management","Legal","Manufacturing, Supply Chain, Logistics","Networking & Connectivity","Other Software","Product Lifecycle Management","Real Estate","Retail","Scientific, Engineering","Security","Stealth Mode","Storage & Systems Management","Systems & Utility"]
                    },
                    2988: {
                    	name: "Utilities",
                    	options: ["Electric Utilities","Gas Utilities","Multi Utilities","Other Utilities","Water Utilities"]
                    },
                    2991: {
                    	name: "Other",
                        other: true,
                    },
                }
            }
        },

        props: {
            fieldPrefix: {
                type: String,
                default: 'company'
            },

            required: {
                type: Boolean,
                default: false
            },

            needSector: {
                type: Boolean,
                default: false
            },

            needSubSector: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            isOther() {
                return ( this.sector != '' && this.sectors[this.sector].other )
            },

            subSectors() {
                if( this.sector == '' || this.sectors[this.sector].other ) { return [] }
                return this.sectors[this.sector].options
            }
        },

        watch: {
            sector(newValue) {
                this.$emit('set-sector', newValue)
            },

            subSector(newValue) {
                this.$emit('set-subsector', newValue)
            }
        },
    }
</script>

<style lang="scss">


</style>