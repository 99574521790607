<template>
    <div class="modal" :class="modalOpen ? 'is-active' : ''" tabindex="0" v-if="modalOpen">
        <div class="modal-background"></div>
        <div class="modal-content">
            <div class="modal-title title is-4">Is this the {{type}} you were looking for?</div>
            <div class="box">
                <div class="content">
                    
                    <p>
                        We found a similar {{type}} in our records from <em v-text="monthYear"></em> that also involves <strong v-text="company.name"></strong>.
                    </p>

                    <div class="matches">
                        <div class="level mb-1" v-for="match in matches" :key="match.id">
                            <div class="level-left">
                                <div class="level-item">
                                    <strong>
                                        <span v-if="match.type"  v-text="match.type"></span>
                                        <span v-if="match.round" v-text="match.round"></span>
                                    </strong>
                                    <div>
                                        <em v-if="match.date" v-text="formatDate(match.date)"></em>
                                        <a v-if="match.pressRelease" :href="match.pressRelease" target="_blank">Read Press Release</a>
                                    </div>
                                </div>
                            </div>

                            <div class="level-right">
                                <div class="level-item">
                                    <a href="#" @click.prevent="selectMatch(match)" class="button is-success">Yes</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr>

                    <div style="text-align: center;">
                        <a href="#" @click.prevent="closeModal()" class="button is-danger is-light">No, this is a different {{type}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                modalOpen: false,
            }
        },

        props: {
            type: {
                type: String,
                default: 'deal'
            },

            date: {
                type: String,
                default: ''
            },

            company: {
                type: Object,
                default: function() {
                    return {}
                }
            },

            matches: {
                default: function() {
                    return []
                }
            },
        },

        computed: {
            monthYear() {
                if( this.date == '' ) { return '' }
                
                const d = new Date( this.date + 'T00:00:00' );
                const month = d.toLocaleString('default', { month: 'long' });
                const year  = d.getYear() + 1900;

                return month + ' of ' + year
            }
        },

        watch: {
            matches(newValue, oldValue) {
                if( this.matches.length >= 1 ) {
                    this.modalOpen = true
                }
            }
        },

        methods: {
            closeModal() {
                this.$emit('set-match', '')
                this.modalOpen = false
            },

            selectMatch(record) {
                this.$emit('set-match', record)
                this.modalOpen = false
            },

            formatDate( Ymd ) {
                const d = new Date( Ymd + 'T00:00:00')

                const month = d.toLocaleString('default', { month: 'long' })
                const day   = d.getDate()
                const year  = d.getYear() + 1900

                return month + ' ' + this.getOrdinalNum(day) + ' ' + year
            },

            getOrdinalNum(number) {
                let selector;

                if (number <= 0) {
                    selector = 4;
                } else if ((number > 3 && number < 21) || number % 10 > 3) {
                    selector = 0;
                } else {
                    selector = number % 10;
                }

                return number + ['th', 'st', 'nd', 'rd', ''][selector];
            }
        },
    }
</script>

<style lang="scss" scoped>
    .matches {
        margin: 0 -10px;

        .level {
            padding: 5px 10px;

            &:nth-of-type(odd) {
                background: #f5f5f5;
            }
        }

        .level-item {
            flex-direction: column;
            align-items: flex-start;

            span + span,
            em + a {
                &::before { 
                    content: ' / ';
                    font-weight: normal;
                    opacity: 0.5;
                    display: inline-block;
                    margin: 0 5px;
                }                
            }

            em + a {
                &::before { 
                    content: ' - ';
                }
            }

            div {
                font-size: 13px;
            }
        }

    }
</style>