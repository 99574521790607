<template>
    <form method="post" autocomplete="off" @submit="isSaving=true" action="/collect/exit">
        <input type="hidden" :name="csrfName" :value="csrfToken">
        <input type="hidden" name="isAdmin" value="true" v-if="isAdmin">

        <section class="box">
            <div class="section-title title is-4">Exit Details</div>
            <div class="section-content">

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">Company Exited</label>
                    </div>
                    <div class="field-body" v-if="editing && edit.company">
                        <div class="control" style="width:100%">
                            <input type="text" :value="edit.company.name" disabled class="input">
                        </div>
                        <input type="hidden" name="exitee_id"      :value="edit.company.id">
                        <input type="hidden" name="exitee_id_json" :value="JSON.stringify(edit.company)">
                    </div>
                    <div class="field-body" v-else>
                        <find-or-create-company
                            :multiple="false"
                            :required="true"
                            :hasTags="true"
                            :hasDiversity="true"
                            form-field="exitee_id"
                            type-filter="Corporation -"
                            @set-company="onSetCompany"
                            :edit="edit"
                        ></find-or-create-company>
                    </div>
                </div>

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">Exit Type</label>
                    </div>
                    <div class="field-body select">
                        <select name="exit_type" required v-model="exitType" @change="findMatchingExits()">
                            <option value=""> - Please Select - </option>
                            <option value="IPO">IPO</option>
                            <option value="M&A">M&amp;A</option>
                            <option value="Secondary Buyout">Secondary Buyout</option>
                            <option value="Company Buyback">Company Buyback</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
                </div>

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">Exit Date</label>
                    </div>
                    <div class="field-body">
                        <div class="control">
                            <datepicker
                                v-model="exitDate"
                                format="yyyy-MM-dd"
                                @selected="findMatchingExits"
                                class="date-input"
                            ></datepicker>
                            <input type="text" tabindex="-1" required name="exit_date" :value="date" class="hidden">
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">Employee Count</label>
                    </div>
                    <div class="field-body">
                        <div class="control">
                            <input class="input" type="text" name="exit_employeeCount" v-model="employeeCount">
                        </div>
                    </div>
                </div>

                <input type="hidden" name="exit_disclosure" value="Full" v-if="isAdmin">
                <div class="field is-horizontal mb-4" v-if="!isAdmin">
                    <div class="field-label">
                        <label class="label">Disclosure</label>
                    </div>
                    <div class="field-body">
                        <div class="control radios">
                            <label class="radio"><input type="radio" name="exit_disclosure" value="Full"         v-model="disclosure" required>Full</label>
                            <label class="radio"><input type="radio" name="exit_disclosure" value="Partial"      v-model="disclosure" required>Partial</label>
                            <label class="radio"><input type="radio" name="exit_disclosure" value="Confidential" v-model="disclosure" required>Confidential</label>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal">
                    <div class="field-label">
                        <label class="label">Press Release URL</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <div class="control">
                                <input class="input" type="url" name="exit_pressRelease" :disabled="!(disclosure != 'Confidential')" v-model="pressRelease">
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="exitType=='M&A' || exitType=='Secondary Buyout'">
                    <hr>

                    <div class="field is-horizontal mb-4">
                        <div class="field-label">
                            <label class="label">Acquiring Company</label>
                        </div>
                        <div class="field-body">
                            <div class="field is-expanded">
                                <find-or-create-company
                                    :multiple="true"
                                    :required="true"
                                    :hasTags="true"
                                    form-field="acquirer_id"
                                    type-filter-not="Service Provider -"
                                    :edit="edit"
                                ></find-or-create-company>

                                <div class="field">
                                    <label class="checkbox" v-if="disclosure =='Partial'">
                                        <input type="checkbox" value="1" name="participant_confAcquirer" v-model="confAcquirer"> Keep acquiring company confidential
                                    </label>

                                    <label class="checkbox confidential" v-if="disclosure == 'Confidential'">
                                        <input type="checkbox" value="1" :checked="true" disabled> Keep acquiring company confidential
                                        <input type="hidden"   value="1" name="participant_confAcquirer">
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="field is-horizontal">
                        <div class="field-label">
                            <label class="label">Total Value of Acquisition</label>
                        </div>
                        <div class="field-body">
                            <div class="field is-expanded">
                                <div class="field has-addons">
                                    <p class="control">
                                        <span class="select">
                                            <select name="participant_currency" required v-model="currency">
                                                <option value="CAD">CAD $</option>
                                                <option value="USD">USD $</option>
                                                <option value="GBP">GBP £</option>
                                                <option value="Euro">Euro €</option>
                                            </select>
                                        </span>
                                    </p>
                                    <p class="control">
                                        <input class="input" type="text" placeholder="10.3" name="participant_companyValuation" v-model="valuation">
                                    </p>

                                    <p class="control">
                                        <a class="button is-static">million</a>
                                    </p>
                                </div>

                                <div class="field">
                                    <label class="checkbox" v-if="disclosure =='Partial'">
                                        <input type="checkbox" value="1" name="participant_confDealSize" v-model="confDealSize"> Keep transaction amount confidential
                                    </label>

                                    <label class="checkbox confidential" v-if="disclosure == 'Confidential'">
                                        <input type="checkbox" value="1" :checked="true" disabled> Keep transaction amount confidential
                                        <input type="hidden"   value="1" name="participant_confDealSize">
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="exitType=='IPO'">
                    <hr>

                    <div class="field is-horizontal mb-4">
                        <div class="field-label">
                            <label class="label">Stock Exchange</label>
                        </div>
                        <div class="field-body">
                            <div class="field is-expanded">
                                <div class="field">
                                    <input class="input" type="text" required name="participant_stockExchange" v-model="stockExchange">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="field is-horizontal mb-4">
                        <div class="field-label">
                            <label class="label">Stock Symbol</label>
                        </div>
                        <div class="field-body">
                            <div class="field is-expanded">
                                <div class="field">
                                    <input class="input" type="text" required name="participant_stockSymbol" v-model="stockSymbol">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal" v-if="exitType=='IPO' || exitType=='Other'">
                    <div class="field-label">
                        <label class="label">Company Valuation</label>
                    </div>
                    <div class="field-body">
                        <div class="field is-expanded">
                            <div class="field has-addons">
                                <p class="control">
                                    <span class="select">
                                        <select name="participant_currency" required v-model="currency">
                                            <option value="CAD">CAD $</option>
                                            <option value="USD">USD $</option>
                                            <option value="GBP">GBP £</option>
                                            <option value="Euro">Euro €</option>
                                        </select>
                                    </span>
                                </p>
                                <p class="control">
                                    <input class="input" type="text" placeholder="10.3" name="participant_companyValuation" v-model="valuation">
                                </p>

                                <p class="control">
                                    <a class="button is-static">million</a>
                                </p>
                            </div>

                            <div class="field" v-if="exitType=='Other'">
                                <label class="checkbox" v-if="disclosure =='Partial'">
                                    <input type="checkbox" value="1" name="participant_confDealSize" v-model="confDealSize"> Keep transaction amount confidential
                                </label>

                                <label class="checkbox confidential" v-if="disclosure == 'Confidential'">
                                    <input type="checkbox" value="1" :checked="true" disabled> Keep transaction amount confidential
                                    <input type="hidden"   value="1" name="participant_confDealSize">
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- admin -->
                <input type="hidden" name="deal_status" value="reported"  v-if="isAdmin">
                <input type="hidden" name="exit_status" value="confirmed" v-else>
            </div>
        </section>

        <modal-choose-match
            type="exit"
            :company="company"
            :date="date"
            :matches="exits"
            @set-match="onSetExit"
        ></modal-choose-match>

        <input type="hidden" name="exit_id" v-model="exit.id">

        <section class="box" v-if="!isAdmin">
            <div class="section-title title is-4">Your Participation</div>
            <div class="section-content">

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">Choose Fund(s)</label>
                    </div>
                    <div class="field-body">
                        <div class="field is-expanded">
                            <find-or-create-fund
                                :multiple="true"
                                :member="member"
                                :required="true"
                                form-field="participant_fund"
                                :funds="member.fundList"
                                :edit="edit"
                            ></find-or-create-fund>
                        </div>
                    </div>
                </div>

                <hr>

                <div class="field is-horizontal mb-4" v-if="exitType=='IPO'">
                    <div class="field-label">
                        <label class="label">Total Raised</label>
                    </div>
                    <div class="field-body">
                        <div class="field is-expanded">
                            <div class="field has-addons">

                                <p class="control has-icons-left">
                                    <input class="input" type="text" placeholder="10.3" name="participant_totalRaised" v-model="totalRaised">

                                    <span class="icon is-left">
                                        <i class="fas fa-dollar-sign"></i>
                                    </span>
                                </p>

                                <p class="control">
                                    <a class="button is-static">million</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">Proceeds</label>
                    </div>
                    <div class="field-body">
                        <div class="field is-expanded">
                            <div class="field has-addons">
                                <p class="control" v-if="exitType=='Company Buyback' || exitType==''">
                                    <span class="select">
                                        <select name="participant_currency" required v-model="currency">
                                            <option value="CAD">CAD $</option>
                                            <option value="USD">USD $</option>
                                            <option value="GBP">GBP £</option>
                                            <option value="Euro">Euro €</option>
                                        </select>
                                    </span>
                                </p>
                                <p
                                    class="control"
                                    :class="{ 'has-icons-left': exitType != 'Company Buyback' }"
                                >
                                    <input class="input" type="text" placeholder="10.3" name="participant_proceeds" v-model="proceeds">

                                    <span class="icon is-left" v-if="exitType!='Company Buyback'">
                                        <i class="fas fa-dollar-sign"></i>
                                    </span>                                    
                                </p>

                                <p class="control">
                                    <a class="button is-static">million</a>
                                </p>
                            </div>

                            <div class="field">
                                <label class="checkbox" v-if="disclosure =='Partial'">
                                    <input type="checkbox" value="1" name="participant_confProceeds" v-model="confProceeds"> Keep proceeds confidential
                                </label>

                                <label class="checkbox confidential" v-if="disclosure == 'Confidential'">
                                    <input type="checkbox" value="1" :checked="true" disabled> Keep proceeds confidential
                                    <input type="hidden"   value="1" name="participant_confProceeds">
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal">
                    <div class="field-label">
                        <label class="label">Partial Exit?</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <div class="control radios">
                                <label class="radio"><input type="radio" name="participant_partialExit" value="1" v-model="partialExit">Yes</label>
                                <label class="radio"><input type="radio" name="participant_partialExit" value="0" v-model="partialExit">No</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">% of Shares Sold</label>
                    </div>
                    <div class="field-body">
                        <div class="control">
                            <input class="input" type="number" name="participant_sharesSold" max="100" min="0" v-model="sharesSold">
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">Initial Investment Date</label>
                    </div>
                    <div class="field-body">
                        <div class="control">
                            <datepicker
                                v-model="investmentDate"
                                format="yyyy-MM-dd"
                                class="date-input"
                            ></datepicker>
                            <input type="text" tabindex="-1" required name="participant_investmentDate" :value="investmentDateStr" class="hidden">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="box">
            <div class="section-title title is-4">Other Investors Exiting</div>
            <div class="section-content">

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">Co-Exiters</label>
                    </div>
                    <div class="field-body">
                        <div class="field is-expanded">
                            <find-or-create-company
                                :multiple="true"
                                :required="false"
                                :hasTags="false"
                                :hasDetails="false"
                                :hasSectors="false"
                                :hasType="true"
                                :hasMemberFlag="isAdmin"
                                form-field="coinvestor_id"
                                type-filter-not="Service Provider -"
                                :edit="edit"
                            ></find-or-create-company>

                            <div class="field">
                                <label class="checkbox" v-if="disclosure =='Partial'">
                                    <input type="checkbox" value="1" name="participant_confCoInvestors"> Keep co-investors confidential
                                </label>

                                <label class="checkbox confidential" v-if="disclosure == 'Confidential'">
                                    <input type="checkbox" value="1" :checked="true" disabled> Keep co-investors confidential
                                    <input type="hidden"   value="1" name="participant_confCoInvestors">
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="box">
            <div class="section-title title is-4">Service Providers Involved</div>
            <div class="section-content">
                <div class="field is-horizontal mb-4" v-if="exitType=='IPO'">
                    <div class="field-label">
                        <label class="label">Underwriters</label>
                    </div>
                    <div class="field-body">
                        <div class="field is-expanded">
                            <find-or-create-company
                                :multiple="true"
                                :required="false"
                                :hasTags="false"
                                :hasDetails="false"
                                :hasSectors="false"
                                :hasType="true"
                                :hasMemberFlag="isAdmin"
                                form-field="underwriter_id"
                                type-filter="Bank"
                                create-type-options="SP"
                                :edit="edit"
                            ></find-or-create-company>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal mb-4" v-if="exitType!='IPO'">
                    <div class="field-label">
                        <label class="label">Financial Advisors</label>
                    </div>
                    <div class="field-body">
                        <div class="field is-expanded">
                            <div class="field">
                                <find-or-create-company
                                    :multiple="true"
                                    :required="false"
                                    :hasTags="false"
                                    :hasDetails="false"
                                    :hasSectors="false"
                                    :hasType="true"
                                    :hasMemberFlag="isAdmin"
                                    form-field="financialAdvisor_id"
                                    type-filter="Service Provider -"
                                    create-type-options="SP"
                                    :edit="edit"
                                ></find-or-create-company>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">Law Firms</label>
                    </div>
                    <div class="field-body">
                        <div class="field is-expanded">
                            <div class="field">
                                <find-or-create-company
                                    :multiple="true"
                                    :required="false"
                                    :hasTags="false"
                                    :hasDetails="false"
                                    :hasSectors="false"
                                    :hasType="true"
                                    :hasMemberFlag="isAdmin"
                                    form-field="lawFirm_id"
                                    type-filter="Law Firm"
                                    create-type-options="SP"
                                    :edit="edit"
                                ></find-or-create-company>
                            </div>

                            <div class="field">
                                <label class="checkbox" v-if="disclosure =='Partial'">
                                    <input type="checkbox" value="1" name="participant_confAdvisors" v-model="confAdvisors"> Keep ALL advisors names confidential
                                </label>

                                <label class="checkbox confidential" v-if="disclosure == 'Confidential'">
                                    <input type="checkbox" value="1" :checked="true" disabled> Keep ALL advisors names confidential
                                    <input type="hidden"   value="1" name="participant_confAdvisors">
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

        <div class="field is-grouped">
            <div class="control">
                <button class="button is-link">Submit</button>
            </div>
            <div class="control">
                <a :href="isAdmin?'/manage':'/collect'" class="button is-link is-light">Cancel</a>
            </div>
        </div>

        <div class="modal" :class="{ 'is-active': isSaving }">
            <div class="modal-background"></div>
            <div class="modal-content has-background-white p-4 pt-6 has-text-centered" style="border-radius: 2rem;">
                <div class="title is-2">Saving Report</div>
                <p>Give us a second, this will just take a moment.</p>
                <img src="/assets/intelligence/icons/loading.gif" width="100">
            </div>
        </div>
    </form>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';

    const FindOrCreateFund = () => import(
        /* webpackChunkName: "find-or-create-fund" */ './FindOrCreateFund.vue'
    );

    const FindOrCreateCompany = () => import(
        /* webpackChunkName: "find-or-create-company" */ './FindOrCreateCompany.vue'
    );

    const ModalChooseMatch = () => import(
        /* webpackChunkName: "modal-choose-match" */ './ModalChooseMatch.vue'
    );

    export default {
        components: {
            FindOrCreateCompany,
            FindOrCreateFund,
            ModalChooseMatch,
            Datepicker
        },

        data() {
            return {
                isSaving: false,
                editing: false,
                
                exitType: '',
                exits: [],
                company: {id:''},
                exit: {id:''},
                exitDate: null,

                confAcquirer: 0,
                confDealSize: 0,
                confProceeds: 0,
                confAdvisors: 0,
                currency: 'CAD',
                disclosure: 'Full',
                employeeCount: '',
                exitCost: '',
                investmentDate: null,
                partialExit: '',
                pressRelease: '',
                proceeds: '',
                sharesSold: '',
                stockExchange: '',
                stockSymbol: '',
                totalRaised: '',
                valuation: ''
            }
        },

        props: {
            member: {
                type: Object,
                default: function() {
                    return {}
                }
            },
            edit: {
                type: Object,
                default: function() {
                    return { id: 0 }
                }
            },
        },

        mounted() {
            if( this.edit.id > 0 ) {
                this.editing = true

                this.exit           = { id: this.edit.id }
                this.exitType       = this.edit.type

                this.confAcquirer   = this.edit.confAcquirer
                this.confDealSize   = this.edit.confDealSize
                this.confProceeds   = this.edit.confProceeds
                this.confAdvisors   = this.edit.confAdvisors
                this.currency       = this.edit.currency ?? 'CAD'
                this.disclosure     = this.edit.disclosure
                this.employeeCount  = this.edit.employeeCount
                this.exitCost       = this.edit.exitCost
                this.partialExit    = ( this.edit.partialExit == 1 ) ? '1' : '0'
                this.pressRelease   = this.edit.pressRelease
                this.proceeds       = this.edit.proceeds
                this.sharesSold     = this.edit.sharesSold
                this.stockExchange  = this.edit.stockExchange
                this.stockSymbol    = this.edit.stockSymbol
                this.totalRaised    = this.edit.totalRaised
                this.valuation      = this.edit.valuation

                if( this.edit.date != '' ) {
                    let dateString = this.edit.date.match(/^(\d{4})\-(\d{2})\-(\d{2})$/)
                    this.exitDate = new Date( dateString[1], dateString[2]-1, dateString[3] )
                }

                if( this.edit.investmentDate != '' ) {
                    let dateString = this.edit.investmentDate.match(/^(\d{4})\-(\d{2})\-(\d{2})$/)
                    this.investmentDate = new Date( dateString[1], dateString[2]-1, dateString[3] )
                }
            }
        },

        computed: {
            csrfName() {
                return window.Craft.csrfTokenName
            }, 

            csrfToken() {
                return window.Craft.csrfTokenValue
            },

            isAdmin() {
                return !this.member.hasOwnProperty('id')
            },

            date() {
                return this.exitDate ? this.exitDate.toISOString().slice(0, 10) : ''
            },

            investmentDateStr() {
                return this.investmentDate ? this.investmentDate.toISOString().slice(0, 10) : ''
            }
        },

        methods: {
            async findMatchingExits(date) {
                if( date && date instanceof Date ) {
                    this.exitDate = date
                }

                if( this.company.id != '' && this.date != '' && this.exitType != '' ) {
                    let type = this.exitType == 'IPO' ? 'ipo' : 'nonipo'
                    this.exits = await fetch(
                        window.IntelligenceURL + `/json/exits?type=${type}&cid=${encodeURI(this.company.id)}&date=${encodeURI(this.date)}`, {
                        method: 'GET',
                        credentials: 'same-origin',
                        redirect: 'follow',
                        agent: null,
                    }
                    ).then( res => { return res.json() }
                    ).then( exits => { return exits.items })
                    
                } else {
                    this.exit  = {id:''}
                    this.exits = []
                }
            },

            onSetCompany: function(company) {
                this.company = company ? company : {id:''}
                this.findMatchingExits()
            },

            onSetExit: function(exit) {
                this.exit = exit ? exit : {id:''}
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>

