<template>
    <form method="post" autocomplete="off">

        <section class="box">
            <div class="section-title title is-4">Company Search</div>
            <div class="section-content">

                <div class="field multiselect-wrapper">
                    <multiselect
                        v-model="involved"
                        :options="options"
                        :multiple="false"
                        :close-on-select="true"
                        :clear-on-select="false"
                        :preserve-search="false"
                        placeholder="ACME Company Co."
                        label="name"
                        track-by="name"
                        :preselect-first="false"
                        open-direction="bottom"
                        @select="viewCompany"
                        @search-change="asyncFind"
                        @open="multiselectOpen=true"
                        @close="multiselectOpen=false"
                        >
                        
                        <span slot="noResult" v-if="isLoading">Searching ...</span>
                        <span slot="noResult" class="noResult" v-if="!isLoading" >
                            <a
                                href="#"
                                @click.prevent="createCompany = true"
                                ><span>No companies found that matches <em>{{lastSearch}}</em>.</span>
                            </a>
                        </span>
                        <span slot="noOptions" class="noOptions">
                            Start typing to search for companies.
                        </span>
                    </multiselect>
                </div>

                <div v-if="multiselectOpen" style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0,0,0,0.5); z-index: 100;"></div>

                <div class="modal" :class="{ 'is-active': isSwitching }">
                    <div class="modal-background"></div>
                    <div class="modal-content has-background-white p-4 pt-6 has-text-centered" style="border-radius: 2rem;">
                        <div class="title is-2">Loading Company</div>
                        <p>Give us a second, this will just take a moment.</p>
                        <img src="/assets/intelligence/icons/loading.gif" width="100">
                    </div>
                </div>
            </div>
        </section>
    </form>
</template>

<script>
    import Multiselect from 'vue-multiselect'

    const ModalCreateCompany = () => import(
        /* webpackChunkName: "modal-create-company" */ './ModalCreateCompany.vue'
    );

    export default {
        components: {
            Multiselect,
            ModalCreateCompany
        },

        data() {
            return {
                multiselectOpen: false,
                createCompany: false,
                isLoading: false,
                lastSearch: '', 
                involved: [],
                options: [],
                isSwitching: false,
            }
        },

        methods: {
            viewCompany: function(company) {
                if( company && company.hasOwnProperty('id') && company.id >= 1 ) {
                    this.isSwitching = true
                    window.location = '/company/' + company.id
                }
            },

            async asyncFind(query) {
                this.lastSearch = query
                if( query.length >= 3 ) {
                    this.isLoading = true
                    this.options = await fetch(
                        window.IntelligenceURL + `/json/companies?q=${encodeURI(query)}`, {
                        method: 'GET',
                        credentials: 'same-origin',
                        redirect: 'follow',
                        agent: null,
                    }
                    ).then( res => { return res.json() }
                    ).then( companies => { return companies.items })
                    this.isLoading = false
                }
            },
        },
    }
</script>

<style lang="scss">

</style>
