<template>
    <div class="field multiselect-wrapper">
        <multiselect
            v-model="selected"
            :options="fundList"
            :multiple="multiple"
            :close-on-select="true"
            :clear-on-select="multiple"
            :preserve-search="false"
            :disabled="isDisabled"
            :placeholder="placeholder"
            label="name"
            track-by="name"
            :preselect-first="false"
            open-direction="bottom"
            @search-change="onSearchChange"
            @tag="onCreateFund"
            @open="multiselectOpen=true"
            @close="multiselectOpen=false"
            >
            
            <li slot="afterList" v-if="fundList.length || lastSearch.length">
                <span class="multiselect__option afterList">
                    <a href="#" @click.prevent="createFund = true"><span>Looking for a different fund?</span> <strong>Tell us about it ›</strong></a>
                </span>
            </li>
            
            <span slot="noResult">
                No funds found that match "<em>{{lastSearch}}</em>"
            </span>

            <span slot="noOptions">
                <a href="#" @click.prevent="createFund = true"><span>No existing funds found.</span> <strong>Create the first fund ›</strong></a>
            </span>            
        </multiselect>

        <div v-if="multiselectOpen" style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0,0,0,0.5); z-index: 100;"></div>

        <modal-create-fund
            v-if="createFund"
            :name="lastSearch"
            :owner="member.id"
            @created="onFundCreated"
            @closed="createFund=false"
        ></modal-create-fund>

        <input type="text" tabindex="-1" :required="required" :name="formField" v-model="fundIDs" v-if="formField != ''" class="hidden">
        <input type="hidden" :name="`${formField}_json`" :value="JSON.stringify(selected)" v-if="formField != ''">
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'

    const ModalCreateFund = () => import(
        /* webpackChunkName: "modal-create-fund" */ './ModalCreateFund.vue'
    );

    export default {
        components: {
            Multiselect,
            ModalCreateFund
        },

        data() {
            return {
                editing: false,
                multiselectOpen: false,
                createFund: false,
                lastSearch: '',
                fundOptions: {},
                fundList: [],
                selected: []
            }
        },

        props: {
            multiple: {
                type: Boolean,
                default: false
            },

            required: {
                type: Boolean,
                default: false
            },

            isDisabled: {
                type: Boolean,
                default: false
            },

            placeholder: {
                type: String,
                default: "Choose or create fund"
            },

            formField: {
                type: String,
                default: 'fund_id'
            },

            funds: {
                default: function() {
                    return {}
                }
            },

            member: {
                type: Object,
                default: function() {
                    return {}
                }
            },

            edit: {
                type: Object,
                default: function() {
                    return { id: 0 }
                }
            },
        },

        computed: {
            fundIDs() {
                if( this.selected == null ) {
                    return ''
                }

                if( this.multiple && this.selected.constructor === Array ) {
                    let IDs = []
                    for (let index = 0; index < this.selected.length; index++) {
                        IDs.push(this.selected[index].id)
                    }
                    return IDs.join(',')

                } else if ( this.containsKey(this.selected, 'id') ) {
                    return this.selected.id
                }
                return ''
            }
        },

        watch: {
            funds(newValue) {
                this.fundOptions = newValue
                this.setFundList()
            },

            selected(newValue) {
                this.$emit('set-fund', newValue)
            }
        },

        mounted () {
            this.fundOptions = this.funds
            this.setFundList()

            if( this.edit.id > 0 ) {
                this.editing = true

                if( this.formField == 'lpFund' ) {
                    this.selected = this.edit.lpFund
                } else {
                    this.selected = this.edit.fund
                }
            }
        },

        methods: {
            onCreateFund(fundName) {
                this.lastSearch = fundName
                this.createFund = true
            },

            onSearchChange(query) {
                this.lastSearch = query
            },


            onFundCreated: function(fund) {
                if( fund.id && fund.id >= 1 ) {
                    if( this.multiple ) {
                        this.selected.push(fund)
                    } else {
                        this.selected = fund
                    }

                    this.fundOptions[fund.id] = fund.name
                    this.setFundList()
                }
            },

            containsKey(obj, key ) {
                return Object.keys(obj).includes(key);
            },

            setFundList() {
                let object = this.fundOptions
                let list = []

                for (const property in object) {
                    list.push( { id: property, name: object[property] } )
                }

                list.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });

                this.fundList = list;
            },

            clearAll() {
                this.selected = []
            }
        },
    }
</script>

<style lang="scss">

</style>
