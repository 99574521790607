<template>
    <div class="modal" :class="modalOpen ? 'is-active' : ''" tabindex="0" v-if="modalOpen" @keydown.esc="closeModal()">           
        <div class="modal-background" @click="closeModal()"></div>
        <div class="modal-content create-fund">
            <div class="modal-title title is-4">Adding Fund</div>
            <div class="box">
                <div :class="{ 'saving': saving }">
                    <div class="field is-horizontal mb-4">
                        <div class="field-label">
                            <label class="label">Name</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                                <div class="control">
                                    <input class="input" type="text" required v-model="fund.fund_name" ref="input" />
                                </div>
                                <p class="help is-danger" v-if="errors.name">Please enter a fund name</p>
                            </div>
                        </div>
                    </div>

                    <div class="field is-horizontal mb-4">
                        <div class="field-label">
                            <label class="label">Vintage Year</label>
                        </div>
                        <div class="field-body">
                            <div class="field has-addons">
                                <div class="control">
                                    <input class="input" placeholder="2020" type="text" required v-model="fund.fund_vintageYear" />
                                    <p class="help is-danger" v-if="errors.vintage">Please provide a vintage year</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="field is-horizontal mb-4">
                        <div class="field-label">
                            <label class="label">Fund Target</label>
                        </div>
                        <div class="field-body">
                            <div class="field is-expanded">
                                <div class="field has-addons">
                                    <p class="control">
                                        <span class="select">
                                            <select v-model="fund.fund_currency" style="min-width:110px;">
                                                <option value="CAD">CAD $</option>
                                                <option value="USD">USD $</option>
                                                <option value="GBP">GBP £</option>
                                                <option value="Euro">Euro €</option>                                       
                                            </select>
                                        </span>
                                    </p>
                                    <p class="control">
                                        <input class="input" type="text" placeholder="10.3" v-model="fund.fund_target">
                                    </p>

                                    <p class="control">
                                        <a class="button is-static">million</a>
                                    </p>
                                </div>
                                <p class="help is-danger" v-if="errors.target">Please provide a target</p>
                            </div>
                        </div>
                    </div>

                    <div class="field is-horizontal mb-4">
                        <div class="field-label">
                            <label class="label">Fund Type</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                                <div class="control select">
                                    <select v-model="fund.fund_capitalType" style="min-width:110px;" required>
                                        <option value=""> - Please Select - </option>
                                        <optgroup label="Private Equity">
                                            <option value="PE-FO">Family Office</option>
                                            <option value="PE-FOF">Fund of Funds</option>
                                            <option value="PE-INFRA">Infrastructure</option>
                                            <option value="PE-LP">Limited Partner (Direct Investment)</option>
                                            <option value="PE-OT">Other PE</option>
                                            <option value="PE-PI">Private Independent</option>
                                            <option value="PE-PUB">Publicly Traded</option>
                                            <option value="PE-REAL">Real Estate</option>
                                            <option value="PE-TRUST">Trust/Income Fund</option>
                                        </optgroup>
                                        <optgroup label="Venture Capital">
                                            <option value="VC-ACIN">Accelerator/Incubator</option>
                                            <option value="VC-C">Corporate Venture Capital</option>
                                            <option value="VC-FO">Family Office</option>
                                            <option value="VC-FOF">Fund of Funds</option>
                                            <option value="VC-G">Government</option>
                                            <option value="VC-HY">Tax Incented Fund</option>
                                            <option value="VC-IN">Institutional</option>
                                            <option value="VC-MF">Mutual Fund</option>
                                            <option value="VC-OT">Other VC</option>
                                            <option value="VC-PFRI">Publicly Funded Research Institute</option>
                                            <option value="VC-PI">Private Independent</option>
                                            <option value="VC-PUB">Publicly Traded</option>
                                        </optgroup>
                                        <optgroup label="Other">
                                            <option value="AN-HNWI">Angel</option>
                                            <option value="OT-GOV">Government</option>
                                            <option value="OT-INST">Institutional</option>
                                            <option value="OT-PRIVATE">Private</option>
                                            <option value="Public">Public</option>
                                            <option value="RealEstate">Private Real Estate Investment Fund</option>
                                        </optgroup>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>



                    <hr>
                </div>

                <div class="field is-grouped is-justify-content-center">
                    <div class="control"><button class="button is-success" :class="{ 'is-loading' : saving }" @click.prevent="createFund()">Save</button></div>
                    <div class="control"><a href="#" class="button is-link is-light" @click.prevent="closeModal()">Cancel</a></div>
                </div>
            </div>
        </div>
        <button class="modal-close is-large" aria-label="close" @click="closeModal()"></button>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                modalOpen: false,
                saving: false,
                fund: {
                    fund_name: '',
                    fund_vintageYear: '',
                    fund_currency: 'CAD',
                    fund_target: '',
                    fund_capitalType: '',
                },
                errors: {}

            }
        },

        props: {
            name: {
                type: String,
                default: ''
            },

            owner: {
                type: Number,
                default: 0
            }
        },

        mounted () {
            this.modalOpen = true
            this.saving = false
            this.fund.fund_name = this.name
            
            this.fund.fund_vintageYear = new Date().getFullYear()

            this.$nextTick(function () {
                this.$refs['input'].focus()
            })
        },

        methods: {
            closeModal() {
                this.modalOpen = false
                this.$emit('closed')
            },

            async createFund() {

                this.errors = {}
                let valid   = true

                if( this.fund.fund_name.toString().trim() == '' ) {
                    this.errors.name = true
                    valid = false
                }

                if( this.fund.fund_vintageYear.toString().trim() == '' ) {
                    this.errors.vintage = true
                    valid = false
                }

                if( this.fund.fund_target.toString().trim() == '' || this.fund.fund_target <= 0 ) {
                    this.errors.target = true
                    valid = false
                }

                if( valid ) {

                    this.fund[window.Craft.csrfTokenName] = window.Craft.csrfTokenValue
                    this.fund.fund_owner = this.owner

                    this.saving = true
                    const rawResponse = await fetch(
                        window.IntelligenceURL + '/json/new-fund', {
                        method: 'POST',
                        credentials: 'same-origin',
                        redirect: 'follow',
                        agent: null,
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(this.fund)
                    });

                    this.fund = await rawResponse.json()

                    this.$emit('created', this.fund)
                    this.$emit('closed')
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
    .create-fund {
        max-width: 540px !important;
    }

    div.saving {
        opacity: 0.4;
        transition: opacity 0.3s linear;
    }
</style>