<template>
    <div>
        
        <section class="box">
            <div class="section-title title is-4">{{sectionLabel}}</div>
            <div class="section-content">

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">Investment Recipient</label>
                    </div>

                    <div class="field-body" v-if="editing">
                        <div class="control">
                            <input type="text" :value="edit.investee.name" disabled class="input">
                        </div>
                        <input type="hidden" name="investee_id"      :value="edit.investee.id">
                        <input type="hidden" name="investee_id_json" :value="JSON.stringify(edit.investee)">
                    </div>
                    <div class="field-body" v-else>
                        <find-or-create-company
                            :multiple="false"
                            :required="true"
                            :hasTags="true"
                            :hasDiversity="true"
                            form-field="investee_id"
                            type-filter="Corporation -"
                            @set-company="onSetCompany"
                            :needCompany="error.company"
                        ></find-or-create-company>
                    </div>
                </div>

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">Investment Date</label>
                    </div>
                    <div class="field-body">
                        <div class="control">
                            <datepicker
                                v-model="dealDate"
                                format="yyyy-MM-dd"
                                @selected="findMatchingDeals"
                                class="date-input"
                            ></datepicker>
                            <input type="text" tabindex="-1" required name="deal_date" :value="date" class="hidden">
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">Employee Count</label>
                    </div>
                    <div class="field-body">
                        <div class="control">
                            <input class="input" type="text" name="deal_employeeCount" v-model="employeeCount">
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal mb-4" v-if="showLeadInvestor">
                    <div class="field-label">
                        <label class="label">Your Role</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <div class="control radios">
                                <label class="radio"><input type="radio" name="participant_leadInvestor" value="1" v-model="leadInvestor" required>Lead Investor</label>
                                <label class="radio"><input type="radio" name="participant_leadInvestor" value="0" v-model="leadInvestor" required>Co-Investor</label>
                            </div>
                        </div>
                    </div>
                </div>

                <hr>

                <input type="hidden" name="deal_disclosure" value="Full" v-if="!showDisclosure">
                <div class="field is-horizontal mb-4" v-if="showDisclosure">
                    <div class="field-label">
                        <label class="label">Disclosure</label>
                    </div>
                    <div class="field-body">
                        <div class="control radios">
                            <label class="radio"><input type="radio" name="deal_disclosure" value="Full"         v-model="disclosure" required>Full</label>
                            <label class="radio"><input type="radio" name="deal_disclosure" value="Partial"      v-model="disclosure" required>Partial</label>
                            <label class="radio"><input type="radio" name="deal_disclosure" value="Confidential" v-model="disclosure" required>Confidential</label>
                        </div>
                    </div>
                </div>
             
                <div class="field is-horizontal">
                    <div class="field-label">
                        <label class="label">Press Release URL</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <div class="control">
                                <input class="input" type="url" name="deal_pressRelease" :disabled="!(disclosure != 'Confidential')" v-model="pressRelease">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal mb-4" v-if="showTags">
                    <div class="field-label">
                        <label class="label">Deal Tags</label>
                    </div>
                    <div class="field-body select">
                        <field-tags
                            formField="deal_tags"
                        ></field-tags>
                    </div>
                </div>
            </div>
        </section>
    
        <modal-choose-match
            type="deal"
            :company="company"
            :date="date"
            :matches="deals"
            @set-match="onSetDeal"
        ></modal-choose-match>

        <input type="hidden" name="deal_id" v-model="deal.id">
    </div>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';

    const FindOrCreateCompany = () => import(
        /* webpackChunkName: "find-or-create-company" */ './FindOrCreateCompany.vue'
    );

    const FieldTags = () => import(
        /* webpackChunkName: "field-tags" */ './FieldTags.vue'
    );

    const ModalChooseMatch = () => import(
        /* webpackChunkName: "modal-choose-match" */ './ModalChooseMatch.vue'
    );

    export default {
        components: {
            ModalChooseMatch,
            FindOrCreateCompany,
            FieldTags,
            Datepicker
        },

        data() {
            return {
                editing: false,
                dealDate: null,
                deals: [],
                employeeCount: '',
                leadInvestor: 0,
                disclosure: 'Full',
                company: {id:''},
                deal: {id:''},
                error: {},
                pressRelease: '',
                editing: false
            }
        },

        props: {
            sectionLabel: {
                type: String,
                default: 'What company did your firm invest in?'
            },

            showLeadInvestor: {
                type: Boolean,
                default: true
            },

            showDisclosure: {
                type: Boolean,
                default: true
            },

            showTags: {
                type: Boolean,
                default: false
            },

            edit: {
                type: Object,
                default: function() {
                    return { id: 0 }
                }
            },
        },

        mounted() {
            if( this.edit.id > 0 ) {
                this.editing  = true
                this.deal     = { id: this.edit.id, status: this.edit.status }

                let dateString = this.edit.date.match(/^(\d{4})\-(\d{2})\-(\d{2})$/)
                this.dealDate  = new Date( dateString[1], dateString[2]-1, dateString[3] )

                this.pressRelease = this.edit.pressRelease
                this.disclosure = this.edit.disclosure
                this.employeeCount = this.edit.employeeCount

                this.leadInvestor = this.edit.leadInvestor ? 1 : 0
            }
        },

        computed: {
            needDetails() {
                let needDetails = ( this.deal.id == '' || this.leadInvestor == 1 || this.deal.status == 'reported' )
                return needDetails
            },

            date() {
                return this.dealDate ? this.dealDate.toISOString().slice(0, 10) : ''
            },
        },

        watch: {
            deal(newValue) {
                this.$emit('set-deal', newValue)
            },

            disclosure(newValue) {
                this.$emit('set-disclosure', newValue)
            },

            leadInvestor(newValue) {
                this.$emit('need-details', { needDetails: this.needDetails, leadInvestor: newValue } )
            },

            needDetails(newValue) {
                this.$emit('need-details', { needDetails: newValue, leadInvestor: this.leadInvestor } )
            },
        },

        methods: {
            async findMatchingDeals(date) {
                if( !this.editing ) {
                    if( date && date instanceof Date ) {
                        this.dealDate = date
                    }

                    if( this.company.id != '' && this.date != '' ) {
                        this.deals = await fetch(
                            window.IntelligenceURL + `/json/deals?cid=${encodeURI(this.company.id)}&date=${encodeURI(this.date)}`, {
                            method: 'GET',
                            credentials: 'same-origin',
                            redirect: 'follow',
                            agent: null,
                        }
                        ).then( res => { return res.json() }
                        ).then( deals => { return deals.items })

                    } else {
                        this.deal  = {id:''}
                        this.deals = []
                    }
                }
            },

            onSetCompany: function(company) {
                this.company = company ? company : {id:''}
                this.findMatchingDeals()
            },

            onSetDeal: function(deal) {
                this.deal = deal ? deal : {id:''}
            }
        },
    }
</script>

<style lang="scss">


</style>