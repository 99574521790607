    <template>
        <div class="modal" :class="modalOpen ? 'is-active' : ''" tabindex="0" v-if="modalOpen" @keydown.esc="closeModal()">
            <div class="modal-background" @click="closeModal()"></div>
            <div class="modal-content create-company">
                <div class="modal-title title is-4">Adding Company</div>
                <div class="box">
                    <div :class="{ 'saving': saving }">
                        <div class="field is-horizontal mb-4">
                            <div class="field-label">
                                <label class="label">Name</label>
                            </div>
                            <div class="field-body">
                                <div class="field">
                                    <div class="control">
                                        <input class="input" ref="input" type="text" required v-model="company.new_name" />
                                    </div>
                                    <p class="help is-danger" v-if="errors.name">Please enter a company name</p>
                                </div>
                            </div>
                        </div>

                        <div v-if="hasDetails || hasMemberFlag">
                            <div class="field is-horizontal mb-4"> 
                                <div class="field-label">
                                    <label class="label">Head Office</label>
                                </div>
                                <div class="field-body">
                                    <div class="field">
                                        <div class="control select">
                                            <select class="input" name="country" required v-model="company.new_country">
                                                <option value="">Choose Country</option>

                                                <optgroup label="Popular">
                                                    <option value="Canada">Canada</option>
                                                    <option value="England">England</option>
                                                    <option value="US">United States</option>
                                                </optgroup>
                                                
                                                <optgroup label="All Countries">
                                                    <option value="Afghanistan">Afghanistan</option>
                                                    <option value="Åland Islands">Åland Islands</option>
                                                    <option value="Albania">Albania</option>
                                                    <option value="Algeria">Algeria</option>
                                                    <option value="American Samoa">American Samoa</option>
                                                    <option value="Andorra">Andorra</option>
                                                    <option value="Angola">Angola</option>
                                                    <option value="Anguilla">Anguilla</option>
                                                    <option value="Antarctica">Antarctica</option>
                                                    <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                                    <option value="Argentina">Argentina</option>
                                                    <option value="Armenia">Armenia</option>
                                                    <option value="Aruba">Aruba</option>
                                                    <option value="Australia">Australia</option>
                                                    <option value="Austria">Austria</option>
                                                    <option value="Azerbaijan">Azerbaijan</option>
                                                    <option value="Bahamas">Bahamas</option>
                                                    <option value="Bahrain">Bahrain</option>
                                                    <option value="Bangladesh">Bangladesh</option>
                                                    <option value="Barbados">Barbados</option>
                                                    <option value="Belarus">Belarus</option>
                                                    <option value="Belgium">Belgium</option>
                                                    <option value="Belize">Belize</option>
                                                    <option value="Benin">Benin</option>
                                                    <option value="Bermuda">Bermuda</option>
                                                    <option value="Bhutan">Bhutan</option>
                                                    <option value="Bolivia">Bolivia</option>
                                                    <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                                    <option value="Botswana">Botswana</option>
                                                    <option value="Bouvet Island">Bouvet Island</option>
                                                    <option value="Brazil">Brazil</option>
                                                    <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                                    <option value="Brunei Darussalam">Brunei Darussalam</option>
                                                    <option value="Bulgaria">Bulgaria</option>
                                                    <option value="Burkina Faso">Burkina Faso</option>
                                                    <option value="Burundi">Burundi</option>
                                                    <option value="Cambodia">Cambodia</option>
                                                    <option value="Cameroon">Cameroon</option>
                                                    <option value="Canada">Canada</option>
                                                    <option value="Cape Verde">Cape Verde</option>
                                                    <option value="Cayman Islands">Cayman Islands</option>
                                                    <option value="Central African Republic">Central African Republic</option>
                                                    <option value="Chad">Chad</option>
                                                    <option value="Chile">Chile</option>
                                                    <option value="China">China</option>
                                                    <option value="Christmas Island">Christmas Island</option>
                                                    <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                                    <option value="Colombia">Colombia</option>
                                                    <option value="Comoros">Comoros</option>
                                                    <option value="Congo">Congo</option>
                                                    <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                                                    <option value="Cook Islands">Cook Islands</option>
                                                    <option value="Costa Rica">Costa Rica</option>
                                                    <option value="Cote D'ivoire">Cote D'ivoire</option>
                                                    <option value="Croatia">Croatia</option>
                                                    <option value="Cuba">Cuba</option>
                                                    <option value="Cyprus">Cyprus</option>
                                                    <option value="Czech Republic">Czech Republic</option>
                                                    <option value="Denmark">Denmark</option>
                                                    <option value="Djibouti">Djibouti</option>
                                                    <option value="Dominica">Dominica</option>
                                                    <option value="Dominican Republic">Dominican Republic</option>
                                                    <option value="Ecuador">Ecuador</option>
                                                    <option value="Egypt">Egypt</option>
                                                    <option value="El Salvador">El Salvador</option>
                                                    <option value="England">England</option>
                                                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                                                    <option value="Eritrea">Eritrea</option>
                                                    <option value="Estonia">Estonia</option>
                                                    <option value="Ethiopia">Ethiopia</option>
                                                    <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                                                    <option value="Faroe Islands">Faroe Islands</option>
                                                    <option value="Fiji">Fiji</option>
                                                    <option value="Finland">Finland</option>
                                                    <option value="France">France</option>
                                                    <option value="French Guiana">French Guiana</option>
                                                    <option value="French Polynesia">French Polynesia</option>
                                                    <option value="French Southern Territories">French Southern Territories</option>
                                                    <option value="Gabon">Gabon</option>
                                                    <option value="Gambia">Gambia</option>
                                                    <option value="Georgia">Georgia</option>
                                                    <option value="Germany">Germany</option>
                                                    <option value="Ghana">Ghana</option>
                                                    <option value="Gibraltar">Gibraltar</option>
                                                    <option value="Greece">Greece</option>
                                                    <option value="Greenland">Greenland</option>
                                                    <option value="Grenada">Grenada</option>
                                                    <option value="Guadeloupe">Guadeloupe</option>
                                                    <option value="Guam">Guam</option>
                                                    <option value="Guatemala">Guatemala</option>
                                                    <option value="Guernsey">Guernsey</option>
                                                    <option value="Guinea">Guinea</option>
                                                    <option value="Guinea-bissau">Guinea-bissau</option>
                                                    <option value="Guyana">Guyana</option>
                                                    <option value="Haiti">Haiti</option>
                                                    <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                                                    <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                                                    <option value="Honduras">Honduras</option>
                                                    <option value="Hong Kong">Hong Kong</option>
                                                    <option value="Hungary">Hungary</option>
                                                    <option value="Iceland">Iceland</option>
                                                    <option value="India">India</option>
                                                    <option value="Indonesia">Indonesia</option>
                                                    <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                                                    <option value="Iraq">Iraq</option>
                                                    <option value="Ireland">Ireland</option>
                                                    <option value="Isle of Man">Isle of Man</option>
                                                    <option value="Israel">Israel</option>
                                                    <option value="Italy">Italy</option>
                                                    <option value="Jamaica">Jamaica</option>
                                                    <option value="Japan">Japan</option>
                                                    <option value="Jersey">Jersey</option>
                                                    <option value="Jordan">Jordan</option>
                                                    <option value="Kazakhstan">Kazakhstan</option>
                                                    <option value="Kenya">Kenya</option>
                                                    <option value="Kiribati">Kiribati</option>
                                                    <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                                                    <option value="Korea, Republic of">Korea, Republic of</option>
                                                    <option value="Kuwait">Kuwait</option>
                                                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                                                    <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                                                    <option value="Latvia">Latvia</option>
                                                    <option value="Lebanon">Lebanon</option>
                                                    <option value="Lesotho">Lesotho</option>
                                                    <option value="Liberia">Liberia</option>
                                                    <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                                    <option value="Liechtenstein">Liechtenstein</option>
                                                    <option value="Lithuania">Lithuania</option>
                                                    <option value="Luxembourg">Luxembourg</option>
                                                    <option value="Macao">Macao</option>
                                                    <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                                                    <option value="Madagascar">Madagascar</option>
                                                    <option value="Malawi">Malawi</option>
                                                    <option value="Malaysia">Malaysia</option>
                                                    <option value="Maldives">Maldives</option>
                                                    <option value="Mali">Mali</option>
                                                    <option value="Malta">Malta</option>
                                                    <option value="Marshall Islands">Marshall Islands</option>
                                                    <option value="Martinique">Martinique</option>
                                                    <option value="Mauritania">Mauritania</option>
                                                    <option value="Mauritius">Mauritius</option>
                                                    <option value="Mayotte">Mayotte</option>
                                                    <option value="Mexico">Mexico</option>
                                                    <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                                                    <option value="Moldova, Republic of">Moldova, Republic of</option>
                                                    <option value="Monaco">Monaco</option>
                                                    <option value="Mongolia">Mongolia</option>
                                                    <option value="Montenegro">Montenegro</option>
                                                    <option value="Montserrat">Montserrat</option>
                                                    <option value="Morocco">Morocco</option>
                                                    <option value="Mozambique">Mozambique</option>
                                                    <option value="Myanmar">Myanmar</option>
                                                    <option value="Namibia">Namibia</option>
                                                    <option value="Nauru">Nauru</option>
                                                    <option value="Nepal">Nepal</option>
                                                    <option value="Netherlands">Netherlands</option>
                                                    <option value="Netherlands Antilles">Netherlands Antilles</option>
                                                    <option value="New Caledonia">New Caledonia</option>
                                                    <option value="New Zealand">New Zealand</option>
                                                    <option value="Nicaragua">Nicaragua</option>
                                                    <option value="Niger">Niger</option>
                                                    <option value="Nigeria">Nigeria</option>
                                                    <option value="Niue">Niue</option>
                                                    <option value="Norfolk Island">Norfolk Island</option>
                                                    <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                                    <option value="Norway">Norway</option>
                                                    <option value="Oman">Oman</option>
                                                    <option value="Pakistan">Pakistan</option>
                                                    <option value="Palau">Palau</option>
                                                    <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                                                    <option value="Panama">Panama</option>
                                                    <option value="Papua New Guinea">Papua New Guinea</option>
                                                    <option value="Paraguay">Paraguay</option>
                                                    <option value="Peru">Peru</option>
                                                    <option value="Philippines">Philippines</option>
                                                    <option value="Pitcairn">Pitcairn</option>
                                                    <option value="Poland">Poland</option>
                                                    <option value="Portugal">Portugal</option>
                                                    <option value="Puerto Rico">Puerto Rico</option>
                                                    <option value="Qatar">Qatar</option>
                                                    <option value="Reunion">Reunion</option>
                                                    <option value="Romania">Romania</option>
                                                    <option value="Russian Federation">Russian Federation</option>
                                                    <option value="Rwanda">Rwanda</option>
                                                    <option value="Saint Helena">Saint Helena</option>
                                                    <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                                    <option value="Saint Lucia">Saint Lucia</option>
                                                    <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                                                    <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                                                    <option value="Samoa">Samoa</option>
                                                    <option value="San Marino">San Marino</option>
                                                    <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                                    <option value="Saudi Arabia">Saudi Arabia</option>
                                                    <option value="Scotland">Scotland</option>
                                                    <option value="Senegal">Senegal</option>
                                                    <option value="Serbia">Serbia</option>
                                                    <option value="Seychelles">Seychelles</option>
                                                    <option value="Sierra Leone">Sierra Leone</option>
                                                    <option value="Singapore">Singapore</option>
                                                    <option value="Slovakia">Slovakia</option>
                                                    <option value="Slovenia">Slovenia</option>
                                                    <option value="Solomon Islands">Solomon Islands</option>
                                                    <option value="Somalia">Somalia</option>
                                                    <option value="South Africa">South Africa</option>
                                                    <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                                                    <option value="Spain">Spain</option>
                                                    <option value="Sri Lanka">Sri Lanka</option>
                                                    <option value="Sudan">Sudan</option>
                                                    <option value="Suriname">Suriname</option>
                                                    <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                                                    <option value="Swaziland">Swaziland</option>
                                                    <option value="Sweden">Sweden</option>
                                                    <option value="Switzerland">Switzerland</option>
                                                    <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                                                    <option value="Taiwan">Taiwan</option>
                                                    <option value="Tajikistan">Tajikistan</option>
                                                    <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                                                    <option value="Thailand">Thailand</option>
                                                    <option value="Timor-leste">Timor-leste</option>
                                                    <option value="Togo">Togo</option>
                                                    <option value="Tokelau">Tokelau</option>
                                                    <option value="Tonga">Tonga</option>
                                                    <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                                    <option value="Tunisia">Tunisia</option>
                                                    <option value="Turkey">Turkey</option>
                                                    <option value="Turkmenistan">Turkmenistan</option>
                                                    <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                                    <option value="Tuvalu">Tuvalu</option>
                                                    <option value="Uganda">Uganda</option>
                                                    <option value="Ukraine">Ukraine</option>
                                                    <option value="United Arab Emirates">United Arab Emirates</option>
                                                    <option value="UK">United Kingdom</option>
                                                    <option value="US">United States</option>
                                                    <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                                    <option value="Uruguay">Uruguay</option>
                                                    <option value="Uzbekistan">Uzbekistan</option>
                                                    <option value="Vanuatu">Vanuatu</option>
                                                    <option value="Venezuela">Venezuela</option>
                                                    <option value="Viet Nam">Viet Nam</option>
                                                    <option value="Virgin Islands, British">Virgin Islands, British</option>
                                                    <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                                                    <option value="Wales">Wales</option>
                                                    <option value="Wallis and Futuna">Wallis and Futuna</option>
                                                    <option value="Western Sahara">Western Sahara</option>
                                                    <option value="Yemen">Yemen</option>
                                                    <option value="Zambia">Zambia</option>
                                                    <option value="Zimbabwe">Zimbabwe</option>
                                                </optgroup>
                                            </select>                                            
                                        </div>
                                    </div>

                                    <div class="field">
                                        <div class="control" v-if="company.new_country != 'US' && company.new_country != 'Canada'">
                                            <input class="input" type="text" placeholder="Province/State" required v-model="company.new_region" :disabled="(company.new_country=='')" />
                                        </div>
                                        
                                        <div class="control select" v-if="company.new_country == 'US'">
                                            <select class="input" v-model="company.new_region">
                                                <option value=""> - State - </option>
                                                <option value="AK">Alaska</option>
                                                <option value="AL">Alabama</option>
                                                <option value="AR">Arkansas</option>
                                                <option value="AZ">Arizona</option>
                                                <option value="CA">California</option>
                                                <option value="CO">Colorado</option>
                                                <option value="CT">Connecticut</option>
                                                <option value="DC">District of Columbia</option>
                                                <option value="DE">Delaware</option>
                                                <option value="FL">Florida</option>
                                                <option value="GA">Georgia</option>
                                                <option value="HI">Hawaii</option>
                                                <option value="IA">Iowa</option>
                                                <option value="ID">Idaho</option>
                                                <option value="IL">Illinois</option>
                                                <option value="IN">Indiana</option>
                                                <option value="KS">Kansas</option>
                                                <option value="KY">Kentucky</option>
                                                <option value="LA">Louisiana</option>
                                                <option value="MA">Massachusetts</option>
                                                <option value="MD">Maryland</option>
                                                <option value="ME">Maine</option>
                                                <option value="MI">Michigan</option>
                                                <option value="MN">Minnesota</option>
                                                <option value="MO">Missouri</option>
                                                <option value="MS">Mississippi</option>
                                                <option value="MT">Montana</option>
                                                <option value="NC">North Carolina</option>
                                                <option value="ND">North Dakota</option>
                                                <option value="NE">Nebraska</option>
                                                <option value="NH">New Hampshire</option>
                                                <option value="NJ">New Jersey</option>
                                                <option value="NM">New Mexico</option>
                                                <option value="NV">Nevada</option>
                                                <option value="NY">New York</option>
                                                <option value="OH">Ohio</option>
                                                <option value="OK">Oklahoma</option>
                                                <option value="OR">Oregon</option>
                                                <option value="PA">Pennsylvania</option>
                                                <option value="PR">Puerto Rico</option>
                                                <option value="RI">Rhode Island</option>
                                                <option value="SC">South Carolina</option>
                                                <option value="SD">South Dakota</option>
                                                <option value="TN">Tennessee</option>
                                                <option value="TX">Texas</option>
                                                <option value="UT">Utah</option>
                                                <option value="VA">Virginia</option>
                                                <option value="VT">Vermont</option>
                                                <option value="WA">Washington</option>
                                                <option value="WI">Wisconsin</option>
                                                <option value="WV">West Virginia</option>
                                                <option value="WY">Wyoming</option>
                                            </select>
                                        </div>
                                        <div class="control select" v-if="company.new_country == 'Canada'">
                                            <select class="input" v-model="company.new_region">
                                                <option value=""> - Province - </option>
                                                <option value="AB">Alberta</option>
                                                <option value="BC">British Columbia</option>
                                                <option value="MB">Manitoba</option>
                                                <option value="NB">New Brunswick</option>
                                                <option value="NF">Newfoundland</option>
                                                <option value="NT">Northwest Territories</option>
                                                <option value="NS">Nova Scotia</option>
                                                <option value="NU">Nunavut</option>
                                                <option value="ON">Ontario</option>
                                                <option value="PE">Prince Edward Island</option>
                                                <option value="QC">Quebec</option>
                                                <option value="SK">Saskatchewan</option>
                                                <option value="YT">Yukon Territory</option>
                                            </select>
                                        </div>
                                        <p class="help is-danger" v-if="errors.region" style="white-space:nowrap;">Please enter a region OR country</p>
                                    </div>

                                    <div class="field">
                                        <div class="control">
                                            <input class="input" ref="companyCity" placeholder="City" type="text" required v-model="company.new_city" />
                                        </div>
                                        <p class="help is-danger" v-if="errors.city">Please enter the city</p>
                                    </div>
                                </div>
                            </div>

                            <div class="field is-horizontal mb-4">
                                <div class="field-label">
                                    <label class="label">Description</label>
                                </div>
                                <div class="field-body">
                                    <div class="field">
                                        <div class="control">
                                            <input class="input" type="text" required v-model="company.new_description" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="field is-horizontal mb-4">
                                <div class="field-label">
                                    <label class="label">Website</label>
                                </div>
                                <div class="field-body">
                                    <div class="field">
                                        <div class="control">
                                            <input class="input" type="text" required v-model="company.new_website" placeholder="https://www.example.com" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <field-sectors
                            v-if="hasSectors"
                            fieldPrefix="new"
                            @set-sector="setSector"
                            @set-subsector="setSubSector"
                            :required="true"
                            :needSector="this.errors.sector"
                            :needSubSector="this.errors.subSector"
                        ></field-sectors>

                        <div class="field is-horizontal mb-4" v-if="hasType">
                            <div class="field-label">
                                <label class="label">Company Type</label>
                            </div>
                            <div class="field-body select">
                                <select v-model="company.new_type" required>
                                    <option value=""> - Please Select - </option>
                                    <optgroup label="Investor / GP" v-if="createTypeOptions == 'ALL'">
                                        <option value="2857">Accelerator/Incubator</option>
                                        <option value="2858">Angel Group</option>
                                        <option value="2859">Angel/Private Investor</option>
                                        <option value="2860">Asset Management/Mutual Fund</option>
                                        <option value="2861">Corporate Investor</option>
                                        <option value="2862">Corporate Venture Capital</option>
                                        <option value="2863">Corporate Private Equity</option>
                                        <option value="2864">Debt Provider</option>
                                        <option value="2865">Family Offices</option>
                                        <option value="2867">Merchant Bank</option>
                                        <option value="2868">Private Equity</option>
                                        <option value="2869">Real Estate Investor</option>
                                        <option value="2870">REIT</option>
                                        <option value="2871">Venture Capital</option>
                                        <option value="2866">(Other / Unknown)</option>
                                    </optgroup>
                                    <optgroup label="Limited Partner / LP" v-if="createTypeOptions == 'ALL'">
                                        <option value="2872">Endowment/Foundation/Trust</option>
                                        <option value="2873">Fund of Funds</option>
                                        <option value="2874">Insurance Company</option>
                                        <option value="2876">Public Pension</option>
                                        <option value="2877">Private Pension</option>
                                        <option value="2878">Sovereign Wealth Fund</option>
                                        <option value="2875">(Other / Unknown)</option>
                                    </optgroup>

                                    <optgroup label="Service Provider" v-if="createTypeOptions == 'SP'">
                                        <option value="2879">Accounting</option>
                                        <option value="2880">Brokerage/Investment Bank</option>
                                        <option value="2864">Debt Provider</option>
                                        <option value="2881">Financial Advisor</option>
                                        <option value="2882">Law Firm/Legal Advisor</option>
                                        <option value="2883">PR/IR/Executive Search</option>
                                        <option value="2884">Proxy Solicitor/Info Agent</option>
                                        <option value="2885">(Other / Unknown)</option>
                                    </optgroup>

                                    <optgroup label="Corporation" v-if="createTypeOptions == 'ALL'">
                                        <option value="1869">Assets, Divisions</option>
                                        <option value="1870">Bank/Financial Institution</option>
                                        <option value="1871">Building, Properties, Real Assets</option>
                                        <option value="2855">Corporation/Partnership</option>
                                        <option value="2856">Infrastructure Project</option>
                                    </optgroup>

                                    <optgroup label="Other">
                                        <option value="2886">Academic/Research Institution</option>
                                        <option value="2887">Government</option>
                                        <option value="2888">Industry Association/NGO</option>
                                        <option value="2889">Military</option>
                                        <option value="2890">(Other / Unknown)</option>
                                    </optgroup>
                                </select>
                            </div>
                        </div>

                        <div class="field is-horizontal mb-4" v-if="hasMemberFlag">
                            <div class="field-label">
                                <label class="label">CVCA Member?</label>
                            </div>
                            <div class="field-body">
                                <div class="field">
                                    <div class="control radios">
                                        <label class="radio"><input type="radio" v-model="company.new_cvcaMember" value="1">Yes</label>
                                        <label class="radio"><input type="radio" v-model="company.new_cvcaMember" value="0">No</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr>

                        <div class="field is-horizontal mb-4" v-if="hasDiversity">
                            <div class="field-label">
                                <label class="label">Diversity</label>
                            </div>
                            <div class="field-body">
                                <div class="field">
                                    <label class="checkbox">
                                        <input type="checkbox" value="1" v-model="company.new_founderWoman"> Does one or more member of the founding team identify as a woman?
                                    </label>

                                    <label class="checkbox">
                                        <input type="checkbox" value="1" v-model="company.new_founderBipoc"> Does one or more of the founding team identify as <a class="tooltip" href="#">BIPOC <span class="classic">BIPOC refers to black, indigenous, and other people of color</span></a>?
                                    </label>

                                    <label class="checkbox">
                                        <input type="checkbox" value="1" v-model="company.new_mgmtWoman"> Does one or more member of the <a class="tooltip" href="#">management team <span class="classic">Management team is limited to individuals in the C-Suite</span></a> identify as a woman?
                                    </label>

                                    <label class="checkbox">
                                        <input type="checkbox" value="1" v-model="company.new_mgmtBipoc"> Does one or more of the <a class="tooltip" href="#">management team <span class="classic">Management team is limited to individuals in the C-Suite</span></a> identify as <a class="tooltip" href="#">BIPOC <span class="classic">BIPOC refers to black, indigenous, and other people of color</span></a>?
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal mb-4" v-if="hasTags">
                            <div class="field-label">
                                <label class="label">Tags</label>
                            </div>
                            <div class="field-body select">
                                <field-tags
                                    formField="new_tags"
                                    @set-tags="setTags"
                                ></field-tags>
                            </div>
                        </div>

                        <hr>
                    </div>

                    <div class="field is-grouped is-justify-content-center">
                        <div class="control">
                            <button class="button is-success" :class="{ 'is-loading' : saving }" @click.prevent="createCompany()">Save</button>
                        </div>
                        <div class="control"><a href="#" class="button is-link is-light" @click.prevent="closeModal()">Cancel</a></div>
                    </div>
                </div>
            </div>
            <button class="modal-close is-large" aria-label="close" @click="closeModal()"></button>
        </div>
    </template>

    <script>
        const FieldSectors = () => import(
            /* webpackChunkName: "field-sectors" */ './FieldSectors.vue'
        );

        const FieldTags = () => import(
            /* webpackChunkName: "field-tags" */ './FieldTags.vue'
        );

        export default {
            components: {
                FieldSectors,
                FieldTags
            },

            data() {
                return {
                    modalOpen: false,
                    saving: false,
                    company: {
                        new_name: '',
                        new_city: '',
                        new_region: '',
                        new_country: '',
                        new_description: '',
                        new_website: '',
                        new_sector: '',
                        new_subSector: '',
                        new_type:  '',
                        new_tags: '',
                        new_cvcaMember: 0,
                        new_founderWoman: 0,
                        new_founderBipoc: 0,
                        new_mgmtWoman: 0,
                        new_mgmtBipoc: 0,
                    },
                    errors: {}
                }
            },

            props: {
                name: {
                    type: String,
                    default: ''
                },

                hasDetails: {
                    type: Boolean,
                    default: true
                },

                hasSectors: {
                    type: Boolean,
                    default: true
                },

                hasType: {
                    type: Boolean,
                    default: false
                },

                hasTags: {
                    type: Boolean,
                    default: false
                },

                hasDiversity: {
                    type: Boolean,
                    default: false
                },

                hasMemberFlag: {
                    type: Boolean,
                    default: false
                },

                createTypeOptions: {
                    type: String,
                    default: 'ALL'
                },
            },

            mounted () {
                this.modalOpen = true
                this.saving = false
                this.company.new_name = this.name
                
                this.$nextTick(function () {
                    this.$refs['input'].focus()
                })
            },

            methods: {
                closeModal() {
                    this.modalOpen = false
                    this.saving = false
                    this.$emit('closed')
                },

                async createCompany() {

                    this.errors = {}
                    let valid   = true

                    if( this.company.new_name.trim() == '' ) {
                        this.errors.name = true
                        valid = false
                    }

                    if( this.hasSectors && this.company.new_sector.trim() == '' ) {
                        this.errors.sector = true
                        valid = false
                    }

                    if( this.hasSectors && this.company.new_subSector.trim() == '' ) {
                        this.errors.subSector = true
                        valid = false
                    }

                    if( this.hasDetails && this.company.new_city.trim() == '' ) {
                        this.errors.city = true
                        valid = false
                    }

                    if( this.hasDetails && this.company.new_region.trim() == '' && this.company.new_country.trim() == '' ) {
                        this.errors.region = true
                        valid = false
                    }

                    if( valid ) {
                        this.company[window.Craft.csrfTokenName] = window.Craft.csrfTokenValue

                        this.saving = true
                        const rawResponse = await fetch(
                            window.IntelligenceURL + '/json/new-company', {
                            method: 'POST',
                            credentials: 'same-origin',
                            redirect: 'follow',
                            agent: null,
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(this.company)
                        });

                        this.company = await rawResponse.json()

                        this.$emit('created', this.company)
                        this.$emit('closed')
                    }
                },

                setSector(sector) {
                    this.company.new_sector = sector
                    this.errors.sector = false
                },

                setSubSector(subSector) {
                    this.company.new_subSector = subSector
                    this.errors.subSector = false
                },

                setTags(tags) {
                    this.company.new_tags = tags
                }
            },
        }
    </script>

    <style lang="scss" scoped>
        .create-company {
            max-width: 840px !important;
            width: 100%;
        }

        div.saving {
            opacity: 0.4;
            transition: opacity 0.3s linear;
        }
    </style>