<template>
    <div>
        <form method="post" autocomplete="off" @submit.prevent="asyncFind">
            <div class="columns mb-5">
                <div class="column is-half-desktop">
                    <section class="box" style="height: calc(100% - 3rem);">
                        <div class="section-title title is-4">Investor Details</div>
                        <div class="section-content" style="height: calc(100% - 3rem);">
                            <div class="field">
                                <div class="field-label has-text-left">
                                    <label class="label">Type</label>
                                </div>
                                <div class="field-body select is-multiple">
                                    <div class="field multiselect-wrapper">
                                        <multiselect
                                            v-model="investorType"
                                            :options="investortypes"
                                            :multiple="true"
                                            placeholder="Begin typing an investor type"
                                            :preselect-first="false"
                                            :preserve-search="true"
                                            open-direction="bottom"
                                        ></multiselect>
                                    </div>
                                </div>
                            </div>

                            <div class="field">
                                <div class="field-label has-text-left">
                                    <label class="label">Headquarters</label>
                                </div>
                                <div class="field-body select is-multiple">
                                    <div class="field multiselect-wrapper">
                                        <multiselect
                                            v-model="investorLocation"
                                            :options="investorLocationSuggestions"
                                            :multiple="true"
                                            :options-limit="( investorLocationQuery.length >= 1 ) ? 7 : 0"
                                            placeholder="Begin typing a location (city, province, country)"
                                            :preselect-first="false"
                                            :preserve-search="true"
                                            open-direction="bottom"
                                            @search-change="onInvestorLocationSearchChange"
                                        >
                                        <span slot="noOptions" class="noOptions"></span>
                                    </multiselect>
                                    </div>
                                </div>
                            </div>

                            <div class="field">
                                <div class="field-label has-text-left">
                                    <label class="label">Number of Funds</label>
                                </div>
                                <div class="field-body select is-multiple">
                                    <div class="field multiselect-wrapper">
                                        <multiselect
                                            v-model="investorFunds"
                                            :options="['1', '2-4', '5-9', '10+']"
                                            :multiple="true"
                                            placeholder="Select the number of funds"
                                            :preselect-first="false"
                                            :preserve-search="true"
                                            open-direction="bottom"
                                        ></multiselect>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div class="column is-half-desktop">
                    <section class="box" style="height: calc(100% - 3rem);">
                        <div class="section-title title is-4">Investment Preferences & History</div>
                        <div class="section-content" style="height: calc(100% - 3rem);">
                            <div class="field">
                                <div class="field-label has-text-left">
                                    <label class="label">Sector / Vertical</label>
                                </div>
                                <div class="field-body select is-multiple">
                                    <div class="field multiselect-wrapper">
                                        <multiselect
                                            v-model="dealSector"
                                            :options="sectors"
                                            :multiple="true"
                                            placeholder="Begin typing a sector, sub-sector, or vertical"
                                            :preselect-first="false"
                                            :preserve-search="true"
                                            open-direction="bottom"
                                        ></multiselect>
                                    </div>
                                </div>
                            </div>

                            <div class="field">
                                <div class="field-label has-text-left">
                                    <label class="label">Deal Details</label>
                                </div>
                                <div class="field-body select is-multiple">
                                    <div class="field multiselect-wrapper">
                                        <multiselect
                                            v-model="dealType"
                                            :options="dealtypes"
                                            :multiple="true"
                                            placeholder="Begin typing a round, deal structure, capital type, etc"
                                            :preselect-first="false"
                                            :preserve-search="true"
                                            open-direction="bottom"
                                        ></multiselect>
                                    </div>
                                </div>
                            </div>

                            <div class="field">
                                <div class="field-label has-text-left">
                                    <label class="label">Location</label>
                                </div>
                                <div class="field-body select is-multiple">
                                    <div class="field multiselect-wrapper">
                                        <multiselect
                                            v-model="dealLocation"
                                            :options="dealLocationSuggestions"
                                            :multiple="true"
                                            :options-limit="( dealLocationQuery.length >= 1 ) ? 7 : 0"
                                            placeholder="Begin typing a location (city, province, country)"
                                            :preselect-first="false"
                                            :preserve-search="true"
                                            open-direction="bottom"
                                            @search-change="onDealLocationSearchChange"
                                        >
                                        </multiselect>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

            <div class="field is-grouped">
                <div class="field-body is-justify-content-center">
                    <div class="control">
                        <button class="button is-link">Search</button>
                        &nbsp;
                        <a :href="siteUrl + '/search/investors'" class="button is-link is-light">Reset</a>
                    </div>
                </div>
            </div>
            <br>
        </form>

        <div id="matches">
            <div class="card mb-6" v-if="noResults">
                <p class="has-text-center">No Investors found matching your criteria.</p>
            </div>

            <div class="card mb-6" v-if="investors.length >= 1">
                <header class="card-header p-4" style="background:var(--blue);">
                    <h2 style="color: #fff; margin-bottom: 0; font-weight: bold;">Matching Investors (<span v-text="investors.length"></span> results)</h2>
                </header>
                <div class="card-table" style="margin-top: 1rem;">
                    <div class="content">
                        <div
                            class="columns is-desktop p-4"
                            v-for="(company, index) in investors"
                            :class="{ 'has-background-white-ter' : index % 2 === 0 }"
                            :key="company.id" >

                            <div class="column is-7-desktop">
                                <div class="columns is-desktop">
                                    <div class="column is-three-quarters" style="line-height: 1.2em;">
                                        <a
                                            class="is-size-5 has-text-weight-bold"
                                            :href="`${siteUrl}/company/${company.baserow}`"
                                            target="_blank"
                                            style="line-height: 1.2em"
                                        >{{company.name}}</a>

                                        <div class="is-size-6 mt-2">{{ company.type }}</div>

                                        <div class="is-size-7 mt-1"><strong>{{ [company.city, company.region, company.country].filter(function(e){return e}).join(", ") }}</strong></div>

                                        <p class="mt-2 is-size-6">
                                            {{company.description}}
                                        </p>

                                        <div class="is-size-7 mt-3 field is-flex is-flex-wrap-wrap" style="gap:4px;">

                                            <span class="tag has-background-info-dark has-text-white" v-if="company.numOfFunds > 0">{{company.numOfFunds}} Fund<span v-if="company.numOfFunds > 1">s</span>&nbsp;Raised</span>

                                            <div class="control" v-if="company.aum > 0">
                                                <div class="tags has-addons">
                                                    <span class="tag is-dark">AUM</span>
                                                    <span class="tag is-success has-background-success-dark">${{formatAUM(company.aum)}}M</span>
                                                </div>
                                            </div>

                                            <span class="tag is-warning" v-if="company.cvcaMember">✓ CVCA Member</span>

                                            <span class="tag" v-if="company.totalDeals > 0">{{company.totalDeals}} Deal<span v-if="company.totalDeals > 1">s</span>&nbsp;Recorded</span>
                                        </div>
                                    </div>

                                    <div class="column">
                                        <div
                                            v-if="company.logo"
                                            style="width: 110px; height: 110px; display: flex; align-items:center; justify-content: center;"
                                            ><img :src="company.logo">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="column">
                                <div class="is-size-7">
                                    <div v-if="Object.keys(company.matchingTags).length" class="mb-4">
                                        <strong class="block mb-2">DEALS MATCHING CRITERIA</strong>
                                        <div class="field is-flex is-flex-wrap-wrap" style="gap:6px;">
                                            <div class="control" v-for="tag in Object.keys(company.matchingTags)" :key="tag">
                                                <div class="tags has-addons is-flex-wrap-nowrap" v-if="company.matchingTags[tag] > 1 || !company.majorityConf">
                                                    <span class="tag is-success" style="background:#41b883;">{{tag}}</span>
                                                    <span class="tag is-dark">{{ company.matchingTags[tag] }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <strong class="block mb-2">
                                        <span v-if="Object.keys(company.matchingTags).length">ADDITIONAL</span> INVESTMENT HISTORY
                                    </strong>
                                    <div class="field is-flex is-flex-wrap-wrap" style="gap:6px;">
                                        <div class="control" v-for="tag in sliceTop(company.topSectors, 4, dealSector, company.matchingTags)" :key="`sector-${tag.name}`">
                                            <div class="tags has-addons is-flex-wrap-nowrap" v-if="tag.count > 1 || !company.majorityConf">
                                                <span class="tag has-background-grey-lighter">{{ tag.name }}</span>
                                                <span class="tag" style="background-color: #c1c1c1;">{{ tag.count }}</span>
                                            </div>
                                        </div>

                                        <div class="control" v-for="tag in sliceTop(company.mappedRounds, 4, dealType, company.matchingTags)" :key="`round-${tag.name}`">
                                            <div class="tags has-addons is-flex-wrap-nowrap" v-if="tag.count > 1 || !company.majorityConf">
                                                <span class="tag has-background-grey-lighter">{{ tag.name }}</span>
                                                <span class="tag" style="background-color: #c1c1c1;">{{ tag.count }}</span>
                                            </div>
                                        </div>

                                        <div class="control" v-for="tag in sliceTop(company.mappedTypes, 4, dealType, company.matchingTags)" :key="`type-${tag.name}`">
                                            <div class="tags has-addons is-flex-wrap-nowrap" v-if="tag.count > 1 || !company.majorityConf">
                                                <span class="tag has-background-grey-lighter">{{ tag.name }}</span>
                                                <span class="tag" style="background-color: #c1c1c1;">{{ tag.count }}</span>
                                            </div>
                                        </div>

                                        <div class="control" v-for="tag in sliceTop(company.topLocations, 4, dealLocation, company.matchingTags)" :key="tag.name">
                                            <div class="tags has-addons is-flex-wrap-nowrap" v-if="( tag.count > 1 || !company.majorityConf ) && ( tag.name != company.oneCountry ) && ( tag.name != company.oneContinent )">
                                                <span class="tag has-background-grey-lighter">{{ tag.name }}</span>
                                                <span class="tag" style="background-color: #c1c1c1;">{{ tag.count }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="p-4">
                            <a :href="exportLink" download class="button is-link">Export</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" :class="{ 'is-active': isLoading }">
            <div class="modal-background"></div>
            <div class="modal-content has-background-white p-4 pt-6 has-text-centered" style="border-radius: 2rem;">
                <div class="title is-2">Searching Investors</div>
                <p>Give us a second, this will just take a moment.</p>
                <img :src="assetUrl + '/assets/intelligence/icons/loading.gif'" width="100">
            </div>
        </div>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'

    export default {
        components: {
            Multiselect
        },

         data() {
            return {
                siteUrl: '',
                assetUrl: '',
                investorLocation: [],
                investorType: [],
                investorFunds: [],
                dealLocation: [],
                dealSector: [],
                dealType: [],
                lastSearch: '',
                dealLocationQuery: '',
                investorLocationQuery: '',
                isLoading: false,
                noResults: false,
                investors: []
            }
        },

        props: {
            locations: {
                type: Array,
                default: () => []
            },

            dealtypes: {
                type: Array,
                default: () => []
            },

            sectors: {
                type: Array,
                default: () => []
            },

            investortypes: {
                type: Array,
                default: () => []
            }
        },

        mounted () {
            this.siteUrl  = window.siteUrl
            this.assetUrl = window.assetUrl
            this.IntelligenceURL = window.IntelligenceURL
        },

        computed: {
            exportLink() {
                return window.IntelligenceURL + `/json/investor-search?export=true&` + this.lastSearch
            },

            dealLocationSuggestions() {
                return ( this.dealLocationQuery.length <= 3 )
                    ? this.locations.filter(word => word.toUpperCase().startsWith( this.dealLocationQuery.toUpperCase() ) )
                    : this.locations ?? []
            },

            investorLocationSuggestions() {
                return ( this.investorLocationQuery.length <= 3 )
                    ? this.locations.filter(word => word.toUpperCase().startsWith( this.investorLocationQuery.toUpperCase() ) )
                    : this.locations ?? []
            }

        },

        methods: {
            formatUrlSearchAttr( input ) {
                let searchAttr = encodeURIComponent(JSON.stringify(input))
                return searchAttr
                //return searchAttr.replace("&", "&amp;")
            },

            async asyncFind() {
                this.isLoading = true
                this.noResults = false

                this.lastSearch =
                      `investorLocation=${this.formatUrlSearchAttr(this.investorLocation)}`
                    + `&investorType=${this.formatUrlSearchAttr(this.investorType)}`
                    + `&investorFunds=${this.formatUrlSearchAttr(this.investorFunds)}`
                    + `&dealSector=${this.formatUrlSearchAttr(this.dealSector)}`
                    + `&dealLocation=${this.formatUrlSearchAttr(this.dealLocation)}`
                    + `&dealType=${this.formatUrlSearchAttr(this.dealType)}`

                this.investors = await fetch( window.IntelligenceURL + '/json/investor-search?' + this.lastSearch, {
                    method: 'GET',
                    credentials: 'same-origin',
                    redirect: 'follow',
                    agent: null,
                }).then( res => { return res.json() }
                 ).then( records => { return records.results.items })

                // stop loading and scroll to results
                setTimeout(() => {
                    this.isLoading = false
                    this.noResults = !( this.investors.length >= 1 )
                    this.scrollToResults()
                }, 300 );
            },

            seenTag( matchedTags, tag ) {
                return ( tag in matchedTags )
            },

            formatAUM( number ) {
                number = parseFloat(number).toFixed(0);
                return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },

            // company.topSectors, 3, dealSector, company.matchingTags
            sliceTop( allPrefs, limit = 5, searchField, matchedTags ) {
		        let max = Math.max( 1, ( limit - searchField.length ) );
                let i = 0
                let tops = []
                for( var key in allPrefs ) {
                    if( key in matchedTags ) { continue }
                    if( i++ === max ) { break; }
                    tops.push( { name: key, count: allPrefs[key] }  )
                }
                return tops;
            },

            scrollToResults() {
                const id = 'matches';
                const yOffset = -120;
                const element = document.getElementById(id);
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

                window.scrollTo({top: y, behavior: 'smooth'});
            },

            onDealLocationSearchChange(query) {
                this.dealLocationQuery = query
            },

            onInvestorLocationSearchChange(query) {
                this.investorLocationQuery = query
            },

        },
    }
</script>