<template>
    <div class="field multiselect-wrapper">
        <multiselect
            v-model="tags"
            :options="options"
            :multiple="true"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="true"
            placeholder="Tag name"
            label="name"
            track-by="name"
            :preselect-first="false"
            open-direction="bottom"
            @select="options=[]"
            @search-change="asyncFind"
            >
            
            <span slot="noResult" v-if="isLoading">Searching ...</span>
            <span slot="noResult" v-else>
                No tags found that match <em>{{lastSearch}}</em>.
            </span>
            <span slot="noOptions" class="noOptions">
                Start typing to search for tags.
            </span>
        </multiselect>

        <input type="text" tabindex="-1" :name="formField" v-model="tagIDs" v-if="formField != ''" class="hidden">
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'

    export default {
        components: {
            Multiselect
        },

        data() {
            return {
                isLoading: false,
                lastSearch: '', 
                tags: [],
                options: [],
            }
        },

        props: {
            formField: {
                type: String,
                default: ''
            },
        },

        computed: {
            tagIDs() {
                let IDs = []
                for (let index = 0; index < this.tags.length; index++) {
                    IDs.push(this.tags[index].id)
                }
                return IDs.join(',')
            }
        },

        watch: {
            tagIDs(newValue) {
                this.$emit('set-tags', newValue)
            }
        },

        methods: {
            async asyncFind(query) {
                this.lastSearch = query
                if( query.length >= 3 ) {
                    this.isLoading = true
                    this.options = await fetch(
                        window.IntelligenceURL + `/json/tags?q=${encodeURI(query)}`, {
                            method: 'GET',
                            credentials: 'same-origin',
                            redirect: 'follow',
                            agent: null,
                        }
                    ).then( res => { return res.json() }
                    ).then( tags => { return tags.items })
                    this.isLoading = false
                }
            }
        },
    }
</script>

<style lang="scss">

</style>
