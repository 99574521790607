var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "field multiselect-wrapper" },
    [
      _c(
        "multiselect",
        {
          attrs: {
            options: _vm.options,
            multiple: _vm.multiple,
            "close-on-select": true,
            "clear-on-select": _vm.multiple,
            "preserve-search": false,
            placeholder: "ACME Company Co.",
            label: "name",
            "track-by": "name",
            "preselect-first": false,
            "open-direction": "bottom"
          },
          on: {
            select: _vm.onSelect,
            "search-change": _vm.onSearchChange,
            tag: _vm.onCreateCompany,
            open: _vm.onOpen,
            close: function($event) {
              _vm.multiselectOpen = false
            }
          },
          model: {
            value: _vm.involved,
            callback: function($$v) {
              _vm.involved = $$v
            },
            expression: "involved"
          }
        },
        [
          !_vm.isLoading &&
          _vm.lastSearch.length >= 3 &&
          _vm.options.length != 0
            ? _c("li", { attrs: { slot: "afterList" }, slot: "afterList" }, [
                _c("span", { staticClass: "multiselect__option afterList" }, [
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.createCompany = true
                        }
                      }
                    },
                    [
                      _c("span", [
                        _vm._v("Looking for a different "),
                        _c("em", [_vm._v(_vm._s(_vm.lastSearch))]),
                        _vm._v("?")
                      ]),
                      _vm._v(" "),
                      _c("strong", [_vm._v("Tell us about them ›")])
                    ]
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isLoading || _vm.options.length != 0
            ? _c("span", { attrs: { slot: "noResult" }, slot: "noResult" }, [
                _vm._v("Searching ...")
              ])
            : _c(
                "span",
                {
                  staticClass: "noResult",
                  attrs: { slot: "noResult" },
                  slot: "noResult"
                },
                [
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.createCompany = true
                        }
                      }
                    },
                    [
                      _c("span", [
                        _vm._v("No companies found that match "),
                        _c("em", [_vm._v(_vm._s(_vm.lastSearch))]),
                        _vm._v(".")
                      ]),
                      _vm._v(" "),
                      _c("strong", [_vm._v("Tell us about them ›")])
                    ]
                  )
                ]
              ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "noOptions",
              attrs: { slot: "noOptions" },
              slot: "noOptions"
            },
            [
              _vm._v(
                "\n            Start typing to search for companies.\n        "
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _vm.multiselectOpen
        ? _c("div", {
            staticStyle: {
              position: "fixed",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              background: "rgba(0,0,0,0.5)",
              "z-index": "100"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.createCompany
        ? _c("modal-create-company", {
            attrs: {
              name: _vm.lastSearch,
              hasDetails: _vm.hasDetails,
              hasSectors: _vm.hasSectors,
              hasType: _vm.hasType,
              hasDiversity: _vm.hasDiversity,
              hasTags: _vm.hasTags,
              hasMemberFlag: _vm.hasMemberFlag,
              "create-type-options": _vm.createTypeOptions
            },
            on: {
              created: _vm.onCompanyCreated,
              closed: function($event) {
                _vm.createCompany = false
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.formField != "" && _vm.required
        ? _c("input", {
            staticClass: "hidden",
            attrs: {
              type: "text",
              tabindex: "-1",
              required: _vm.required,
              name: _vm.formField
            },
            domProps: { value: _vm.validateInvolved }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.formField != ""
        ? _c("input", {
            attrs: { type: "hidden", name: _vm.formField + "_json" },
            domProps: { value: _vm.validateInvolved }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }