<template>
    <div class="field multiselect-wrapper">
        <multiselect
            v-model="involved"
            :options="options"
            :multiple="multiple"
            :close-on-select="true"
            :clear-on-select="multiple"
            :preserve-search="false"
            placeholder="ACME Company Co."
            label="name"
            track-by="name"
            :preselect-first="false"
            open-direction="bottom"
            @select="impersonateCompany"
            @search-change="updateSearchQuery"
            @open="multiselectOpen=true"
            @close="multiselectOpen=false"
            >
            
            <li slot="afterList" v-if="!isLoading && lastSearch.length >= 3 && options.length != 0">
                <span class="multiselect__option afterList">
                    <a href="#" @click.prevent="createCompany = true"><span>Looking for a different <em>{{lastSearch}}</em>?</span> <strong>Tell us about them ›</strong></a>
                </span>
            </li>

            <span slot="noResult" v-if="isLoading">Searching ...</span>
            <span slot="noResult" class="noResult" v-if="!isLoading" >
                <a
                    href="#"
                    @click.prevent="createCompany = true"
                    ><span>No companies found that match <em>{{lastSearch}}</em>.</span> <strong>Tell us about them ›</strong>
                </a>
            </span>
            <span slot="noOptions" class="noOptions">
                Start typing to search for companies.
            </span>
        </multiselect>

        <div v-if="multiselectOpen" style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0,0,0,0.5); z-index: 100;"></div>

        <modal-create-company
            v-if="createCompany"
            :name="lastSearch"
            :hasDetails="true"
            :hasSectors="false"
            :hasType="true"
            :hasTags="true"
            :hasMemberFlag="true"
            :create-type-options="createTypeOptions"
            @created="impersonateCompany"
            @closed="createCompany=false"
        ></modal-create-company>

        <div class="modal" :class="{ 'is-active': isSwitching }">
            <div class="modal-background"></div>
            <div class="modal-content has-background-white p-4 pt-6 has-text-centered" style="border-radius: 2rem;">
                <div class="title is-2">Loading Member</div>
                <p>Give us a second, this will just take a moment.</p>
                <img src="/assets/intelligence/icons/loading.gif" width="100">
            </div>
        </div>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import { debounce } from "lodash"

    const ModalCreateCompany = () => import(
        /* webpackChunkName: "modal-create-company" */ './ModalCreateCompany.vue'
    );

    export default {
        components: {
            Multiselect,
            ModalCreateCompany
        },

        data() {
            return {
                multiselectOpen: false,
                createCompany: false,
                isLoading: false,
                lastSearch: '', 
                involved: [],
                options: [],
                isSwitching: false,
            }
        },

        props: {
            multiple: {
                type: Boolean,
                default: false
            },

            required: {
                type: Boolean,
                default: false
            },

            hasDetails: {
                type: Boolean,
                default: true
            },

            hasSectors: {
                type: Boolean,
                default: true
            },

            hasType: {
                type: Boolean,
                default: false
            },

            hasTags: {
                type: Boolean,
                default: false
            },

            createTypeOptions: {
                type: String,
                default: 'ALL'
            },

            typeFilter: {
                type: String,
                default: ''
            },

            typeFilterNot: {
                type: String,
                default: ''
            },

            formField: {
                type: String,
                default: ''
            },
        },

        methods: {
            impersonateCompany: function(company) {
                if( company && company.hasOwnProperty('id') && company.id >= 1 ) {
                    this.isSwitching = true
                    window.location = '/manage/impersonate/' + company.id
                }
            },

            updateSearchQuery(query) {
                if( query.length >= 3 ) {
                    this.isLoading  = true
                    this.lastSearch = query
                    this.loadOptions();
                }
            },

            loadOptions: debounce(function() {
                let query = this.lastSearch
                fetch(
                    window.IntelligenceURL + `/json/companies?q=${encodeURI(query)}&typeMatch=${this.typeFilter}&typeNotMatch=${this.typeFilterNot}&inclWebsiteWithName=true`, {
                        method: 'GET',
                        credentials: 'same-origin',
                        redirect: 'follow',
                        agent: null,
                    }
                ).then( res => { return res.json() }
                ).then( companies => { return companies.items }
                ).then( results => {
                    this.options = results
                    this.isLoading = false
                })
            }, 300 ),
        },
    }
</script>

<style lang="scss">

</style>
