<template>
    <form method="post" autocomplete="off" @submit="isSaving=true" action="/collect/investment">
        <input type="hidden" :name="csrfName" :value="csrfToken">
        <input type="hidden" name="isAdmin" value="true" v-if="isAdmin">

        <find-or-create-deal
            @set-deal="onSetDeal"
            @set-disclosure="onSetDisclosure"
            @need-details="onNeedDetails"
            :show-lead-investor="!isAdmin"
            :show-disclosure="!isAdmin"
            :show-tags="isAdmin"
            :edit="edit"
        ></find-or-create-deal>

        <section class="box" v-if="!isAdmin">
            <div class="section-title title is-4">Your Participation</div>
            <div class="section-content">
                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">Choose Fund(s)</label>
                    </div>
                    <div class="field-body">
                        <div class="field is-expanded">
                            <find-or-create-fund
                                :multiple="true"
                                :member="member"
                                form-field="participant_fund"
                                :funds="Array.isArray(member.fundList) ? {} : member.fundList"
                                :edit="edit"
                            ></find-or-create-fund>

                            <div class="field">
                                <label class="checkbox" v-if="disclosure == 'Partial'">
                                    <input type="checkbox" value="1" name="participant_confFundName" v-model="confFundName"> Keep fund name confidential
                                </label>

                                <label class="checkbox confidential" v-if="disclosure == 'Confidential'">
                                    <input type="checkbox" value="1" :checked="true" disabled> Keep fund name confidential
                                    <input type="hidden"   value="1" name="participant_confFundName">
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <hr>

                <div class="field is-horizontal">
                    <div class="field-label">
                        <label class="label">Currency</label>
                    </div>
                    <div class="field-body">
                        <div class="control">
                            <div class="field select">
                                <select name="deal_currency" required v-model="currency">
                                    <option value="CAD">CAD $</option>
                                    <option value="USD">USD $</option>
                                    <option value="GBP">GBP £</option>
                                    <option value="Euro">Euro €</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">Your Equity</label>
                    </div>
                    <div class="field-body">
                        <div class="field has-addons">
                            <div class="control  has-icons-left">
                                <input class="input" type="number" step=".001" pattern="^\d*(\.\d{0,3})?$" name="participant_investedEquity" value="0" min="0" max="99999" required v-model="investedEquity">
                                <span class="icon is-left">
                                    <i class="fas fa-dollar-sign"></i>
                                </span>
                            </div>

                            <p class="control">
                                <a class="button is-static">million</a>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">Your Debt</label>
                    </div>
                    <div class="field-body">
                        <div class="field is-expanded">
                            <div class="field has-addons">
                                <div class="control has-icons-left">
                                    <input class="input" type="number" step=".001" pattern="^\d*(\.\d{0,3})?$" name="participant_investedDebt" value="0" min="0" max="99999" required  v-model="investedDebt">
                                    <span class="icon is-left">
                                        <i class="fas fa-dollar-sign"></i>
                                    </span>
                                </div>

                                <p class="control">
                                    <a class="button is-static">million</a>
                                </p>
                            </div>

                            <div class="field">
                                <label class="checkbox" v-if="disclosure =='Partial'">
                                    <input type="checkbox" value="1" name="participant_confTransaction" v-model="confTransaction"> Keep transaction amount confidential
                                </label>

                                <label class="checkbox confidential" v-if="disclosure == 'Confidential'">
                                    <input type="checkbox" value="1" :checked="true" disabled> Keep transaction amount confidential                                           
                                    <input type="hidden"   value="1" name="participant_confTransaction">
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal">
                    <div class="field-label">
                        <label class="label">Instruments</label>
                    </div>
                    <div class="field-body">
                        <div class="control">
                            <div class="field select">
                                <select name="participant_instruments" required v-model="instruments">
                                    <option value=""> - Please Select - </option>
                                    <option value="Common Shares">Common Shares</option>
                                    <option value="Convertible Debt">Convertible Debt (Including Convertible Notes)</option>
                                    <option value="Preferred Shares">Preferred Shares</option>
                                    <option value="Restricted Stock">Restricted Stock</option>
                                    <option value="SAFE Notes">SAFE Notes</option>
                                    <option value="Venture Debt">Venture Debt</option>
                                    <option value="Warrants">Warrants</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label twoLines">Is this a follow-on investment?</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <div class="control radios">
                                <label class="radio"><input type="radio" name="participant_followOn" value="1" v-model="followOn">Yes</label>
                                <label class="radio"><input type="radio" name="participant_followOn" value="0" v-model="followOn">No</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">Tranched deal?</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <div class="control radios">
                                <label class="radio"><input type="radio" name="participant_tranched" value="1" v-model="tranched">Yes</label>
                                <label class="radio"><input type="radio" name="participant_tranched" value="0" v-model="tranched">No</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="box" v-if="isAdmin || showDealDetails">
            <div class="section-title title is-4">Deal Information</div>
            <div class="section-content">

                <!-- admin -->
                <input type="hidden" name="deal_status" value="reported"         v-if="isAdmin">

                <!-- any investor -->
                <input type="hidden" name="deal_status" value="confirmed"        v-if="!isAdmin && !isLeadInvestor">

                <!-- lead investor -->
                <input type="hidden" name="deal_status" value="lead-confirmed"   v-if="isLeadInvestor">

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">Deal Type</label>
                    </div>
                    <div class="field-body select">
                        <select name="deal_type" required v-model="dealType">
                            <option value=""> - Please Select - </option>
                            <optgroup label="Venture Capital">
                                <option value="1674">Seed</option>
                                <option value="1665">Early Stage</option>
                                <option value="1667">Later Stage</option>
                                <option value="1666">Growth</option>
                                <option value="1671">Pre-IPO</option>
                                <option value="1670">PIPE</option>
                                <option value="1675">Venture Debt</option>
                                <option value="1664">Bridge</option>
                                <option value="1672">Secondary</option>
                                <option value="1663">M&A</option>
                                <option value="1662">RTO</option>                                
                                <option value="1668">Other</option>
                            </optgroup>

                            <optgroup label="Private Equity">
                                <option value="1682">Growth</option>
                                <option value="1681">Follow-On</option>
                                <option value="1679">Buyout</option>
                                <option value="1688">Privatization</option>
                                <option value="1689">Recapitalization</option>
                                <option value="1680">Debt</option>
                                <option value="1676">Add-On Acquisition</option>
                                <option value="1678">M&A</option>
                                <option value="1677">RTO</option>
                                <option value="1686">Platform Creation</option>
                                <option value="1687">Private Placement</option>
                                <option value="1683">Infrastructure</option>
                                <option value="1684">Other</option>
                            </optgroup>
                        </select>
                    </div>
                </div>

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">Round Type</label>
                    </div>
                    <div class="field-body select">
                        <select name="deal_round" v-model="round">
                            <option value=""> - Please Select - </option>
                            <option value="1693">Pre-Seed</option>
                            <option value="1695">Seed</option>                           
                            <option value="1696">Series A</option>
                            <option value="1697">Series B</option>
                            <option value="1698">Series C</option>
                            <option value="1699">Series D</option>
                            <option value="1700">Series E</option>
                            <option value="1701">Series F</option>
                            <option value="1702">Series G</option>
                            <option value="1703">Series H</option>
                            <option value="1704">Series I</option>
                            <option value="1705">Series J</option>
                            <option value="1706">Series K</option>
                            <option value="1707">Series L</option>
                            <option value="1708">Series M</option>
                            <option value="1709">Series N</option>
                            <option value="1692">Debt</option>
                            <option value="1694">Private Equity</option>                            
                            <option value="1710">Unknown</option>
                        </select>
                    </div>
                </div>

                <hr>

                <div v-if="showAcquiring">
                    <div class="field is-horizontal mb-4">
                        <div class="field-label">
                            <label class="label">Acquired Company</label>
                        </div>
                        <div class="field-body">
                            <div class="field is-expanded">

                                <!--
                                it's worth pointing out that while the fields here are named "acquirer" / confAcquirer,
                                when talking about Deals/Investments the field should actually be refer to as "acquired" since
                                it represents a company that's been acquired, not one doing the acquiring (like in Exits) -->

                                <div class="field-body" v-if="editing && edit.acquirer">
                                    <div class="control">
                                        <input type="text" :value="edit.acquirer.name" disabled class="input">
                                    </div>
                                    <input type="hidden" name="deal_acquirer"      :value="edit.acquirer.id">
                                    <input type="hidden" name="deal_acquirer_json" :value="JSON.stringify(edit.acquirer)">
                                </div>

                                <find-or-create-company
                                    v-else
                                    :multiple="false"
                                    :required="true"
                                    :hasTags="true"
                                    form-field="deal_acquirer"
                                    type-filter-not="Service Provider -"
                                    :edit="edit"
                                ></find-or-create-company>

                                <div class="field">
                                    <label class="checkbox" v-if="disclosure =='Partial'">
                                        <input type="checkbox" value="1" name="deal_confAcquirer" v-model="confAcquirer"> Keep acquired company confidential
                                    </label>

                                    <label class="checkbox confidential" v-if="disclosure == 'Confidential'">
                                        <input type="checkbox" value="1" :checked="true" disabled> Keep acquired company confidential
                                        <input type="hidden"   value="1" name="deal_confAcquirer">
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="showStock">
                        <hr>

                        <div class="field is-horizontal mb-4">
                            <div class="field-label">
                                <label class="label">Stock Exchange</label>
                            </div>
                            <div class="field-body">
                                <div class="field is-expanded">
                                    <div class="field">
                                        <input class="input" type="text" required name="participant_stockExchange" v-model="stockExchange">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal mb-4">
                            <div class="field-label">
                                <label class="label">Stock Symbol</label>
                            </div>
                            <div class="field-body">
                                <div class="field is-expanded">
                                    <div class="field">
                                        <input class="input" type="text" required name="participant_stockSymbol" v-model="stockSymbol">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <hr>
                </div>

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">Total Deal Size</label>
                    </div>
                    <div class="field-body">
                        <div class="field is-expanded">
                            <div class="field has-addons">

                                <p class="control" v-if="isAdmin">
                                    <span class="select">
                                        <select name="deal_currency" required v-model="currency">
                                            <option value="CAD">CAD $</option>
                                            <option value="USD">USD $</option>
                                            <option value="GBP">GBP £</option>
                                            <option value="Euro">Euro €</option>
                                        </select>
                                    </span>
                                </p>

                                <div class="control" :class="{ 'has-icons-left' : !isAdmin }">
                                    <input class="input" type="number" step=".001" pattern="^\d*(\.\d{0,3})?$" name="deal_totalSize" min="0" max="99999" v-model="totalSize">
                                    <span class="icon is-left" v-if="!isAdmin">
                                        <i class="fas fa-dollar-sign"></i>
                                    </span>
                                </div>

                                <p class="control">
                                    <a class="button is-static">million</a>
                                </p>
                            </div>

                            <div class="field">
                                <label class="checkbox" v-if="disclosure =='Partial'">
                                    <input type="checkbox" value="1" name="deal_confDealSize" v-model="confDealSize"> Keep deal size confidential
                                </label>

                                <label class="checkbox confidential" v-if="disclosure == 'Confidential'">
                                    <input type="checkbox" value="1" :checked="true" disabled> Keep deal size confidential
                                    <input type="hidden"   value="1" name="deal_confDealSize">
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">Total Equity</label>
                    </div>
                    <div class="field-body">
                        <div class="field has-addons">
                            <div class="control has-icons-left">
                                <input class="input" type="number" step=".001" pattern="^\d*(\.\d{0,3})?$" name="deal_equity" min="0" max="99999" v-model="equity">
                                <span class="icon is-left">
                                    <i class="fas fa-dollar-sign"></i>
                                </span>
                            </div>

                            <p class="control">
                                <a class="button is-static">million</a>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">Total Debt</label>
                    </div>
                    <div class="field-body">
                        <div class="field has-addons">
                            <div class="control has-icons-left">
                                <input class="input" type="number" step=".001" pattern="^\d*(\.\d{0,3})?$" name="deal_debt" min="0" max="99999" v-model="debt">
                                <span class="icon is-left">
                                    <i class="fas fa-dollar-sign"></i>
                                </span>
                            </div>

                            <p class="control">
                                <a class="button is-static">million</a>
                            </p>
                        </div>
                    </div>
                </div>

                <hr>

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label twoLines">Pre-money Company&nbsp;Valuation</label>
                    </div>
                    <div class="field-body">
                        <div class="field has-addons">
                            <div class="control has-icons-left">
                                <input class="input" type="number" step=".001" pattern="^\d*(\.\d{0,3})?$" name="deal_preMoneyValuation" min="0" max="99999" v-model="preMoney">
                                <span class="icon is-left">
                                    <i class="fas fa-dollar-sign"></i>
                                </span>
                            </div>

                            <p class="control">
                                <a class="button is-static">million</a>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label twoLines">How much of this round is a secondary investment?</label>
                    </div>
                    <div class="field-body">
                        <div class="field has-addons">
                            <div class="control has-icons-left">
                                <input class="input" type="number" step=".001" pattern="^\d*(\.\d{0,3})?$" name="deal_secondaryInvestment" v-model="secondaryInvestment" value="0" min="0" max="99999">
                                <span class="icon is-left">
                                    <i class="fas fa-dollar-sign"></i>
                                </span>
                            </div>

                            <p class="control">
                                <a class="button is-static">million</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="box">
            <div class="section-title title is-4">Who Else Was Involved?</div>
            <div class="section-content">

                <div class="field is-horizontal mb-4" v-if="isAdmin">
                    <div class="field-label">
                        <label class="label">Lead Investor(s)</label>
                    </div>
                    <div class="field-body">
                        <find-or-create-company
                            :multiple="true"
                            form-field="deal_leadInvestors"
                            :hasSectors="false"
                            :hasDetails="false"
                            :hasType="true"
                            :hasMemberFlag="isAdmin"
                        ></find-or-create-company>
                    </div>
                </div>

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">Other Co-Investors</label>
                    </div>
                    <div class="field-body">
                        <div class="field is-expanded">
                            <find-or-create-company
                                :multiple="true"
                                :required="false"
                                :hasTags="false"
                                :hasDetails="false"
                                :hasSectors="false"
                                :hasType="true"
                                :hasMemberFlag="isAdmin"
                                form-field="coinvestor_id"
                                type-filter-not="Service Provider -"
                                :edit="edit"
                            ></find-or-create-company>

                            <div class="field">
                                <label class="checkbox" v-if="disclosure =='Partial'">
                                    <input type="checkbox" value="1" name="participant_confCoInvestors" v-model="confCoInvestors"> Keep co-investors confidential
                                </label>

                                <label class="checkbox confidential" v-if="disclosure == 'Confidential'">
                                    <input type="checkbox" value="1" :checked="true" disabled> Keep co-investors confidential
                                    <input type="hidden"   value="1" name="participant_confCoInvestors">
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <hr>

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">Debt Providers</label>
                    </div>
                    <div class="field-body">
                        <div class="field is-expanded">
                            <find-or-create-company
                                :multiple="true"
                                :required="false"
                                :hasTags="false"
                                :hasDetails="false"
                                :hasSectors="false"
                                :hasType="true"
                                :hasMemberFlag="isAdmin"
                                form-field="debtProvider_id"
                                type-filter="Debt"
                                create-type-options="SP"
                                :edit="edit"
                            ></find-or-create-company>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">Financial Advisors</label>
                    </div>
                    <div class="field-body">
                        <div class="field is-expanded">
                            <div class="field">
                                <find-or-create-company
                                    :multiple="true"
                                    :required="false"
                                    :hasTags="false"
                                    :hasDetails="false"
                                    :hasSectors="false"
                                    :hasType="true"
                                    :hasMemberFlag="isAdmin"
                                    form-field="financialAdvisor_id"
                                    type-filter="Service Provider -"
                                    create-type-options="SP"
                                    :edit="edit"
                                ></find-or-create-company>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">Law Firms</label>
                    </div>
                    <div class="field-body">
                        <div class="field is-expanded">
                            <div class="field">
                                <find-or-create-company
                                    :multiple="true"
                                    :required="false"
                                    :hasTags="false"
                                    :hasDetails="false"
                                    :hasSectors="false"
                                    :hasType="true"
                                    :hasMemberFlag="isAdmin"
                                    form-field="lawFirm_id"
                                    type-filter="Law Firm"
                                    create-type-options="SP"
                                    :edit="edit"
                                ></find-or-create-company>
                            </div>

                            <div class="field">
                                <label class="checkbox" v-if="disclosure =='Partial'">
                                    <input type="checkbox" value="1" name="participant_confAdvisors" v-model="confAdvisors"> Keep ALL advisors names confidential
                                </label>

                                <label class="checkbox confidential" v-if="disclosure == 'Confidential'">
                                    <input type="checkbox" value="1" :checked="true" disabled> Keep ALL advisors names confidential
                                    <input type="hidden"   value="1" name="participant_confAdvisors">
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div class="field is-grouped">
            <div class="control">
                <button class="button is-link">Submit</button>
            </div>
            <div class="control">
                <a :href="isAdmin?'/manage':'/collect'" class="button is-link is-light">Cancel</a>
            </div>
        </div>

        <div class="modal" :class="{ 'is-active': isSaving }">
            <div class="modal-background"></div>
            <div class="modal-content has-background-white p-4 pt-6 has-text-centered" style="border-radius: 2rem;">
                <div class="title is-2">Saving Report</div>
                <p>Give us a second, this will just take a moment.</p>
                <img src="/assets/intelligence/icons/loading.gif" width="100">
            </div>
        </div>
    </form>
</template>

<script>
    const FindOrCreateFund = () => import(
        /* webpackChunkName: "find-or-create-fund" */ './FindOrCreateFund.vue'
    );

    const FindOrCreateDeal = () => import(
        /* webpackChunkName: "find-or-create-deal" */ './FindOrCreateDeal.vue'
    );

    const FindOrCreateCompany = () => import(
        /* webpackChunkName: "find-or-create-company" */ './FindOrCreateCompany.vue'
    );

    export default {
        components: {
            FindOrCreateDeal,
            FindOrCreateFund,
            FindOrCreateCompany
        },

        data() {
            return {
                // layout/field control variables
                isSaving: false,
                showDealDetails: true,
                showAcquiring: false,
                showStock: false,
                editing: false,                

                // deal object
                deal: {id:''},
                
                // individual fields
                confAcquirer: 0,
                confAdvisors: 0,
                confCoInvestors: 0,
                confDealSize: 0,
                confFundName: 0,
                confTransaction: 0,
                currency: 'CAD',
                dealType: '',
                debt: '',
                disclosure: 'Full',
                equity: '',
                followOn: 0,
                instruments: '',
                investedDebt: '',
                investedEquity: '',
                isLeadInvestor: false,
                preMoney: '',
                round: '',
                secondaryInvestment: '',
                stockExchange: '',
                stockSymbol: '',
                totalSize: '',
                tranched: 0,
            }
        },

        props: {
            member: {
                type: Object,
                default: function() {
                    return {}
                }
            },
            edit: {
                type: Object,
                default: function() {
                    return { id: 0 }
                }
            },
        },

        mounted() {
            if( this.edit.id > 0 ) {

                this.editing = true
                this.deal    = { id: this.edit.id }

                this.confAcquirer        = this.edit.confAcquirer
                this.confAdvisors        = this.edit.confAdvisors
                this.confCoInvestors     = this.edit.confCoInvestors
                this.confDealSize        = this.edit.confDealSize
                this.confFundName        = this.edit.confFundName
                this.confTransaction     = this.edit.confTransaction
                this.currency            = this.edit.currency ?? 'CAD'
                this.dealType            = this.edit.type  ? this.edit.type.id  : ''
                this.debt                = this.edit.debt
                this.disclosure          = this.edit.disclosure
                this.equity              = this.edit.equity
                this.followOn            = ( this.edit.followOn == 1 ) ? '1' : '0'
                this.instruments         = this.edit.instruments
                this.investedDebt        = this.edit.investedDebt
                this.investedEquity      = this.edit.investedEquity
                this.isLeadInvestor      = this.edit.leadInvestor
                this.preMoney            = this.edit.preMoney
                this.round               = this.edit.round ? this.edit.round.id : ''                
                this.secondaryInvestment = this.edit.secondaryInvestment
                this.stockExchange       = this.edit.stockExchange
                this.stockSymbol         = this.edit.stockSymbol
                this.totalSize           = this.edit.totalSize
                this.tranched            = ( this.edit.tranched == 1 ) ? '1' : '0'
            }
        },

        computed: {
            csrfName() {
                return window.Craft.csrfTokenName
            }, 

            csrfToken() {
                return window.Craft.csrfTokenValue
            },

            isAdmin() {
                return !this.member.hasOwnProperty('id')
            }
        },

        watch: {
            dealType(newValue, oldValue) {
                switch (parseInt(newValue)) {
                    case 1662:
                    case 1663:
                    case 1676: 
                    case 1677: 
                    case 1678: 
                        this.showAcquiring=true
                        break;

                    default: 
                        this.showAcquiring=false
                }

                switch (newValue) {
                    case 1662:
                    case 1677:
                        this.showStock=true
                        break;

                    default: 
                        this.showStock=false
                }                
            }
        },

        methods: {
            onSetDeal: function(deal) {
                this.deal = deal
            },

            onSetDisclosure: function(disclosure) {
                this.disclosure = disclosure
            },

            onNeedDetails: function(args) {
                if( this.editing ) {
                    this.showDealDetails = ( this.edit.status == 'lead-confirmed' && !this.edit.leadInvestor ) ? false : args.needDetails
                    this.isLeadInvestor  = ( args.leadInvestor == 1 )
                } else {
                    this.showDealDetails = args.needDetails
                    this.isLeadInvestor  = ( args.leadInvestor == 1 )
                }

            },
        },
    }
</script>

<style lang="scss" scoped>

</style>

