<template>
    <div class="c-mobileMenu">
        <a href="#" class="toggle" @click.prevent="toggleMenu" :class="mobileMenuOpen ? 'active' : ''">
            <span></span>
            <span></span>
            <span></span>
        </a>

        <nav v-if="mobileMenuOpen">
            <div class="container flex">
                <ul class="primary">
                    <li><a href="/">Home</a></li>
                    <li><a href="/pricing">Pricing</a></li>
                    <li><a href="/faqs">FAQs</a></li>
                    <li><a href="/reports">Reports</a></li>
                    <li><a href="/live-data">Live Data</a></li>
                    <li><a href="/account/login">Login</a></li>
                    <li><a href="/account/create">Sign-up</a></li>
                </ul>
            </div>
        </nav>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                mobileMenuOpen: false,
                openMenu: 0
            }
        },

        props: {
            pages: {
                type: Array,
                default: []
            },
            checkout: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            pageTree() {
                return this.createTree( this.pages );
            }
        },

        methods : {
            createTree(array) {
                let a = array
                let result = []
                let levels = [{ children: result }]

                a.forEach(function (o) {
                    let thisLevel = o.level - 1
                    levels[thisLevel].children = levels[thisLevel].children || [];
                    levels[thisLevel].children.push(levels[thisLevel + 1] = o);
                });

                return result;
            },

            toggleMenu( status = '' ) {
                if( status  == 'off' ) {
                    this.mobileMenuOpen = false;
                } else {
                    this.mobileMenuOpen = !this.mobileMenuOpen;
                }

                if( this.mobileMenuOpen ) {
                    document.querySelector('body').classList.add('has-mobile-menu');
                } else {
                    document.querySelector('body').classList.remove('has-mobile-menu');
                }
            },

            menuOn(menu) {
                this.showMenu[menu] = true;
            },

            menuOff(menu) {
                if( menu == 'all' ) {
                    this.toggleMenu( 'off' )
                } else {
                    this.showMenu[menu]  = false;
                }
            },

            showSub( pageid ) {
                this.openMenu = ( this.openMenu == pageid ) ? 0 : pageid
            }
        }
   }
</script>

<style lang="scss" scoped>

    @keyframes menuIn {
        from {opacity: 0;}
        to {opacity: 1;}
    }

    .c-mobileMenu {
        position: absolute;
        z-index: 1000000;
        min-width: 100vw;
        top: 0;

        nav {
            opacity: 0;
            max-height: 100vh;
            overflow: hidden;
            z-index: 1000;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: all 0.5s ease-in-out;
            background: var(--blue);
            animation-name: menuIn;
            animation-duration: 0.5s;
            animation-fill-mode: forwards;

            .container.flex {
                display: flex;
                justify-content: space-between;
                padding: 1.5rem 0;
                align-items: center;
            }


            .logo {
                
                img {
                    max-height: 40px;
                }

                @include tiny {
                    padding-left: 10px;
                }
            }

            ul.primary {
                margin: 4rem 0 0 0;
                padding: 15px 0px;
                list-style: none;

                &.utility {
                    border-bottom: none;
                    margin-top: 0rem;
                }

                @include mobile {
                    padding: 8px 0 0 10px;
                }

                li {
                    margin: 0;
                    padding: 0;
                    position: relative;
                    max-width: 330px;
                    display: block;

                    @include mobile {
                        max-width: 280px;
                    }

                    @include tiny {
                        max-width: 240px;
                    }

                    a:not(.menu) {
                        font-size: 2rem;
                        text-decoration: none;
                        display: block;
                        color: #fff;
                        margin: 0 0 0rem;
                        font-weight: 900;
                        line-height: 1em;
                        font-family: var(--headline);
                        text-transform: uppercase;
                        padding : 0.75rem;

                        @include mobile {
                            font-size: 2rem;
                        }

                        &:hover, &:focus {
                            color: var(--yellow);
                        }
                    }

                    a.menu {
                        display: inline-block;
                        padding: 10px;
                        font-size: 18px;
                        color: #fff;
                        text-decoration: none;
                        position: relative;
                        font-family: var(--headline);
                        font-weight: 700;
                        text-transform: uppercase;

                        &.member {
                            background: #EE2D46;
                            color: #162A50;
                            padding: 2rem 2.5rem;
                            font-size: 2.5rem;
                            color: #fff;
                        }

                        @include tiny {
                            padding: 8px 10px;
                            font-size: 16px;
                            margin-bottom: 6px;
                        }

                        &:hover, &:active {
                            background: darken( #29ABE2, 10% );
                        }

                        .chevron {
                            position: absolute;
                            right: 20px;
                            top: 10px;
                            font-size: 26px;
                        }
                    }
                }
            }
        }
    }

    .buttonWrapper {
        margin-right: 3rem;
    }

    a.button {
        display: flex;
        background: #EE2D46;
        color: #fff;
        font-family: var(--text);
        text-transform: uppercase;
        height: 34px;
        font-weight: 700;
        padding: 0 2rem;
        text-decoration: none;
        text-align: center;
        border: none;
        cursor: pointer;
        align-items: center;
        font-size: 1.4rem;

        &:hover, &:focus {
            background: #29ABE2;
            color: #fff;
        }
    }

    .toggle {
        z-index   : 1001;
        display   : block;
        height    : 24px;
        width     : 30px;
        position  : absolute;
        right     : 40px;
        top       : 45px;

        @include tiny {
            right: 10px;
        }

        @include legacy-up {
            display: none;
        }

        span {
            height          : 4px;
            width           : 30px;
            position        : absolute;
            left            : 0;
            top             : 0;
            background-color: #ffffff;
            transition      : all 0.3s ease-in-out;

            &:nth-child(1) {
                body.has-mobile-menu & {
                    background-color: transparent;
                }
            }

            &:nth-child(2) {
                top: calc( 50% - 2px );

                body.has-mobile-menu & {
                    top   : 10px;
                    transform: rotate(-45deg);
                }
            }

            &:nth-child(3) {
                bottom: 0;
                top   : auto;

                body.has-mobile-menu & {
                    transform: rotate(45deg);
                    bottom: auto;
                    top   : 10px;
                }
            }
        }
    }
</style>