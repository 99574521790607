<template>
    <form method="post" autocomplete="off" @submit="isSaving=true" action="/collect/fund-commitment">
        <input type="hidden" :name="csrfName" :value="csrfToken">
        <input type="hidden" name="isAdmin" value="true" v-if="isAdmin">

        <section class="box">
            <div class="section-title title is-4">Fund Information</div>
            <div class="section-content">

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">Fund Owner / GP</label>
                    </div>

                    <div class="field-body" v-if="editing && edit.owner">
                        <div class="control" style="width:100%">
                            <input type="text" :value="edit.owner.name" disabled class="input">
                        </div>
                        <input type="hidden" name="fund_owner"      :value="edit.owner.id">
                        <input type="hidden" name="fund_owner_json" :value="JSON.stringify(edit.owner)">
                    </div>

                    <div class="field-body" v-else>
                        <find-or-create-company
                            :multiple="false"
                            :required="true"
                            :hasTags="true"
                            :hasSectors="false"
                            :hasType="true"
                            :hasMemberFlag="isAdmin"
                            form-field="fund_owner"
                            type-filter="Investor"
                            @set-company="onSetCompany"
                            @has-company="onHasCompany"
                            :edit="edit"
                        ></find-or-create-company>
                    </div>
                </div>
                                
                <hr>

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">Fund name</label>
                    </div>

                    <div class="field-body" v-if="editing && edit.fund">
                        <div class="control" style="width:100%">
                            <input type="text" :value="edit.fund.name" disabled class="input">
                        </div>
                        <input type="hidden" name="commit_fund"      :value="edit.fund.id">
                        <input type="hidden" name="commit_fund_json" :value="JSON.stringify(edit.fund)">
                    </div>

                    <div class="field-body" v-else>
                        <find-or-create-fund
                            :multiple="false"
                            :member="company"
                            :required="true"
                            :isDisabled="!canChooseFund"
                            :placeholder="fundPlaceholder"
                            form-field="commit_fund"
                            :funds="funds"
                            :edit="edit"
                        ></find-or-create-fund>
                    </div>
                </div>

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">Raise status</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <div class="control radios">
                                <label class="radio"><input type="radio" name="fund_status" v-model="status" required value="interim">Interim</label>
                                <label class="radio"><input type="radio" name="fund_status" v-model="status" required value="close">Final Close</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal mb-4" v-if="status=='close'">
                    <div class="field-label">
                        <label class="label">Close Date</label>
                    </div>
                    <div class="field-body">
                        <div class="control">
                            <datepicker
                                v-model="closeDate"
                                format="yyyy-MM-dd"
                                class="date-input"
                            ></datepicker>
                            <input type="text" tabindex="-1" required name="fund_close_date" :value="closeDateStr" class="hidden">
                        </div>
                    </div>
                </div>

            </div>
        </section>

        <section class="box">
            <div class="section-title title is-4">Your Participation</div>
            <div class="section-content">

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">Disclosure</label>
                    </div>
                    <div class="field-body">
                        <div class="control radios">
                            <label class="radio"><input type="radio" name="participant_disclosure" value="Full"         v-model="disclosure" required>Full</label>
                            <label class="radio"><input type="radio" name="participant_disclosure" value="Partial"      v-model="disclosure" required>Partial</label>
                            <label class="radio"><input type="radio" name="participant_disclosure" value="Confidential" v-model="disclosure" required>Confidential</label>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">Committed Date</label>
                    </div>
                    <div class="field-body">
                        <div class="control">
                            <datepicker
                                v-model="commitDate"
                                format="yyyy-MM-dd"
                                class="date-input"
                            ></datepicker>
                            <input type="text" tabindex="-1" required name="participant_date" :value="commitDateStr" class="hidden">
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">Committed Amount</label>
                    </div>
                    <div class="field-body">
                        <div class="field is-expanded">
                            <div class="field has-addons">
                                <p class="control">
                                    <span class="select">
                                        <select name="participant_currency" required v-model="currency">
                                            <option value="CAD">CAD $</option>
                                            <option value="USD">USD $</option>
                                            <option value="GBP">GBP £</option>
                                            <option value="Euro">Euro €</option>                                       
                                        </select>
                                    </span>
                                </p>
                                <p class="control">
                                    <input class="input" type="text" required placeholder="10.3" name="participant_amount" v-model="amount" required>
                                </p>

                                <p class="control">
                                    <a class="button is-static">million</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">Your Fund</label>
                    </div>
                    <div class="field-body">
                        <find-or-create-fund
                            :multiple="false"
                            :member="member"
                            form-field="lpFund"
                            :funds="member.fundList"
                            :edit="edit"
                        ></find-or-create-fund>
                    </div>
                </div>
            </div>
        </section>

        <section class="box">
            <div class="section-title title is-4">Other LPs</div>
            <div class="section-content">

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">Co-Investors</label>
                    </div>
                    <div class="field-body">
                        <div class="field is-expanded">
                            <div class="field">
                                <find-or-create-company
                                    :multiple="true"
                                    :required="false"
                                    :hasTags="false"
                                    :hasDetails="false"
                                    :hasSectors="false"
                                    :hasType="true"
                                    :hasMemberFlag="isAdmin"
                                    form-field="coinvestors"
                                    type-filter-not="Service Provider -"
                                    :edit="edit"
                                ></find-or-create-company>
                            </div>

                            <div class="field">
                                <label class="checkbox" v-if="disclosure == 'Partial'">
                                    <input type="checkbox" value="1" name="participant_confCoInvestors" v-model="confCoInvestors"> Keep co-investors confidential
                                </label>

                                <label class="checkbox confidential" v-if="disclosure == 'Confidential'">
                                    <input type="checkbox" value="1" :checked="true" disabled> Keep co-investors confidential
                                    <input type="hidden"   value="1" name="participant_confCoInvestors">
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="box">
            <div class="section-title title is-4">Service Providers Involved in this Fundraising</div>
            <div class="section-content">

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">Financial Advisors</label>
                    </div>
                    <div class="field-body">
                        <div class="field is-expanded">
                            <div class="field">
                                <find-or-create-company
                                    :multiple="true"
                                    :required="false"
                                    :hasTags="false"
                                    :hasDetails="false"
                                    :hasSectors="false"
                                    :hasType="true"
                                    :hasMemberFlag="isAdmin"
                                    form-field="financialAdvisors"
                                    type-filter="Service Provider -"
                                    create-type-options="SP"
                                    :edit="edit"
                                ></find-or-create-company>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">Law Firms</label>
                    </div>
                    <div class="field-body">
                        <div class="field is-expanded">
                            <div class="field">
                                <find-or-create-company
                                    :multiple="true"
                                    :required="false"
                                    :hasTags="false"
                                    :hasDetails="false"
                                    :hasSectors="false"
                                    :hasType="true"
                                    :hasMemberFlag="isAdmin"
                                    form-field="lawFirms"
                                    type-filter="Law Firm"
                                    create-type-options="SP"
                                    :edit="edit"
                                ></find-or-create-company>
                            </div>

                            <div class="field">
                                <label class="checkbox" v-if="disclosure == 'Partial'">
                                    <input type="checkbox" value="1" name="participant_confAdvisors" v-model="confAdvisors"> Keep ALL advisors names confidential
                                </label>

                                <label class="checkbox confidential" v-if="disclosure == 'Confidential'">
                                    <input type="checkbox" value="1" :checked="true" disabled> Keep ALL advisors names confidential
                                    <input type="hidden"   value="1" name="participant_confAdvisors">
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div class="field is-grouped">
            <div class="control">
                <button class="button is-link">Submit</button>
            </div>
            <div class="control">
                <a :href="isAdmin?'/manage':'/collect'" class="button is-link is-light">Cancel</a>
            </div>
        </div>

        <div class="modal" :class="{ 'is-active': isSaving }">
            <div class="modal-background"></div>
            <div class="modal-content has-background-white p-4 pt-6 has-text-centered" style="border-radius: 2rem;">
                <div class="title is-2">Saving Report</div>
                <p>Give us a second, this will just take a moment.</p>
                <img src="/assets/intelligence/icons/loading.gif" width="100">
            </div>
        </div>
    </form>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';

    const FindOrCreateCompany = () => import(
        /* webpackChunkName: "find-or-create-company" */ './FindOrCreateCompany.vue'
    );

    const FindOrCreateFund = () => import(
        /* webpackChunkName: "find-or-create-fund" */ './FindOrCreateFund.vue'
    );

    export default {
        components: {
            FindOrCreateCompany,
            FindOrCreateFund,
            Datepicker
        },

        data() {
            return {
                editing: false,
                isSaving: false,
                canChooseFund: false,
                fundPlaceholder: 'Select a company first',
                company: {},
                funds: {},

                disclosure: 'Full',
                status: '',
                date: '',
                closeDate: null,
                commitDate: null,
                amount: '',
                currency: 'CAD',
                confCoInvestors: 0,
                confAdvisors: 0,
            }
        },

        props: {
            member: {
                type: Object,
                default: function() {
                    return {}
                }
            },

            sectionLabel: {
                type: String,
                default: 'Investee Information'
            },

            fieldPrefix: {
                type: String,
                default: 'company'
            },

            edit: {
                type: Object,
                default: function() {
                    return { id: 0 }
                }
            },
        },

        mounted() {
            if( this.edit.id > 0 ) {
                this.editing = true

                this.company   = this.edit.owner
                this.status    = this.edit.fund.status

                if( this.edit.fund.closeDate != '' ) {
                    let dateString = this.edit.fund.closeDate.match(/^(\d{4})\-(\d{2})\-(\d{2})$/)
                    this.closeDate = new Date( dateString[1], dateString[2]-1, dateString[3] )
                }

                if( this.edit.date != '' ) {
                    let dateString = this.edit.date.match(/^(\d{4})\-(\d{2})\-(\d{2})$/)
                    this.commitDate = new Date( dateString[1], dateString[2]-1, dateString[3] )
                }

                this.amount             = this.edit.amount
                this.currency           = this.edit.currency ?? 'CAD'
                this.confCoInvestors    = this.edit.confCoInvestors
                this.confAdvisors       = this.edit.confAdvisors
                this.disclosure         = this.edit.disclosure

                this.findFunds()
            }
        },
        
        computed: {
            csrfName() {
                return window.Craft.csrfTokenName
            }, 

            csrfToken() {
                return window.Craft.csrfTokenValue
            },

            isAdmin() {
                return !this.member.hasOwnProperty('id')
            },

            closeDateStr() {
                return this.closeDate ? this.closeDate.toISOString().slice(0, 10) : ''
            },

            commitDateStr() {
                return this.commitDate ? this.commitDate.toISOString().slice(0, 10) : ''
            },
        },

        methods: {
            async findFunds() {

                if( this.company && this.company.id ) {
                    this.fundPlaceholder = 'Loading ' + this.company.name + ' funds ...'
                    this.canChooseFund = false

                    this.funds = await fetch(
                        window.IntelligenceURL + `/json/funds?cid=${encodeURI(this.company.id)}`, {
                        method: 'GET',
                        credentials: 'same-origin',
                        redirect: 'follow',
                        agent: null,
                    }
                    ).then( res => { return res.json() }
                    ).then( funds => {
                        this.fundPlaceholder = 'Choose or create fund'
                        this.canChooseFund = true

                        return Array.isArray(funds.items) ? {} : funds.items
                    })

                } else {
                    this.funds = {}
                }
            },

            onSetCompany: function (company) {
                this.company = company
                this.findFunds()
            },

            onHasCompany: function (companyName) {
                this.canChooseFund = ( companyName != '' )               
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>

