var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      attrs: { method: "post", autocomplete: "off", action: "/collect/exit" },
      on: {
        submit: function($event) {
          _vm.isSaving = true
        }
      }
    },
    [
      _c("input", {
        attrs: { type: "hidden", name: _vm.csrfName },
        domProps: { value: _vm.csrfToken }
      }),
      _vm._v(" "),
      _vm.isAdmin
        ? _c("input", {
            attrs: { type: "hidden", name: "isAdmin", value: "true" }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("section", { staticClass: "box" }, [
        _c("div", { staticClass: "section-title title is-4" }, [
          _vm._v("Exit Details")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "section-content" }, [
          _c("div", { staticClass: "field is-horizontal mb-4" }, [
            _vm._m(0),
            _vm._v(" "),
            _vm.editing && _vm.edit.company
              ? _c("div", { staticClass: "field-body" }, [
                  _c(
                    "div",
                    { staticClass: "control", staticStyle: { width: "100%" } },
                    [
                      _c("input", {
                        staticClass: "input",
                        attrs: { type: "text", disabled: "" },
                        domProps: { value: _vm.edit.company.name }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    attrs: { type: "hidden", name: "exitee_id" },
                    domProps: { value: _vm.edit.company.id }
                  }),
                  _vm._v(" "),
                  _c("input", {
                    attrs: { type: "hidden", name: "exitee_id_json" },
                    domProps: { value: JSON.stringify(_vm.edit.company) }
                  })
                ])
              : _c(
                  "div",
                  { staticClass: "field-body" },
                  [
                    _c("find-or-create-company", {
                      attrs: {
                        multiple: false,
                        required: true,
                        hasTags: true,
                        hasDiversity: true,
                        "form-field": "exitee_id",
                        "type-filter": "Corporation -",
                        edit: _vm.edit
                      },
                      on: { "set-company": _vm.onSetCompany }
                    })
                  ],
                  1
                )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "field is-horizontal mb-4" }, [
            _vm._m(1),
            _vm._v(" "),
            _c("div", { staticClass: "field-body select" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.exitType,
                      expression: "exitType"
                    }
                  ],
                  attrs: { name: "exit_type", required: "" },
                  on: {
                    change: [
                      function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.exitType = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      function($event) {
                        return _vm.findMatchingExits()
                      }
                    ]
                  }
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v(" - Please Select - ")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "IPO" } }, [_vm._v("IPO")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "M&A" } }, [_vm._v("M&A")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Secondary Buyout" } }, [
                    _vm._v("Secondary Buyout")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Company Buyback" } }, [
                    _vm._v("Company Buyback")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Other" } }, [_vm._v("Other")])
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "field is-horizontal mb-4" }, [
            _vm._m(2),
            _vm._v(" "),
            _c("div", { staticClass: "field-body" }, [
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("datepicker", {
                    staticClass: "date-input",
                    attrs: { format: "yyyy-MM-dd" },
                    on: { selected: _vm.findMatchingExits },
                    model: {
                      value: _vm.exitDate,
                      callback: function($$v) {
                        _vm.exitDate = $$v
                      },
                      expression: "exitDate"
                    }
                  }),
                  _vm._v(" "),
                  _c("input", {
                    staticClass: "hidden",
                    attrs: {
                      type: "text",
                      tabindex: "-1",
                      required: "",
                      name: "exit_date"
                    },
                    domProps: { value: _vm.date }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "field is-horizontal mb-4" }, [
            _vm._m(3),
            _vm._v(" "),
            _c("div", { staticClass: "field-body" }, [
              _c("div", { staticClass: "control" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.employeeCount,
                      expression: "employeeCount"
                    }
                  ],
                  staticClass: "input",
                  attrs: { type: "text", name: "exit_employeeCount" },
                  domProps: { value: _vm.employeeCount },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.employeeCount = $event.target.value
                    }
                  }
                })
              ])
            ])
          ]),
          _vm._v(" "),
          _vm.isAdmin
            ? _c("input", {
                attrs: {
                  type: "hidden",
                  name: "exit_disclosure",
                  value: "Full"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isAdmin
            ? _c("div", { staticClass: "field is-horizontal mb-4" }, [
                _vm._m(4),
                _vm._v(" "),
                _c("div", { staticClass: "field-body" }, [
                  _c("div", { staticClass: "control radios" }, [
                    _c("label", { staticClass: "radio" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.disclosure,
                            expression: "disclosure"
                          }
                        ],
                        attrs: {
                          type: "radio",
                          name: "exit_disclosure",
                          value: "Full",
                          required: ""
                        },
                        domProps: { checked: _vm._q(_vm.disclosure, "Full") },
                        on: {
                          change: function($event) {
                            _vm.disclosure = "Full"
                          }
                        }
                      }),
                      _vm._v("Full")
                    ]),
                    _vm._v(" "),
                    _c("label", { staticClass: "radio" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.disclosure,
                            expression: "disclosure"
                          }
                        ],
                        attrs: {
                          type: "radio",
                          name: "exit_disclosure",
                          value: "Partial",
                          required: ""
                        },
                        domProps: {
                          checked: _vm._q(_vm.disclosure, "Partial")
                        },
                        on: {
                          change: function($event) {
                            _vm.disclosure = "Partial"
                          }
                        }
                      }),
                      _vm._v("Partial")
                    ]),
                    _vm._v(" "),
                    _c("label", { staticClass: "radio" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.disclosure,
                            expression: "disclosure"
                          }
                        ],
                        attrs: {
                          type: "radio",
                          name: "exit_disclosure",
                          value: "Confidential",
                          required: ""
                        },
                        domProps: {
                          checked: _vm._q(_vm.disclosure, "Confidential")
                        },
                        on: {
                          change: function($event) {
                            _vm.disclosure = "Confidential"
                          }
                        }
                      }),
                      _vm._v("Confidential")
                    ])
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "field is-horizontal" }, [
            _vm._m(5),
            _vm._v(" "),
            _c("div", { staticClass: "field-body" }, [
              _c("div", { staticClass: "field" }, [
                _c("div", { staticClass: "control" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.pressRelease,
                        expression: "pressRelease"
                      }
                    ],
                    staticClass: "input",
                    attrs: {
                      type: "url",
                      name: "exit_pressRelease",
                      disabled: !(_vm.disclosure != "Confidential")
                    },
                    domProps: { value: _vm.pressRelease },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.pressRelease = $event.target.value
                      }
                    }
                  })
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _vm.exitType == "M&A" || _vm.exitType == "Secondary Buyout"
            ? _c("div", [
                _c("hr"),
                _vm._v(" "),
                _c("div", { staticClass: "field is-horizontal mb-4" }, [
                  _vm._m(6),
                  _vm._v(" "),
                  _c("div", { staticClass: "field-body" }, [
                    _c(
                      "div",
                      { staticClass: "field is-expanded" },
                      [
                        _c("find-or-create-company", {
                          attrs: {
                            multiple: true,
                            required: true,
                            hasTags: true,
                            "form-field": "acquirer_id",
                            "type-filter-not": "Service Provider -",
                            edit: _vm.edit
                          }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "field" }, [
                          _vm.disclosure == "Partial"
                            ? _c("label", { staticClass: "checkbox" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.confAcquirer,
                                      expression: "confAcquirer"
                                    }
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    value: "1",
                                    name: "participant_confAcquirer"
                                  },
                                  domProps: {
                                    checked: Array.isArray(_vm.confAcquirer)
                                      ? _vm._i(_vm.confAcquirer, "1") > -1
                                      : _vm.confAcquirer
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = _vm.confAcquirer,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = "1",
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.confAcquirer = $$a.concat([
                                              $$v
                                            ]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.confAcquirer = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.confAcquirer = $$c
                                      }
                                    }
                                  }
                                }),
                                _vm._v(
                                  " Keep acquiring company confidential\n                                "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.disclosure == "Confidential"
                            ? _c(
                                "label",
                                { staticClass: "checkbox confidential" },
                                [
                                  _c("input", {
                                    attrs: {
                                      type: "checkbox",
                                      value: "1",
                                      disabled: ""
                                    },
                                    domProps: { checked: true }
                                  }),
                                  _vm._v(
                                    " Keep acquiring company confidential\n                                    "
                                  ),
                                  _c("input", {
                                    attrs: {
                                      type: "hidden",
                                      value: "1",
                                      name: "participant_confAcquirer"
                                    }
                                  })
                                ]
                              )
                            : _vm._e()
                        ])
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "field is-horizontal" }, [
                  _vm._m(7),
                  _vm._v(" "),
                  _c("div", { staticClass: "field-body" }, [
                    _c("div", { staticClass: "field is-expanded" }, [
                      _c("div", { staticClass: "field has-addons" }, [
                        _c("p", { staticClass: "control" }, [
                          _c("span", { staticClass: "select" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.currency,
                                    expression: "currency"
                                  }
                                ],
                                attrs: {
                                  name: "participant_currency",
                                  required: ""
                                },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.currency = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  }
                                }
                              },
                              [
                                _c("option", { attrs: { value: "CAD" } }, [
                                  _vm._v("CAD $")
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "USD" } }, [
                                  _vm._v("USD $")
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "GBP" } }, [
                                  _vm._v("GBP £")
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "Euro" } }, [
                                  _vm._v("Euro €")
                                ])
                              ]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "control" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.valuation,
                                expression: "valuation"
                              }
                            ],
                            staticClass: "input",
                            attrs: {
                              type: "text",
                              placeholder: "10.3",
                              name: "participant_companyValuation"
                            },
                            domProps: { value: _vm.valuation },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.valuation = $event.target.value
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _vm._m(8)
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "field" }, [
                        _vm.disclosure == "Partial"
                          ? _c("label", { staticClass: "checkbox" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.confDealSize,
                                    expression: "confDealSize"
                                  }
                                ],
                                attrs: {
                                  type: "checkbox",
                                  value: "1",
                                  name: "participant_confDealSize"
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.confDealSize)
                                    ? _vm._i(_vm.confDealSize, "1") > -1
                                    : _vm.confDealSize
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.confDealSize,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = "1",
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.confDealSize = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.confDealSize = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.confDealSize = $$c
                                    }
                                  }
                                }
                              }),
                              _vm._v(
                                " Keep transaction amount confidential\n                                "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.disclosure == "Confidential"
                          ? _c(
                              "label",
                              { staticClass: "checkbox confidential" },
                              [
                                _c("input", {
                                  attrs: {
                                    type: "checkbox",
                                    value: "1",
                                    disabled: ""
                                  },
                                  domProps: { checked: true }
                                }),
                                _vm._v(
                                  " Keep transaction amount confidential\n                                    "
                                ),
                                _c("input", {
                                  attrs: {
                                    type: "hidden",
                                    value: "1",
                                    name: "participant_confDealSize"
                                  }
                                })
                              ]
                            )
                          : _vm._e()
                      ])
                    ])
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.exitType == "IPO"
            ? _c("div", [
                _c("hr"),
                _vm._v(" "),
                _c("div", { staticClass: "field is-horizontal mb-4" }, [
                  _vm._m(9),
                  _vm._v(" "),
                  _c("div", { staticClass: "field-body" }, [
                    _c("div", { staticClass: "field is-expanded" }, [
                      _c("div", { staticClass: "field" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.stockExchange,
                              expression: "stockExchange"
                            }
                          ],
                          staticClass: "input",
                          attrs: {
                            type: "text",
                            required: "",
                            name: "participant_stockExchange"
                          },
                          domProps: { value: _vm.stockExchange },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.stockExchange = $event.target.value
                            }
                          }
                        })
                      ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "field is-horizontal mb-4" }, [
                  _vm._m(10),
                  _vm._v(" "),
                  _c("div", { staticClass: "field-body" }, [
                    _c("div", { staticClass: "field is-expanded" }, [
                      _c("div", { staticClass: "field" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.stockSymbol,
                              expression: "stockSymbol"
                            }
                          ],
                          staticClass: "input",
                          attrs: {
                            type: "text",
                            required: "",
                            name: "participant_stockSymbol"
                          },
                          domProps: { value: _vm.stockSymbol },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.stockSymbol = $event.target.value
                            }
                          }
                        })
                      ])
                    ])
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.exitType == "IPO" || _vm.exitType == "Other"
            ? _c("div", { staticClass: "field is-horizontal" }, [
                _vm._m(11),
                _vm._v(" "),
                _c("div", { staticClass: "field-body" }, [
                  _c("div", { staticClass: "field is-expanded" }, [
                    _c("div", { staticClass: "field has-addons" }, [
                      _c("p", { staticClass: "control" }, [
                        _c("span", { staticClass: "select" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.currency,
                                  expression: "currency"
                                }
                              ],
                              attrs: {
                                name: "participant_currency",
                                required: ""
                              },
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.currency = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                }
                              }
                            },
                            [
                              _c("option", { attrs: { value: "CAD" } }, [
                                _vm._v("CAD $")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "USD" } }, [
                                _vm._v("USD $")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "GBP" } }, [
                                _vm._v("GBP £")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "Euro" } }, [
                                _vm._v("Euro €")
                              ])
                            ]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "control" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.valuation,
                              expression: "valuation"
                            }
                          ],
                          staticClass: "input",
                          attrs: {
                            type: "text",
                            placeholder: "10.3",
                            name: "participant_companyValuation"
                          },
                          domProps: { value: _vm.valuation },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.valuation = $event.target.value
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _vm._m(12)
                    ]),
                    _vm._v(" "),
                    _vm.exitType == "Other"
                      ? _c("div", { staticClass: "field" }, [
                          _vm.disclosure == "Partial"
                            ? _c("label", { staticClass: "checkbox" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.confDealSize,
                                      expression: "confDealSize"
                                    }
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    value: "1",
                                    name: "participant_confDealSize"
                                  },
                                  domProps: {
                                    checked: Array.isArray(_vm.confDealSize)
                                      ? _vm._i(_vm.confDealSize, "1") > -1
                                      : _vm.confDealSize
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = _vm.confDealSize,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = "1",
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.confDealSize = $$a.concat([
                                              $$v
                                            ]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.confDealSize = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.confDealSize = $$c
                                      }
                                    }
                                  }
                                }),
                                _vm._v(
                                  " Keep transaction amount confidential\n                            "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.disclosure == "Confidential"
                            ? _c(
                                "label",
                                { staticClass: "checkbox confidential" },
                                [
                                  _c("input", {
                                    attrs: {
                                      type: "checkbox",
                                      value: "1",
                                      disabled: ""
                                    },
                                    domProps: { checked: true }
                                  }),
                                  _vm._v(
                                    " Keep transaction amount confidential\n                                "
                                  ),
                                  _c("input", {
                                    attrs: {
                                      type: "hidden",
                                      value: "1",
                                      name: "participant_confDealSize"
                                    }
                                  })
                                ]
                              )
                            : _vm._e()
                        ])
                      : _vm._e()
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isAdmin
            ? _c("input", {
                attrs: {
                  type: "hidden",
                  name: "deal_status",
                  value: "reported"
                }
              })
            : _c("input", {
                attrs: {
                  type: "hidden",
                  name: "exit_status",
                  value: "confirmed"
                }
              })
        ])
      ]),
      _vm._v(" "),
      _c("modal-choose-match", {
        attrs: {
          type: "exit",
          company: _vm.company,
          date: _vm.date,
          matches: _vm.exits
        },
        on: { "set-match": _vm.onSetExit }
      }),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.exit.id,
            expression: "exit.id"
          }
        ],
        attrs: { type: "hidden", name: "exit_id" },
        domProps: { value: _vm.exit.id },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.exit, "id", $event.target.value)
          }
        }
      }),
      _vm._v(" "),
      !_vm.isAdmin
        ? _c("section", { staticClass: "box" }, [
            _c("div", { staticClass: "section-title title is-4" }, [
              _vm._v("Your Participation")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "section-content" }, [
              _c("div", { staticClass: "field is-horizontal mb-4" }, [
                _vm._m(13),
                _vm._v(" "),
                _c("div", { staticClass: "field-body" }, [
                  _c(
                    "div",
                    { staticClass: "field is-expanded" },
                    [
                      _c("find-or-create-fund", {
                        attrs: {
                          multiple: true,
                          member: _vm.member,
                          required: true,
                          "form-field": "participant_fund",
                          funds: _vm.member.fundList,
                          edit: _vm.edit
                        }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _vm.exitType == "IPO"
                ? _c("div", { staticClass: "field is-horizontal mb-4" }, [
                    _vm._m(14),
                    _vm._v(" "),
                    _c("div", { staticClass: "field-body" }, [
                      _c("div", { staticClass: "field is-expanded" }, [
                        _c("div", { staticClass: "field has-addons" }, [
                          _c("p", { staticClass: "control has-icons-left" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.totalRaised,
                                  expression: "totalRaised"
                                }
                              ],
                              staticClass: "input",
                              attrs: {
                                type: "text",
                                placeholder: "10.3",
                                name: "participant_totalRaised"
                              },
                              domProps: { value: _vm.totalRaised },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.totalRaised = $event.target.value
                                }
                              }
                            }),
                            _vm._v(" "),
                            _vm._m(15)
                          ]),
                          _vm._v(" "),
                          _vm._m(16)
                        ])
                      ])
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "field is-horizontal mb-4" }, [
                _vm._m(17),
                _vm._v(" "),
                _c("div", { staticClass: "field-body" }, [
                  _c("div", { staticClass: "field is-expanded" }, [
                    _c("div", { staticClass: "field has-addons" }, [
                      _vm.exitType == "Company Buyback" || _vm.exitType == ""
                        ? _c("p", { staticClass: "control" }, [
                            _c("span", { staticClass: "select" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.currency,
                                      expression: "currency"
                                    }
                                  ],
                                  attrs: {
                                    name: "participant_currency",
                                    required: ""
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.currency = $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    }
                                  }
                                },
                                [
                                  _c("option", { attrs: { value: "CAD" } }, [
                                    _vm._v("CAD $")
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "USD" } }, [
                                    _vm._v("USD $")
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "GBP" } }, [
                                    _vm._v("GBP £")
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "Euro" } }, [
                                    _vm._v("Euro €")
                                  ])
                                ]
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass: "control",
                          class: {
                            "has-icons-left": _vm.exitType != "Company Buyback"
                          }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.proceeds,
                                expression: "proceeds"
                              }
                            ],
                            staticClass: "input",
                            attrs: {
                              type: "text",
                              placeholder: "10.3",
                              name: "participant_proceeds"
                            },
                            domProps: { value: _vm.proceeds },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.proceeds = $event.target.value
                              }
                            }
                          }),
                          _vm._v(" "),
                          _vm.exitType != "Company Buyback"
                            ? _c("span", { staticClass: "icon is-left" }, [
                                _c("i", { staticClass: "fas fa-dollar-sign" })
                              ])
                            : _vm._e()
                        ]
                      ),
                      _vm._v(" "),
                      _vm._m(18)
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "field" }, [
                      _vm.disclosure == "Partial"
                        ? _c("label", { staticClass: "checkbox" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.confProceeds,
                                  expression: "confProceeds"
                                }
                              ],
                              attrs: {
                                type: "checkbox",
                                value: "1",
                                name: "participant_confProceeds"
                              },
                              domProps: {
                                checked: Array.isArray(_vm.confProceeds)
                                  ? _vm._i(_vm.confProceeds, "1") > -1
                                  : _vm.confProceeds
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.confProceeds,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "1",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.confProceeds = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.confProceeds = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.confProceeds = $$c
                                  }
                                }
                              }
                            }),
                            _vm._v(
                              " Keep proceeds confidential\n                            "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.disclosure == "Confidential"
                        ? _c(
                            "label",
                            { staticClass: "checkbox confidential" },
                            [
                              _c("input", {
                                attrs: {
                                  type: "checkbox",
                                  value: "1",
                                  disabled: ""
                                },
                                domProps: { checked: true }
                              }),
                              _vm._v(
                                " Keep proceeds confidential\n                                "
                              ),
                              _c("input", {
                                attrs: {
                                  type: "hidden",
                                  value: "1",
                                  name: "participant_confProceeds"
                                }
                              })
                            ]
                          )
                        : _vm._e()
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "field is-horizontal" }, [
                _vm._m(19),
                _vm._v(" "),
                _c("div", { staticClass: "field-body" }, [
                  _c("div", { staticClass: "field" }, [
                    _c("div", { staticClass: "control radios" }, [
                      _c("label", { staticClass: "radio" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.partialExit,
                              expression: "partialExit"
                            }
                          ],
                          attrs: {
                            type: "radio",
                            name: "participant_partialExit",
                            value: "1"
                          },
                          domProps: { checked: _vm._q(_vm.partialExit, "1") },
                          on: {
                            change: function($event) {
                              _vm.partialExit = "1"
                            }
                          }
                        }),
                        _vm._v("Yes")
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "radio" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.partialExit,
                              expression: "partialExit"
                            }
                          ],
                          attrs: {
                            type: "radio",
                            name: "participant_partialExit",
                            value: "0"
                          },
                          domProps: { checked: _vm._q(_vm.partialExit, "0") },
                          on: {
                            change: function($event) {
                              _vm.partialExit = "0"
                            }
                          }
                        }),
                        _vm._v("No")
                      ])
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "field is-horizontal mb-4" }, [
                _vm._m(20),
                _vm._v(" "),
                _c("div", { staticClass: "field-body" }, [
                  _c("div", { staticClass: "control" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.sharesSold,
                          expression: "sharesSold"
                        }
                      ],
                      staticClass: "input",
                      attrs: {
                        type: "number",
                        name: "participant_sharesSold",
                        max: "100",
                        min: "0"
                      },
                      domProps: { value: _vm.sharesSold },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.sharesSold = $event.target.value
                        }
                      }
                    })
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "field is-horizontal mb-4" }, [
                _vm._m(21),
                _vm._v(" "),
                _c("div", { staticClass: "field-body" }, [
                  _c(
                    "div",
                    { staticClass: "control" },
                    [
                      _c("datepicker", {
                        staticClass: "date-input",
                        attrs: { format: "yyyy-MM-dd" },
                        model: {
                          value: _vm.investmentDate,
                          callback: function($$v) {
                            _vm.investmentDate = $$v
                          },
                          expression: "investmentDate"
                        }
                      }),
                      _vm._v(" "),
                      _c("input", {
                        staticClass: "hidden",
                        attrs: {
                          type: "text",
                          tabindex: "-1",
                          required: "",
                          name: "participant_investmentDate"
                        },
                        domProps: { value: _vm.investmentDateStr }
                      })
                    ],
                    1
                  )
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("section", { staticClass: "box" }, [
        _c("div", { staticClass: "section-title title is-4" }, [
          _vm._v("Other Investors Exiting")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "section-content" }, [
          _c("div", { staticClass: "field is-horizontal mb-4" }, [
            _vm._m(22),
            _vm._v(" "),
            _c("div", { staticClass: "field-body" }, [
              _c(
                "div",
                { staticClass: "field is-expanded" },
                [
                  _c("find-or-create-company", {
                    attrs: {
                      multiple: true,
                      required: false,
                      hasTags: false,
                      hasDetails: false,
                      hasSectors: false,
                      hasType: true,
                      hasMemberFlag: _vm.isAdmin,
                      "form-field": "coinvestor_id",
                      "type-filter-not": "Service Provider -",
                      edit: _vm.edit
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "field" }, [
                    _vm.disclosure == "Partial"
                      ? _c("label", { staticClass: "checkbox" }, [
                          _c("input", {
                            attrs: {
                              type: "checkbox",
                              value: "1",
                              name: "participant_confCoInvestors"
                            }
                          }),
                          _vm._v(
                            " Keep co-investors confidential\n                            "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.disclosure == "Confidential"
                      ? _c("label", { staticClass: "checkbox confidential" }, [
                          _c("input", {
                            attrs: {
                              type: "checkbox",
                              value: "1",
                              disabled: ""
                            },
                            domProps: { checked: true }
                          }),
                          _vm._v(
                            " Keep co-investors confidential\n                                "
                          ),
                          _c("input", {
                            attrs: {
                              type: "hidden",
                              value: "1",
                              name: "participant_confCoInvestors"
                            }
                          })
                        ])
                      : _vm._e()
                  ])
                ],
                1
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "box" }, [
        _c("div", { staticClass: "section-title title is-4" }, [
          _vm._v("Service Providers Involved")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "section-content" }, [
          _vm.exitType == "IPO"
            ? _c("div", { staticClass: "field is-horizontal mb-4" }, [
                _vm._m(23),
                _vm._v(" "),
                _c("div", { staticClass: "field-body" }, [
                  _c(
                    "div",
                    { staticClass: "field is-expanded" },
                    [
                      _c("find-or-create-company", {
                        attrs: {
                          multiple: true,
                          required: false,
                          hasTags: false,
                          hasDetails: false,
                          hasSectors: false,
                          hasType: true,
                          hasMemberFlag: _vm.isAdmin,
                          "form-field": "underwriter_id",
                          "type-filter": "Bank",
                          "create-type-options": "SP",
                          edit: _vm.edit
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.exitType != "IPO"
            ? _c("div", { staticClass: "field is-horizontal mb-4" }, [
                _vm._m(24),
                _vm._v(" "),
                _c("div", { staticClass: "field-body" }, [
                  _c("div", { staticClass: "field is-expanded" }, [
                    _c(
                      "div",
                      { staticClass: "field" },
                      [
                        _c("find-or-create-company", {
                          attrs: {
                            multiple: true,
                            required: false,
                            hasTags: false,
                            hasDetails: false,
                            hasSectors: false,
                            hasType: true,
                            hasMemberFlag: _vm.isAdmin,
                            "form-field": "financialAdvisor_id",
                            "type-filter": "Service Provider -",
                            "create-type-options": "SP",
                            edit: _vm.edit
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "field is-horizontal mb-4" }, [
            _vm._m(25),
            _vm._v(" "),
            _c("div", { staticClass: "field-body" }, [
              _c("div", { staticClass: "field is-expanded" }, [
                _c(
                  "div",
                  { staticClass: "field" },
                  [
                    _c("find-or-create-company", {
                      attrs: {
                        multiple: true,
                        required: false,
                        hasTags: false,
                        hasDetails: false,
                        hasSectors: false,
                        hasType: true,
                        hasMemberFlag: _vm.isAdmin,
                        "form-field": "lawFirm_id",
                        "type-filter": "Law Firm",
                        "create-type-options": "SP",
                        edit: _vm.edit
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "field" }, [
                  _vm.disclosure == "Partial"
                    ? _c("label", { staticClass: "checkbox" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.confAdvisors,
                              expression: "confAdvisors"
                            }
                          ],
                          attrs: {
                            type: "checkbox",
                            value: "1",
                            name: "participant_confAdvisors"
                          },
                          domProps: {
                            checked: Array.isArray(_vm.confAdvisors)
                              ? _vm._i(_vm.confAdvisors, "1") > -1
                              : _vm.confAdvisors
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.confAdvisors,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = "1",
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.confAdvisors = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.confAdvisors = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.confAdvisors = $$c
                              }
                            }
                          }
                        }),
                        _vm._v(
                          " Keep ALL advisors names confidential\n                            "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.disclosure == "Confidential"
                    ? _c("label", { staticClass: "checkbox confidential" }, [
                        _c("input", {
                          attrs: { type: "checkbox", value: "1", disabled: "" },
                          domProps: { checked: true }
                        }),
                        _vm._v(
                          " Keep ALL advisors names confidential\n                                "
                        ),
                        _c("input", {
                          attrs: {
                            type: "hidden",
                            value: "1",
                            name: "participant_confAdvisors"
                          }
                        })
                      ])
                    : _vm._e()
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "field is-grouped" }, [
        _vm._m(26),
        _vm._v(" "),
        _c("div", { staticClass: "control" }, [
          _c(
            "a",
            {
              staticClass: "button is-link is-light",
              attrs: { href: _vm.isAdmin ? "/manage" : "/collect" }
            },
            [_vm._v("Cancel")]
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal", class: { "is-active": _vm.isSaving } },
        [
          _c("div", { staticClass: "modal-background" }),
          _vm._v(" "),
          _vm._m(27)
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Company Exited")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Exit Type")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Exit Date")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Employee Count")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Disclosure")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Press Release URL")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Acquiring Company")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [
        _vm._v("Total Value of Acquisition")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "control" }, [
      _c("a", { staticClass: "button is-static" }, [_vm._v("million")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Stock Exchange")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Stock Symbol")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Company Valuation")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "control" }, [
      _c("a", { staticClass: "button is-static" }, [_vm._v("million")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Choose Fund(s)")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Total Raised")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon is-left" }, [
      _c("i", { staticClass: "fas fa-dollar-sign" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "control" }, [
      _c("a", { staticClass: "button is-static" }, [_vm._v("million")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Proceeds")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "control" }, [
      _c("a", { staticClass: "button is-static" }, [_vm._v("million")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Partial Exit?")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("% of Shares Sold")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Initial Investment Date")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Co-Exiters")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Underwriters")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Financial Advisors")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Law Firms")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "control" }, [
      _c("button", { staticClass: "button is-link" }, [_vm._v("Submit")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "modal-content has-background-white p-4 pt-6 has-text-centered",
        staticStyle: { "border-radius": "2rem" }
      },
      [
        _c("div", { staticClass: "title is-2" }, [_vm._v("Saving Report")]),
        _vm._v(" "),
        _c("p", [_vm._v("Give us a second, this will just take a moment.")]),
        _vm._v(" "),
        _c("img", {
          attrs: { src: "/assets/intelligence/icons/loading.gif", width: "100" }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }