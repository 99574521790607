<template>
    <form method="post" autocomplete="off" @submit="isSaving=true" action="/collect/fundraising">
        <input type="hidden" :name="csrfName" :value="csrfToken">
        <input type="hidden" name="isAdmin" value="true" v-if="isAdmin">

        <section class="box">
            <div class="section-title title is-4">Fund Information</div>
            <div class="section-content">

                <div v-if="isAdmin">
                    <div class="field is-horizontal mb-4">
                        <div class="field-label">
                            <label class="label">Fund Owner / GP</label>
                        </div>
                        <div class="field-body">
                            <find-or-create-company
                                :multiple="false"
                                :required="true"
                                :hasTags="true"
                                :hasMemberFlag="isAdmin"
                                :hasSectors="false"
                                :hasType="true"
                                form-field="fund_owner"
                                type-filter="Investor"
                                @set-company="onSetCompany"
                                @has-company="onHasCompany"
                                :edit="edit"
                            ></find-or-create-company>
                        </div>
                    </div>
                                    
                    <hr>

                    <div class="field is-horizontal mb-4">
                        <div class="field-label">
                            <label class="label">Fund name</label>
                        </div>

                        <div class="field-body">
                            <find-or-create-fund
                                :multiple="false"
                                :member="company"
                                :required="true"
                                :isDisabled="!canChooseFund"
                                :placeholder="fundPlaceholder"
                                form-field="raise_fund"
                                :funds="funds"
                                :edit="edit"
                            ></find-or-create-fund>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal mb-4" v-else>
                    <div class="field-label">
                        <label class="label">Fund name</label>
                    </div>
                    <div class="field-body" v-if="editing && edit.fund">
                        <div class="control" style="width:100%">
                            <input type="text" :value="edit.fund.name" disabled class="input">
                        </div>
                        <input type="hidden" name="raise_fund"      :value="edit.fund.id">
                        <input type="hidden" name="raise_fund_json" :value="JSON.stringify(edit.fund)">
                    </div>

                    <div class="field-body" v-else>
                        <find-or-create-fund
                            :multiple="false"
                            :member="member"
                            :required="true"
                            form-field="raise_fund"
                            :funds="member.fundList"
                            :edit="edit"
                        ></find-or-create-fund>
                    </div>
                </div>

                <hr>

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">Fundraising status</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <div class="control radios">
                                <label class="radio"><input type="radio" name="raise_status" v-model="status" required value="interim">Interim</label>
                                <label class="radio"><input type="radio" name="raise_status" v-model="status" required value="close">Final Close</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal mb-4" v-if="status=='close'">
                    <div class="field-label">
                        <label class="label">Close Date</label>
                    </div>
                    <div class="field-body">
                        <div class="control">
                            <datepicker
                                v-model="closeDate"
                                format="yyyy-MM-dd"
                                class="date-input"
                            ></datepicker>
                            <input type="text" tabindex="-1" required name="fund_close_date" :value="closeDateStr" class="hidden">
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">Fundraising Period</label>
                    </div>
                    <div class="field-body">
                        <div class="field is-expanded">
                            <div class="field has-addons">
                                <p class="control">
                                    <span class="select">
                                        <select name="raise_quarter" v-model="quarter">
                                            <option value="1">Q1</option>
                                            <option value="2">Q2</option>
                                            <option value="3">Q3</option>
                                            <option value="4">Q4</option>
                                        </select>
                                    </span>
                                </p>
                                <p class="control">
                                    <input class="input" type="text" required placeholder="2020" name="raise_year" v-model="year">
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal">
                    <div class="field-label">
                        <label class="label">Currency</label>
                    </div>
                    <div class="field-body">
                        <div class="control">
                            <div class="field select">
                                <select name="raise_currency" required v-model="currency">
                                    <option value="CAD">CAD $</option>
                                    <option value="USD">USD $</option>
                                    <option value="GBP">GBP £</option>
                                    <option value="Euro">Euro €</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">Raised this quarter</label>
                    </div>
                    <div class="field-body">
                        <div class="field has-addons">
                            <div class="control  has-icons-left">
                                <input class="input" type="number" required step=".001" pattern="^\d*(\.\d{0,3})?$" name="raise_totalRaised" v-model="totalRaised">
                                <span class="icon is-left">
                                    <i class="fas fa-dollar-sign"></i>
                                </span>
                            </div>

                            <p class="control">
                                <a class="button is-static">million</a>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal">
                    <div class="field-label">
                        <label class="label">Press Release URL</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <div class="control">
                                <input class="input" type="url" name="raise_pressRelease" v-model="pressRelease">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal mb-4" v-if="isAdmin">
                    <div class="field-label">
                        <label class="label">Announce Date</label>
                    </div>
                    <div class="field-body">
                        <div class="control">
                            <datepicker
                                v-model="announceDate"
                                format="yyyy-MM-dd"
                                class="date-input"
                            ></datepicker>
                            <input type="text" tabindex="-1" required name="raise_announced" :value="announceDateStr" class="hidden">
                        </div>
                    </div>
                </div>

            </div>
        </section>

        <section class="box">
            <div class="section-title title is-4">Which LPs invested in this fund?</div>
            <div class="section-content">
                <div class="field is-horizontal mb-4">
                    <div class="field-label">
                        <label class="label">LP Investors</label>
                    </div>
                    <div class="field-body">
                        <div class="field is-expanded">
                            <div class="field">
                                <find-or-create-company
                                    :multiple="true"
                                    :required="false"
                                    :hasTags="false"
                                    :hasDetails="false"
                                    :hasSectors="false"
                                    :hasType="true"
                                    :hasMemberFlag="isAdmin"
                                    form-field="coinvestors"
                                    type-filter-not="Service Provider -"
                                    :edit="edit"
                                ></find-or-create-company>
                            </div>

                            <div class="field">
                                <label class="checkbox">
                                    <input type="checkbox" value="1" name="participant_confCoInvestors" v-model="confCoInvestors"> Keep investors confidential
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="box">
            <div class="section-title title is-4">Fundraising Sources</div>
            <div class="section-content">
                <div class="field is-horizontal mb-2" v-for="source in sources" :key="source.field">
                    <div class="field-label">
                        <label class="label">{{source.label}}</label>
                    </div>
                    <div class="field-body large">
                        <div class="field is-expanded">
                            <div class="field has-addons">
                                <div class="control has-icons-left">
                                    <input class="input" type="number" step=".001" pattern="^\d*(\.\d{0,3})?$" :name="`raise_${source.field}`" v-model="sourceVals[source.field]">
                                    <span class="icon is-left">
                                        <i class="fas fa-dollar-sign"></i>
                                    </span>
                                </div>

                                <p class="control">
                                    <a class="button is-static">million</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div class="field is-grouped">
            <div class="control">
                <button class="button is-link">Submit</button>
            </div>
            <div class="control">
                <a :href="isAdmin?'/manage':'/collect'" class="button is-link is-light">Cancel</a>
            </div>
        </div>

        <div class="modal" :class="{ 'is-active': isSaving }">
            <div class="modal-background"></div>
            <div class="modal-content has-background-white p-4 pt-6 has-text-centered" style="border-radius: 2rem;">
                <div class="title is-2">Saving Report</div>
                <p>Give us a second, this will just take a moment.</p>
                <img src="/assets/intelligence/icons/loading.gif" width="100">
            </div>
        </div>
    </form>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';

    const FindOrCreateCompany = () => import(
        /* webpackChunkName: "find-or-create-company" */ './FindOrCreateCompany.vue'
    );

    const FindOrCreateFund = () => import(
        /* webpackChunkName: "find-or-create-fund" */ './FindOrCreateFund.vue'
    );

    export default {
        components: {
            FindOrCreateCompany,
            FindOrCreateFund,
            Datepicker
        },

        data() {
            return {
                isSaving: false,
                editing: false,
                canChooseFund: false,
                fundPlaceholder: 'Select a company first',
                sources: [
                    { 'field': 'individual',            'label': 'Individuals' },
                    { 'field': 'governments',           'label': 'Governments' },
                    { 'field': 'fundOfFunds',           'label': 'Fund of Funds' },
                    { 'field': 'privatePensions',       'label': 'Private Pensions' },
                    { 'field': 'publicPensions',        'label': 'Public Pensions' },
                    { 'field': 'insuranceCompanies',    'label': 'Insurance Companies' },
                    { 'field': 'banks',                 'label': 'Banks' },
                    { 'field': 'corporations',          'label': 'Corporations' },
                    { 'field': 'institutional',         'label': 'Institutional Investors' },
                    { 'field': 'sovereign',             'label': 'Sovereign Wealth Funds' },
                    { 'field': 'foundations',           'label': 'Foundations / Endowments / Trusts' },
                    { 'field': 'other',                 'label': 'Others' },
                ],
                company: {},
                funds: {},

                status: '',
                closeDate: null,
                currency: 'CAD',
                quarter: 1,
                year: '',
                totalRaised: '',
                pressRelease: '',
                announceDate: null,
                sourceVals: {},
            }
        },

        props: {
            member: {
                type: Object,
                default: function() {
                    return {}
                }
            },
            edit: {
                type: Object,
                default: function() {
                    return { id: 0 }
                }
            },
        },

        mounted() {
            if( this.edit.id > 0 ) {
                this.editing = true

                this.company        = this.edit.owner
                this.status         = this.edit.status
                this.currency       = this.edit.currency
                this.quarter        = this.edit.quarter
                this.year           = this.edit.year
                this.totalRaised    = this.edit.totalRaised
                this.pressRelease   = this.edit.pressRelease
                this.sourceVals     = this.edit.sourceVals

                if( this.edit.fund.closeDate != '' ) {
                    let dateString = this.edit.fund.closeDate.match(/^(\d{4})\-(\d{2})\-(\d{2})$/)
                    this.closeDate = new Date( dateString[1], dateString[2]-1, dateString[3] )
                }

                if( this.edit.announceDate != '' ) {
                    let dateString = this.edit.announceDate.match(/^(\d{4})\-(\d{2})\-(\d{2})$/)
                    this.announceDate = new Date( dateString[1], dateString[2]-1, dateString[3] )
                }                
            } else {
                this.year    = new Date().getFullYear()
                this.quarter = Math.floor((new Date().getMonth() + 3) / 3)
            }
        },
        
        computed: {
            csrfName() {
                return window.Craft.csrfTokenName
            }, 

            csrfToken() {
                return window.Craft.csrfTokenValue
            },

            isAdmin() {
                return !this.member.hasOwnProperty('id')
            },

            closeDateStr() {
                return this.closeDate ? this.closeDate.toISOString().slice(0, 10) : ''
            },

            announceDateStr() {
                return this.announceDate ? this.announceDate.toISOString().slice(0, 10) : ''
            },            
        },

        methods: {
            async findFunds() {
                if( this.company != '' && this.company.id ) {
                    this.fundPlaceholder = 'Loading ' + this.company.name + ' funds ...'
                    this.canChooseFund = false

                    this.funds = await fetch(
                        window.IntelligenceURL + `/json/funds?cid=${encodeURI(this.company.id)}`, {
                        method: 'GET',
                        credentials: 'same-origin',
                        redirect: 'follow',
                        agent: null,
                    }
                    ).then( res => { return res.json() }
                    ).then( funds => {
                        this.fundPlaceholder = 'Choose or create fund'
                        this.canChooseFund = true
                        return funds.items
                    })

                } else {
                    this.funds = {}
                }
            },

            onSetCompany: function (company) {
                this.company = company
                this.findFunds()
            },

            onHasCompany: function (companyName) {
                this.canChooseFund = ( companyName != '' )               
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>

