var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "field multiselect-wrapper" },
    [
      _c(
        "multiselect",
        {
          attrs: {
            options: _vm.options,
            multiple: _vm.multiple,
            "close-on-select": true,
            "clear-on-select": _vm.multiple,
            "preserve-search": false,
            placeholder: "ACME Company Co.",
            label: "name",
            "track-by": "name",
            "preselect-first": false,
            "open-direction": "bottom"
          },
          on: {
            select: _vm.impersonateCompany,
            "search-change": _vm.updateSearchQuery,
            open: function($event) {
              _vm.multiselectOpen = true
            },
            close: function($event) {
              _vm.multiselectOpen = false
            }
          },
          model: {
            value: _vm.involved,
            callback: function($$v) {
              _vm.involved = $$v
            },
            expression: "involved"
          }
        },
        [
          !_vm.isLoading &&
          _vm.lastSearch.length >= 3 &&
          _vm.options.length != 0
            ? _c("li", { attrs: { slot: "afterList" }, slot: "afterList" }, [
                _c("span", { staticClass: "multiselect__option afterList" }, [
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.createCompany = true
                        }
                      }
                    },
                    [
                      _c("span", [
                        _vm._v("Looking for a different "),
                        _c("em", [_vm._v(_vm._s(_vm.lastSearch))]),
                        _vm._v("?")
                      ]),
                      _vm._v(" "),
                      _c("strong", [_vm._v("Tell us about them ›")])
                    ]
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isLoading
            ? _c("span", { attrs: { slot: "noResult" }, slot: "noResult" }, [
                _vm._v("Searching ...")
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.isLoading
            ? _c(
                "span",
                {
                  staticClass: "noResult",
                  attrs: { slot: "noResult" },
                  slot: "noResult"
                },
                [
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.createCompany = true
                        }
                      }
                    },
                    [
                      _c("span", [
                        _vm._v("No companies found that match "),
                        _c("em", [_vm._v(_vm._s(_vm.lastSearch))]),
                        _vm._v(".")
                      ]),
                      _vm._v(" "),
                      _c("strong", [_vm._v("Tell us about them ›")])
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "noOptions",
              attrs: { slot: "noOptions" },
              slot: "noOptions"
            },
            [
              _vm._v(
                "\n            Start typing to search for companies.\n        "
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _vm.multiselectOpen
        ? _c("div", {
            staticStyle: {
              position: "fixed",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              background: "rgba(0,0,0,0.5)",
              "z-index": "100"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.createCompany
        ? _c("modal-create-company", {
            attrs: {
              name: _vm.lastSearch,
              hasDetails: true,
              hasSectors: false,
              hasType: true,
              hasTags: true,
              hasMemberFlag: true,
              "create-type-options": _vm.createTypeOptions
            },
            on: {
              created: _vm.impersonateCompany,
              closed: function($event) {
                _vm.createCompany = false
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal", class: { "is-active": _vm.isSwitching } },
        [_c("div", { staticClass: "modal-background" }), _vm._v(" "), _vm._m(0)]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "modal-content has-background-white p-4 pt-6 has-text-centered",
        staticStyle: { "border-radius": "2rem" }
      },
      [
        _c("div", { staticClass: "title is-2" }, [_vm._v("Loading Member")]),
        _vm._v(" "),
        _c("p", [_vm._v("Give us a second, this will just take a moment.")]),
        _vm._v(" "),
        _c("img", {
          attrs: { src: "/assets/intelligence/icons/loading.gif", width: "100" }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }