var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-mobileMenu" }, [
    _c(
      "a",
      {
        staticClass: "toggle",
        class: _vm.mobileMenuOpen ? "active" : "",
        attrs: { href: "#" },
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.toggleMenu($event)
          }
        }
      },
      [_c("span"), _vm._v(" "), _c("span"), _vm._v(" "), _c("span")]
    ),
    _vm._v(" "),
    _vm.mobileMenuOpen ? _c("nav", [_vm._m(0)]) : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container flex" }, [
      _c("ul", { staticClass: "primary" }, [
        _c("li", [_c("a", { attrs: { href: "/" } }, [_vm._v("Home")])]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "/pricing" } }, [_vm._v("Pricing")])
        ]),
        _vm._v(" "),
        _c("li", [_c("a", { attrs: { href: "/faqs" } }, [_vm._v("FAQs")])]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "/reports" } }, [_vm._v("Reports")])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "/live-data" } }, [_vm._v("Live Data")])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "/account/login" } }, [_vm._v("Login")])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "/account/create" } }, [_vm._v("Sign-up")])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }