<script>
  import { Doughnut } from 'vue-chartjs'

  export default {
      extends: Doughnut,
      data() {
        return {
          options: {
            responsive: true,
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    color: 'rgb(255, 99, 132)'
                }
            },
            plugins: {
              colorschemes: { scheme: 'brewer.Accent8' }
            }
          }
        }
      },
      props:['chartData','hideLegend'],
      methods: {
        update() {
          this.$data._chart.update()
        }
      },
      mounted () {
        if( this.hideLegend ) {
          this.options.legend.display = false
        }
        this.renderChart(this.chartData, this.options )
      }
  };
</script>